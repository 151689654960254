import * as React from 'react'

const Price = () => {
  return (
    <main>
      <div className="about">
        <div className="defaultBackgroundColor">
          <div className="feachuredImage paddingTop70 paddingBottom70"
               style={{backgroundImage: 'url(/images/header.jpg)'}}>
            <h1 className="title margin0 padding0 textLight text-center">
              Үнэ тариф
            </h1>
            <div className="breadcrumb marginTop10">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb margin0 font17">
                  <li className="breadcrumb-item textLight">Хувь хүн</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Үнэ тариф
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"/>
              <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-12 col-sm-12">
                <div className="paddingTop50 paddingBottom50">
                  <div className="item marginBottom30">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h3 className="font20 fontMedium marginBottom15">
                          Хот, хот хооронд бичиг захидал үнэ тариф
                        </h3>
                      </div>
                      <div className="font14">
                        /Төгрөгөөр/ НӨАТ багтаагүй болно.
                      </div>
                    </div>
                    <table className={'table'}>
                      <tbody>
                        <tr>
                          <td rowSpan={3} style={{width: '30'}}>№</td>
                          <td rowSpan={3} style={{width: '180'}}>Үзүүлэлт</td>
                          <td rowSpan={3} style={{width: '128'}}>Зай /км/</td>
                          <td colSpan={3} rowSpan={2}>Бичиг захидал</td>
                        </tr>
                        <tr/>
                        <tr>
                          <td style={{width: '118'}}>0-300 гр</td>
                          <td style={{width: '120'}}>301-600 гр</td>
                          <td style={{width: '114'}}>601-1000 гр</td>
                        </tr>
                        <tr>
                          <td style={{width: '30'}}>1</td>
                          <td style={{width: '180'}}>1-р бүс</td>
                          <td style={{width: '128'}}>0-250 км</td>
                          <td style={{width: '18'}}>4,000</td>
                          <td style={{width: '120'}}>4,500</td>
                          <td style={{width: '114'}}>5,000</td>
                        </tr>
                        <tr>
                          <td style={{width: '30'}}>2</td>
                          <td style={{width: '180'}}>2-р бүс</td>
                          <td style={{width: '128'}}>251-500 км</td>
                          <td style={{width: '18'}}>4,000</td>
                          <td style={{width: '120'}}>4,500</td>
                          <td style={{width: '114'}}>5,000</td>
                        </tr>
                        <tr>
                          <td style={{width: '30'}}>3</td>
                          <td style={{width: '180'}}>3-р бүс</td>
                          <td style={{width: '128'}}>501-1000 км</td>
                          <td style={{width: '118'}}>4,000</td>
                          <td style={{width: '120'}}>4,500</td>
                          <td style={{width: '114'}}>5,000</td>
                        </tr>
                        <tr>
                          <td style={{width: '30'}}>4</td>
                          <td style={{width: '180'}}>4-р бүс</td>
                          <td style={{width: '128'}}>251-500 км</td>
                          <td style={{width: '18'}}>4,000</td>
                          <td style={{width: '120'}}>4,500</td>
                          <td style={{width: '114'}}>5,000</td>
                        </tr>
                        <tr>
                          <td style={{width: '30'}}>5</td>
                          <td style={{width: '180'}}>5-р бүс</td>
                          <td style={{width: '128'}}>251-500 км</td>
                          <td style={{width: '18'}}>4,000</td>
                          <td style={{width: '120'}}>4,500</td>
                          <td style={{width: '114'}}>5,000</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="item marginBottom15">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h3 className="font20 fontMedium marginBottom15">
                          Хот хооронд шуудангийн илгээлтийн үнэ тариф
                        </h3>
                      </div>
                      <div className="font14">
                        /Төгрөгөөр/ НӨАТ багтаагүй болно.
                      </div>
                    </div>
                    <table className={'table'}>
                      <tbody>
                        <tr>
                          <td rowSpan={3} style={{width: '29'}}>№</td>
                          <td rowSpan={3} style={{width: '109'}}>Үзүүлэлт</td>
                          <td rowSpan={3} style={{width: '132'}}>Зай /км/</td>
                          <td style={{width: '108'}}>Жижиг</td>
                          <td style={{width: '108'}}>Дунд</td>
                          <td style={{width: '102'}}>Том</td>
                          <td style={{width: '105'}}>Овор ихтэй</td>
                        </tr>
                        <tr>
                          <td style={{width: '108'}}>90 см</td>
                          <td style={{width: '108'}}>120 см</td>
                          <td style={{width: '102'}}>150 см</td>
                          <td style={{width: '105'}}>180 см</td>
                        </tr>
                        <tr>
                          <td style={{width: '108'}}>0-5 кг</td>
                          <td style={{width: '108'}}>5.01-10 кг</td>
                          <td style={{width: '102'}}>10.01-20 кг</td>
                          <td style={{width: '105'}}>20.01-30кг</td>
                        </tr>
                        <tr>
                          <td style={{width: '29'}}>1</td>
                          <td style={{width: '109'}}>1-р бүс</td>
                          <td style={{width: '132'}}>0-250 км</td>
                          <td style={{width: '108'}}>7,000</td>
                          <td style={{width: '108'}}>9,000</td>
                          <td style={{width: '102'}}>12,000</td>
                          <td style={{width: '105'}}>15,000</td>
                        </tr>
                        <tr>
                          <td style={{width: '29'}}>2</td>
                          <td style={{width: '108'}}>2-р бүс</td>
                          <td style={{width: '132'}}>251-500 км</td>
                          <td style={{width: '108'}}>10,000</td>
                          <td style={{width: '108'}}>12,000</td>
                          <td style={{width: '102'}}>16,000</td>
                          <td style={{width: '105'}}>20,000</td>
                        </tr>
                        <tr>
                          <td style={{width: '29'}}>3</td>
                          <td style={{width: '109'}}>3-р бүс</td>
                          <td style={{width: '132'}}>501-1000 км</td>
                          <td style={{width: '108'}}>12,000</td>
                          <td style={{width: '108'}}>15,000</td>
                          <td style={{width: '102'}}>20,000</td>
                          <td style={{width: '105'}}>25,000</td>
                        </tr>
                        <tr>
                          <td style={{width: '129'}}>4</td>
                          <td style={{width: '109'}}>4-р бүс</td>
                          <td style={{width: '132'}}>1001-1500 км</td>
                          <td style={{width: '108'}}>15,000</td>
                          <td style={{width: '108'}}>20,000</td>
                          <td style={{width: '102'}}>30,000</td>
                          <td style={{width: '105'}}>35,000</td>
                        </tr>
                        <tr>
                          <td style={{width: '29'}}>5</td>
                          <td style={{width: '109'}}>5-р бүс</td>
                          <td style={{width: '132'}}>1500-аас дээш км</td>
                          <td style={{width: '108'}}>18,000</td>
                          <td style={{width: '108'}}>25,000</td>
                          <td style={{width: '102'}}>35,000</td>
                          <td style={{width: '105'}}>40,000</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className={'marginBottom30'}>
                    <b>Тайлбар:</b> Бага жинтэй боловч овор ихтэй,жин ихтэй боловч овор багатай бол тухай жин,овор хэмжээгээр тооцож тарифаар авна.
                  </p>
                  <div className="item marginBottom30">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h3 className="font20 fontMedium marginBottom15">
                          Энгийн хүргэлтийн үйлчилгээ
                        </h3>
                      </div>
                      <div className="font14">
                        /Төгрөгөөр/ НӨАТ багтаагүй болно.
                      </div>
                    </div>
                    <table className="table">
                      <thead>
                      <tr>
                        <th scope="col">Жин, Хэмжээ</th>
                        <th scope="col">A Бүс</th>
                        <th scope="col">Б Бүс</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>0.01кг - 10кг / 32x32см</td>
                        <td>5,000 ₮</td>
                        <td>7,000 ₮</td>
                      </tr>
                      <tr>
                        <td>10.01кг - 20кг / 60x60см</td>
                        <td>7,000 ₮</td>
                        <td>9,000 ₮</td>
                      </tr>
                      <tr>
                        <td>20.01кг - 30кг / 90x90см</td>
                        <td>9,000 ₮</td>
                        <td>11,000 ₮</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="item marginBottom30">
                    <h3 className="font20 fontMedium marginBottom15">
                      Буухиа хүргэлтийн үйлчилгээ
                    </h3>
                    <table className="table">
                      <thead>
                      <tr>
                        <th scope="col">Жин, Хэмжээ</th>
                        <th scope="col">А Бүс / 1-8 Цаг</th>
                        <th scope="col">Б Бүс / 1-8 Цаг</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>0.01кг - 10кг / 32x32см</td>
                        <td>10,000 ₮</td>
                        <td>15,000 ₮</td>
                      </tr>
                      <tr>
                        <td>10.01кг - 20кг / 60x60см</td>
                        <td>12,000 ₮</td>
                        <td>9,000 ₮</td>
                      </tr>
                      <tr>
                        <td>20.01кг - 30кг / 90x90см</td>
                        <td>9,000 ₮</td>
                        <td>18,000 ₮</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="item marginBottom30">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h3 className="font20 fontMedium marginBottom15">
                          Ачааны машин захиалга, хүргэлтийн үйлчилгээ
                        </h3>
                      </div>
                      <div className="font14">
                        24/48 цагийн дотор нэг талдаа
                      </div>
                    </div>
                    <table className="table">
                      <thead>
                      <tr>
                        <th scope="col">Зай</th>
                        <th scope="col">Үнэ</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>A Бүс + B бүс</td>
                        <td>0-5 км хүртэл</td>
                      </tr>
                      <tr>
                        <td>30кг - 1 тн хүртэлх</td>
                        <td>0-5 км хүртэл</td>
                      </tr>
                      <tr>
                        <td>0-5 км хүртэл</td>
                        <td>50,000 ₮</td>
                      </tr>
                      <tr>
                        <td>15.01-30 км хүртэл</td>
                        <td>70,000 ₮</td>
                      </tr>
                      <tr>
                        <td>30.01-50 км хүртэл</td>
                        <td>90,000 ₮</td>
                      </tr>
                      <tr>
                        <td>Зөөх, оруулах</td>
                        <td>10,000 ₮</td>
                      </tr>
                      <tr>
                        <td>Ачилт буулгалт хийхэд</td>
                        <td>20,000 ₮</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="item marginBottom30">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h3 className="font20 fontMedium marginBottom15">
                          Нэмэлт үйлчилгээ
                        </h3>
                      </div>
                      <div className="font14">
                        НӨАТ багтаагүй болно.
                      </div>
                    </div>
                    <table className="table">
                      <thead>
                      <tr>
                        <th scope="col">Нөхцөл A Бүс + B бүс</th>
                        <th scope="col">Үнэ/ төгрөг</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>Цаг, чиглэл заасан хүргэлтийн нэмэгдэл</td>
                        <td>5,000 ₮</td>
                      </tr>
                      <tr>
                        <td>Өдөрт нэг удаа очиж авах</td>
                        <td>5,000 ₮</td>
                      </tr>
                      <tr>
                        <td>Бараа байршуулах</td>
                        <td>150,000 ₮</td>
                      </tr>
                      <tr>
                        <td>Захиалагчаас төлбөр тооцоог авч өгөх</td>
                        <td>200 ₮</td>
                      </tr>
                      <tr>
                        <td>Давтан хүргэлт</td>
                        <td>2 дахин үнэ</td>
                      </tr>
                      <tr>
                        <td>Бараа савлах тус бүр</td>
                        <td>300 ₮</td>
                      </tr>
                      <tr>
                        <td>Гардуулалтын тайлан</td>
                        <td>100 ₮</td>
                      </tr>
                      </tbody>
                    </table>
                    <table className="table">
                      <thead>
                      <tr>
                        <th scope="col">А Бүс</th>
                        <th scope="col">Б Бүс</th>
                        <th scope="col">Уртын бүс</th>
                        <th scope="col">Үнэ ₮</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>5 шар</td>
                        <td>Толгойт</td>
                        <td>Найрамдал, Баянголын ам, Эмээлт, Тахилт, Орбит, Жаргалантын ам</td>
                        <td>15,000 - 35,000 ₮</td>
                      </tr>
                      <tr>
                        <td>Зайсан</td>
                        <td>Нисэх</td>
                        <td>Биокомбинат, Баруун туруун, Шувуун Фабрик,</td>
                        <td>15,000 - 35,000 ₮</td>
                      </tr>
                      <tr>
                        <td>Ботаник</td>
                        <td>Улиастай</td>
                        <td>Хуурай мухар, Баянбулаг, Өвөр гүнт, Хадат вилла, Сэлбэ, Майхан толгой, Согоот, Ургах наран,
                          Гачуурт
                        </td>
                        <td>15,000 - 35,000 ₮</td>
                      </tr>
                      <tr>
                        <td>7 буудал</td>
                        <td>Бэлх</td>
                        <td>Ар гүнт, Хандгайт, Санзай, Жигжид, Бусад зуслан</td>
                        <td>15,000 - 35,000 ₮</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"/>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Price