import * as React from 'react'
import { Link } from 'react-router-dom'
import {useCallback, useState} from "react";
import CustomButton from "../lib/CustomButton";
import fetchApi from "../../api/fetchApi";
import config from "../../config";
import {useToasts} from "react-toast-notifications";
import { useIntl } from 'react-intl';

const RecoverPassword = () => {
  const intl = useIntl()
  const [loading, updateLoading] = useState(false)
  const [email, updateEmail] = useState("");
  const {addToast} = useToasts();
  const recoverPassword = useCallback(async ()=>{
    updateLoading(true)
    const response = await fetchApi.requestPost(`${config.get('myPostApiUrl')}/api/auth/recover`,{email});
    if(response.success){
      addToast(`Нууц үг солих имэйл ${email} руу илгээлээ`, { appearance: 'success' });
      updateEmail("")
    }else{
      addToast(response.message, { appearance: 'error' })
    }
    updateLoading(false)
  },[email, loading]);
  return (
    <main>
      <div className="login defaultBackgroundColor text-center d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="content paddingTop100 paddingBottom100">
            <div className="top">
              <div className="middle paddingTop50 paddingBottom25">
                <div className="first paddingBottom25">
                  <h2 className="margin0 font32 fontBold">{intl.formatMessage({id: 'forgot.password', defaultMessage: ''})}</h2>
                </div>
                <div className="last">
                  <form>
                    <div className="paddingBottom5">
                      <input type="email" value={email} onChange={(e)=>updateEmail(e.target.value)} placeholder={intl.formatMessage({id: 'email', defaultMessage: ''})}
                             className="form-control fontRegular borderRadiusSmall"/>
                    </div>
                    <div className="paddingTop10">
                      <CustomButton title={intl.formatMessage({id: 'send', defaultMessage: ''})} loading={loading} reload={recoverPassword} />
                    </div>
                    <div className="forget paddingTop10">
                      <Link to={'/login'}
                         className="btn fontRegular borderRadiusSmall all-hover paddingTop10 paddingBottom10">
                        {intl.formatMessage({id: 'back', defaultMessage: ''})}
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
)
}

export default RecoverPassword