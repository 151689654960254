import React from "react";
import { useIntl } from "react-intl";

const ErrorPage = () => {
  const intl = useIntl();
  return (
    <div>
      {intl.formatMessage({id: 'data.empty', defaultMessage: ''})}
    </div>
  )
}
export default ErrorPage;