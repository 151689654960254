import * as React from 'react'
import {useCallback, useContext, useEffect, useState} from "react";
import UserContext from "../../../context/UserContext";
import CustomButton from "../../lib/CustomButton";
import fetchApi from "../../../api/fetchApi";
import config from "../../../config";
import {useToasts} from "react-toast-notifications";
import { useIntl } from 'react-intl';
interface State {
  loading: boolean,
  first_name: string
  last_name: string
  email: string
  gender: string
}

const initialState: State = {
  loading: false,
  first_name: "",
  last_name: "",
  email: "",
  gender: "MALE",
}
const MainInfo = () => {
  const intl = useIntl()
  const {user} = useContext(UserContext);
  const [state, updateState] = useState(initialState);
  const {addToast} = useToasts();
  const onFinish = useCallback(async ()=>{
    updateState({...state, loading: true})
    const response = await fetchApi.requestPost(`${config.get('myPostApiUrl')}/api/auth/update`,state);
    if(response.success){
      addToast(intl.formatMessage({id: 'change.information.successful', defaultMessage: ''}), { appearance: 'success' });
      updateState(initialState)
    }else{
      addToast(response.message, { appearance: 'error' })
    }
    updateState({...state,loading: false})
  },[state])
  useEffect(()=>{
    if(user){
      updateState(user)
    }
  },[user]);
  return (
    <div className="bottomTab marginTop20 defaultBackgroundColor borDer borderRadiusSmall padding30">
      <div className="title">
        <h2 className="margin0 padding0 font18 fontMedium">{intl.formatMessage({id: 'personal.information', defaultMessage: ''})}</h2>
      </div>
      <div className="body marginTop20">
        <form className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="marginBottom5 marginLeft5">{intl.formatMessage({id: 'last.name', defaultMessage: ''})}</div>
            <input type="text" className="form-control" value={state.last_name} onChange={(e)=>updateState({...state, last_name: e.target.value})}/>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="marginBottom5 marginLeft5">{intl.formatMessage({id: 'first.name', defaultMessage: ''})}</div>
            <input type="text" className="form-control" value={state.first_name} onChange={(e)=>updateState({...state, first_name: e.target.value})}/>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 marginTop20">
            <div className="marginBottom5 marginLeft5">{intl.formatMessage({id: 'gender', defaultMessage: ''})}</div>
            <select className="form-select" value={state.gender} onChange={(e)=>updateState({...state, gender: e.target.value})}>
              <option value="MALE">{intl.formatMessage({id: 'male', defaultMessage: ''})}</option>
              <option value="FEMALE">{intl.formatMessage({id: 'female', defaultMessage: ''})}</option>
            </select>
          </div>
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 marginTop25">
            <CustomButton loading={state.loading} type="submit" title={intl.formatMessage({id: 'save', defaultMessage: ''})} reload={onFinish}/>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MainInfo