import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Nav, Tab, TabContainer, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import fetchApi from '../../api/fetchApi'
import config from '../../config'
import UserContext from "../../context/UserContext";
import { useToasts } from 'react-toast-notifications'
import { useIntl } from 'react-intl'

interface State {
  loading: boolean
  result: any
}

const initialState: State = {
  loading: true,
  result: {},
}


const HeaderView = () => {
  const { user, changeLanguage } = useContext(UserContext);
  const intl = useIntl()
  const updateLanguage = useCallback((lng) => {
    changeLanguage(lng)
}, []);
  const { addToast } = useToasts()
  const [navTab, setNavTab] = useState('individual')
  const [state, updateState] = useState(initialState)
  const fetchMenu = useCallback(async () => {
    try {
      updateState({
        ...state,
        loading: true,
      })
      const response = await fetchApi.requestGet(`${config.get('myPostApiUrl')}/api/menu/list`)
      updateState({
        loading: false,
        result: response.result,
      })
    } catch (e: any) {
      addToast(intl.formatMessage({ id: 'error.message', defaultMessage: '' }), { appearance: 'error' })
    }
  }, [state])
  useEffect(() => {
    fetchMenu().then()
  }, [])
  const individual = useMemo(() => {
    return (state.result?.result || []).filter((item: any, index: number) => item.position === "individual")
  }, [state])
  const organization = useMemo(() => {
    return (state.result?.result || []).filter((item: any, index: number) => item.position === "organization")
  }, [state])
  return (
    <div>
      <div className="social defaultBackgroundColor position-fixed zIndexMedium">
        <ul className="margin0 padding0">
          <li>
            <a target="_blank" className="uk-position-relative" href={'https://www.facebook.com/mypost2424'}>
              <div className="icon lightBackgroundColor"
                style={{ WebkitMaskImage: 'url("/images/icons/facebook.svg")' }} />
            </a>
          </li>
          <li>
            <a target="_blank" className="uk-position-relative" href={'https://www.instagram.com/mongolinfinitypost/'}>
              <div className="icon lightBackgroundColor"
                style={{ WebkitMaskImage: 'url("/images/icons/instagram.svg")' }} />
            </a>
          </li>
          {/*<li>*/}
          {/*  <Link target="_blank" className="uk-position-relative" to={'https://www.linkedin.com/'}>*/}
          {/*    <div className="icon lightBackgroundColor"*/}
          {/*      style={{ WebkitMaskImage: 'url("/images/icons/linkedin.svg")' }} />*/}
          {/*  </Link>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <Link target="_blank" className="uk-position-relative" to={'https://www.youtube.com/'}>*/}
          {/*    <div className="icon lightBackgroundColor"*/}
          {/*      style={{ WebkitMaskImage: 'url("/images/icons/youtube.svg")' }} />*/}
          {/*  </Link>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <Link className="uk-position-relative" to={''}>*/}
          {/*    <div className="icon lightBackgroundColor"*/}
          {/*      style={{ WebkitMaskImage: 'url("/images/icons/calculator.svg")' }} />*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </ul>
      </div>
      <header className="defaultBackgroundColor">
        <div className="top paddingTop25 paddingBottom25 defaultBackgroundColor position-relative zIndexMedium">
          <div className="container container-xl container-lg container-md container-sm">
            <div className="d-flex align-items-center justify-content-between">
              <div className="left d-flex">
                <div className="logo paddingRight40">
                  <Link to={'/'}>
                    <img src="/images/logo.jpg" width={225} />
                  </Link>
                </div>
                <div className="menu d-flex align-items-center paddingLeft15">
                  <Tab.Container activeKey={navTab}>
                    <Nav activeKey={navTab} onSelect={e => setNavTab(e || '')} className="nav nav-tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="individual">{intl.formatMessage({ id: 'individual', defaultMessage: '' })}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="organization">{intl.formatMessage({ id: 'organization', defaultMessage: '' })}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Tab.Container>
                </div>
              </div>
              <div className="right">
                <div className="menu">
                  <ul className="margin0 padding0 d-flex font16">
                    <li className="paddingRight30">
                      <Link className="d-flex align-items-center" to={'/branch'}>
                        <div className="icon coverBackgroundCover coverBackgroundPosition secondaryBackgroundColor"
                          style={{ WebkitMaskImage: 'url("/images/icons/map.svg")' }} />
                        <div className="text paddingLeft5 textSecondary all-hover">{intl.formatMessage({ id: 'branch.location', defaultMessage: '' })}</div>
                      </Link>
                    </li>
                    <li className="paddingRight30">
                      <Link className="d-flex align-items-center" to={'/help'}>
                        <div className="icon coverBackgroundCover coverBackgroundPosition secondaryBackgroundColor"
                          style={{ WebkitMaskImage: 'url("/images/icons/help.svg")' }} />
                        <div className="text paddingLeft5 textSecondary">{intl.formatMessage({ id: 'help.center', defaultMessage: '' })}</div>
                      </Link>
                    </li>
                    {
                      user ? (
                        <li className="paddingRight15">
                          <Link className="d-flex align-items-center" to={'/profile'}>
                            <div className="icon coverBackgroundCover coverBackgroundPosition secondaryBackgroundColor"
                              style={{ WebkitMaskImage: 'url("/images/icons/login.svg")' }} />
                            <div className="text paddingLeft5 textSecondary">{user?.last_name}</div>
                          </Link>
                        </li>
                      ) : (
                        <li className="paddingRight15">
                          <Link className="d-flex align-items-center" to={'/login'}>
                            <div className="icon coverBackgroundCover coverBackgroundPosition secondaryBackgroundColor"
                              style={{ WebkitMaskImage: 'url("/images/icons/login.svg")' }} />
                            <div className="text paddingLeft5 textSecondary">{intl.formatMessage({ id: 'login', defaultMessage: '' })}</div>
                          </Link>
                        </li>
                      )
                    }
                    <div className="language">
                      {
                        intl.locale === "mn" ? (
                          <a onClick={(e) => updateLanguage("en")}>
                            <img width={20} src="/images/eng.svg" />
                          </a>
                        ) : (
                          <a onClick={(e) => updateLanguage( "mn")}>
                            <img width={20} src="/images/mn.png" />
                          </a>
                        )
                      }
                    </div>
                  </ul>
                </div>
                <div className="mobileMenu">
                  <a className="icon" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop"
                    aria-controls="offcanvasWithBackdrop">
                    <div className="icon" style={{ backgroundImage: 'url(/images/icons/menu.svg)' }} />
                  </a>
                  <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasWithBackdrop"
                    aria-labelledby="offcanvasWithBackdropLabel">
                    <div className="offcanvas-header">
                      <div className="logo">
                        <Link to={'/'}>
                          <img src="/images/logo.jpg" width={225} />
                        </Link>
                      </div>
                      <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close" />
                    </div>
                    <div className="offcanvas-body">
                      <p>.....</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TabContainer activeKey={navTab}>
          <div
            className="bottom borderTop borderBottom paddingTop15 paddingBottom15 defaultBackgroundColor position-relative">
            <Tab.Content>
              <Tab.Pane eventKey="individual">
                <div className="container container-xl container-lg container-md container-sm container">
                  <div className="menu">
                    <ul className="margin0 padding0 d-flex font17">
                      {
                        individual.map((item: any) => {
                          return (
                            <li className="paddingRight25 dropActive">
                              {
                                item.children.length > 0 ?
                                  <div className="textSecondary">{item.title}</div>
                                  :
                                  <Link to={`/page/${item.slug}`} className="textSecondary">
                                    {item.title}
                                  </Link>
                              }
                              <div
                                className="defaultBackgroundColor position-absolute megaMenu background-hover borderBottom background-hover">
                                <div className="dropdown paddingTop20 paddingBottom20">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="row">
                                          {
                                            (item.children || []).map((itm: any) => {
                                              return (
                                                itm.children.length > 0 ? (
                                                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                    <div className="textSecondary fontBold font16">{itm.title}</div>
                                                    <div className="marginTop5">
                                                      <ul className="margin0 padding0 font16">
                                                        {
                                                          (itm.children || []).map((chld: any) => {
                                                            return (
                                                              <li>
                                                                <Link className="textSecondary"
                                                                  to={`/page/${chld.slug}`}>
                                                                  {chld.title}
                                                                </Link>
                                                              </li>
                                                            )
                                                          })
                                                        }
                                                      </ul>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <ul className="margin0 padding0 font16">
                                                    <li>
                                                      <Link to={`/page/${itm.slug}`} className="textSecondary">
                                                        {itm.title}
                                                      </Link>
                                                    </li>
                                                  </ul>
                                                )
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>

              </Tab.Pane>
              <Tab.Pane eventKey="organization">
                <div className="container container-xl container-lg container-md container-sm container">
                  <div className="menu">
                    <ul className="margin0 padding0 d-flex font17">
                      {
                        organization.map((item: any) => {
                          return (
                            <li className="paddingRight25 dropActive">
                              {
                                item.children.length > 0 ?
                                  <div className="textSecondary">{item.title}</div>
                                  :
                                  <Link to={`/page/${item.slug}`} className="textSecondary">
                                    {item.title}
                                  </Link>
                              }
                              <div
                                className="defaultBackgroundColor position-absolute megaMenu background-hover borderBottom background-hover">
                                <div className="dropdown paddingTop20 paddingBottom20">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="row">
                                          {
                                            (item.children || []).map((itm: any) => {
                                              return (
                                                itm.children.length > 0 ? (
                                                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                    <div className="textSecondary fontBold font16">{itm.title}</div>
                                                    <div className="marginTop5">
                                                      <ul className="margin0 padding0 font16">
                                                        {
                                                          (itm.children || []).map((chld: any) => {
                                                            return (
                                                              <li>
                                                                <Link className="textSecondary"
                                                                  to={`/page/${chld.slug}`}>
                                                                  {chld.title}
                                                                </Link>
                                                              </li>
                                                            )
                                                          })
                                                        }
                                                      </ul>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <ul className="margin0 padding0 font16">
                                                    <li>
                                                      <Link to={`/page/${itm.slug}`} className="textSecondary">
                                                        {itm.title}
                                                      </Link>
                                                    </li>
                                                  </ul>
                                                )
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </TabContainer>
      </header>
    </div>

  )
}
export default HeaderView