import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useIntl } from 'react-intl';
import config from '../../../config'
import NetworkApi from '../../../api/networkApi'

const initialState = {
  dataList: [],
  loading: true,
  searchBarcode: '',
  createdDate: '',
}

const ItemReport = () => {
  const intl = useIntl();

  const { barcode } = useParams<any>()
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (barcode) {
      fetchFormData(barcode)
    }
  }, [barcode])

  const fetchFormData = async (barcode: string) => {
    setState({ ...state, dataList: [], loading: true, searchBarcode: barcode })
    const response = await NetworkApi.requestGet(`${config.get('orderApiUrl')}/api/order/item/${barcode}`)

    if (response.message) {
      setState({ ...state, dataList: [], loading: false, searchBarcode: barcode, createdDate: moment(new Date()).format('YYYY-MM-DD') })
    } else {
      if (response.result.length > 0 && response.result[0].lineItemDescription.length > 0) {
        response.result[0].lineItemDescription.forEach((item: any) => {
          item.totalPrice = item.price * item.quantity
        })
        let resultList: any
        let quantityCount = 0
        let priceCount = 0
        let totalCount = 0
        response.result[0].lineItemDescription.forEach((item: any) => {
          quantityCount += item.quantity
          priceCount += item.price
          totalCount += item.quantity * item.price
          // resultList.push(item);
        })
        console.log('aa====' + quantityCount)
        console.log('aa====' + priceCount)
        console.log('aa====' + totalCount)

        const lastRow = {
          id: 99999,
          name: 'НИЙТ ҮНЭ',
          unit: '',
          quantity: quantityCount,
          price: priceCount,
          totalCount: totalCount,
        }
        response.result[0].lineItemDescription.push(lastRow)

        setState({
          ...state,
          dataList: response.result[0].lineItemDescription,
          loading: false,
          searchBarcode: barcode,
          createdDate: moment(response.result[0].createdAt).format('YYYY-MM-DD'),
        })
      } else {
        setState({ ...state, dataList: [], loading: false, searchBarcode: barcode, createdDate: moment(new Date()).format('YYYY-MM-DD') })
      }
    }
  }

  return (
      <div style={{ maxWidth: 800, width: '100%', margin: '20px auto 0' }}>
        <div className="row">
          <div className="col-6">
            <img src={'/images/report_logo.png'} width="150" />
            <div style={{ fontSize: 20, marginTop: 10 }}>
              {intl.formatMessage({ id: 'date', defaultMessage: '' })}: {state.createdDate}
            </div>
          </div>

          <div className="col-6">
            <div style={{ fontSize: 16, color: 'grey', textAlign: 'right' }}>
              {intl.formatMessage({ id: 'report.title1', defaultMessage: '' })}: {state.createdDate} <br />
              {intl.formatMessage({ id: 'report.title2', defaultMessage: '' })}: {state.createdDate}
            </div>
            <br />
            <div style={{ fontSize: 20, textAlign: 'right' }}>
              {intl.formatMessage({ id: 'registration.number', defaultMessage: '' })}: {state.createdDate} <br />
              {state.searchBarcode}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <div style={{ fontSize: 20, marginTop: 25, fontWeight: 'bold' }}>
              {intl.formatMessage({ id: 'report.information', defaultMessage: '' })}: {state.createdDate}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12" style={{ textAlign: 'left', marginTop: 20 }}>
            {state.dataList.length > 0 ? (
              <div>
                <table className="table" style={{ width: '100%' }}>
                  <thead>
                    <tr style={{ background: '#404A9B', fontSize: 12 }}>
                      <th scope="col" style={{ border: '1px solid black', color: 'white', padding: '5px', textAlign: 'center' }}>
                        №
                      </th>
                      <th scope="col" style={{ border: '1px solid black', color: 'white', padding: '5px', textAlign: 'center', width: '10%' }}>
                        {intl.formatMessage({ id: 'report.tissue.name', defaultMessage: '' })}: {state.createdDate}
                      </th>
                      <th scope="col" style={{ border: '1px solid black', color: 'white', padding: '5px', textAlign: 'center' }}>
                        {intl.formatMessage({ id: 'report.measurement.unit', defaultMessage: '' })}: {state.createdDate}
                      </th>
                      <th scope="col" style={{ border: '1px solid black', color: 'white', padding: '5px', textAlign: 'center' }}>
                        {intl.formatMessage({ id: 'report.title2', defaultMessage: '' })}: {state.createdDate}
                      </th>
                      <th scope="col" style={{ border: '1px solid black', color: 'white', padding: '5px', textAlign: 'center' }}>
                        {intl.formatMessage({ id: 'report.quantity', defaultMessage: '' })}: {state.createdDate}
                      </th>
                      <th scope="col" style={{ border: '1px solid black', color: 'white', padding: '5px', textAlign: 'center' }}>
                        {intl.formatMessage({ id: 'report.total', defaultMessage: '' })}: {state.createdDate}
                      </th>
                      <th scope="col" style={{ border: '1px solid black', color: 'white', padding: '5px', textAlign: 'center', width: '5%' }}>
                        {intl.formatMessage({ id: 'report.note', defaultMessage: '' })}: {state.createdDate}
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: 12 }}>
                    {(state.dataList || []).map((item: any, index: number) => {
                      return item.id === 99999 ? (
                        <tr>
                          <td colSpan={3} style={{ border: '1px solid black', fontWeight: 'bold', paddingLeft: 10 }}>
                            {item.name}
                          </td>
                          <td style={{ border: '1px solid black', fontWeight: 'bold', paddingLeft: 10 }}>{item.quantity}ш</td>
                          <td style={{ border: '1px solid black', fontWeight: 'bold', paddingLeft: 10 }}>{item.price}₮</td>
                          <td style={{ border: '1px solid black', fontWeight: 'bold', paddingLeft: 10 }}>{item.totalCount}₮</td>
                          <td style={{ border: '1px solid black', fontWeight: 'bold', paddingLeft: 10 }}>{item.description}</td>
                        </tr>
                      ) : (
                        <tr>
                          <td style={{ border: '1px solid black', textAlign: 'center' }}>{index + 1}</td>
                          <td style={{ border: '1px solid black', paddingLeft: 10 }}>{item.name}</td>
                          <td style={{ border: '1px solid black', paddingLeft: 10 }}>{item.unit}</td>
                          <td style={{ border: '1px solid black', paddingLeft: 10 }}>{item.quantity}ш</td>
                          <td style={{ border: '1px solid black', paddingLeft: 10 }}>{item.price}₮</td>
                          <td style={{ border: '1px solid black', paddingLeft: 10 }}>{item.quantity * item.price}₮</td>
                          <td style={{ border: '1px solid black', paddingLeft: 10 }}>{item.description}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                {' '}
                {intl.formatMessage({ id: 'report.blank', defaultMessage: '' })}!
              </div>
            )}
          </div>
        </div>
        <div className="row" style={{ marginTop: 50 }}>
          <div
            className="col-4 d-flex justify-content-center align-items-center"
            style={{ fontSize: 20, fontWeight: 'bold', backgroundColor: '#404A9B', color: 'white' }}
          >
            {intl.formatMessage({ id: 'report.original', defaultMessage: '' })}
          </div>
          <div
            className="col-8"
            style={{ padding: '10px 0', textAlign: 'center', fontSize: 18, fontWeight: 'bold', backgroundColor: '#FFBE00', color: 'white' }}
          >
            {intl.formatMessage({ id: 'report.control', defaultMessage: '' })} <br />
            <a href="https://www.mypost.mn" target="_blank">
              www.mypost.mn
            </a>{' '}
            <br />
            {intl.formatMessage({ id: 'phone', defaultMessage: '' })}: 7701-2424
          </div>
        </div>
      </div>
  )
}

export default ItemReport
