import fetchWithTimeout from './fetchWithTimeout'
import * as querystring from 'querystring'
import config from '../config'

let headers: any = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}
class NetworkApi {
  static parseUrl = (url: string): string => {
    return `${url.indexOf('http') == 0 ? '' : config.get('deliveryApiUrl')}${url}`
  }
  static requestGet = async (url: string, params?: any,head?:any) => {
    let header = {
      ...headers,
      ...head
    }
    
    if(localStorage.getItem('accessToken')){
      header.accesstoken = localStorage.getItem('accessToken')
    }

    //console.log(url + ' requestGet headers:', header)

    try {
      if (params) {
        url += '?' + querystring.stringify(params)
      }
      let response: any = await fetchWithTimeout(NetworkApi.parseUrl(url), {
        headers: {
          ...header,
        },
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        crossDomain: true,
        withCredentials: true,
      })
      if(response.status >= 200 && response.status < 300){
        let result = await response.json();
        return {resultCode: response.status, result: (result || {}).result || {} }
      }else{
        let result = await response.json();
        return {resultCode: response.status, message: await result.message}
      }
    } catch (e) {
      return { resultCode: 500, message: 'system error' }
    }
  }
  static requestDelete = async (url: string, params?: any) => {
    let header = {
      ...headers,
    }
    if(localStorage.getItem('accessToken')){
      header.accesstoken = localStorage.getItem('accessToken')
    }
    try {
      if (params) {
        url += '?' + querystring.stringify(params)
      }
      let response: any = await fetchWithTimeout(NetworkApi.parseUrl(url), {
        headers: {
          ...header,
        },
        method: 'DELETE',
        mode: 'cors',
        credentials: 'include',
        crossDomain: true,
        withCredentials: true,
      })
      if(response.status >= 200 && response.status < 300){
        let result = await response.json();
        return {resultCode: response.status, result: (result || {}).result || {} }
      }else{
        let result = await response.json();
        return {resultCode: response.status, message: await result.message}
      }
    } catch (e) {
      return { resultCode: 500, message: 'system error' }
    }
  }
  static requestPost = async (url: string, body?: any, params?: any,head?:any) => {
    let header = {
      ...headers,
      ...head
    }
    if(localStorage.getItem('accessToken')){
      header.accesstoken = localStorage.getItem('accessToken')
    }
    try {
      let response: any = await fetchWithTimeout(NetworkApi.parseUrl(url), {
        headers: {
          ...header,
        },
        method: 'POST',
        body: JSON.stringify(body),
        mode: 'cors',
        credentials: 'include',
        withCredentials: true,
      })
      if(response.status >= 200 && response.status < 300){
        let result = await response.json();
        return {resultCode: response.status, result: (result || {}).result || {} }
      }else{
        let result = await response.json();
        return {resultCode: response.status, message: await result.message}
      }
    } catch (e) {      
      return { resultCode: 500, message: 'system error' }
    }
  }
  static requestPut = async (url: string, body?: any, params?: any) => {
    let header = {
      ...headers,
    }
    if(localStorage.getItem('accessToken')){
      header.accesstoken = localStorage.getItem('accessToken')
    }
    try {
      let response: any = await fetchWithTimeout(NetworkApi.parseUrl(url), {
        headers: {
          ...header,
        },
        method: 'PUT',
        body: JSON.stringify(body),
        mode: 'cors',
        credentials: 'include',
        withCredentials: true,
      })
      if(response.status >= 200 && response.status < 300){
        let result = await response.json();
        return {resultCode: response.status, result: (result || {}).result || {} }
      }else{
        let result = await response.json();
        return {resultCode: response.status, message: await result.message}
      }
    } catch (e) {      
      return { resultCode: 500, message: 'system error' }
    }
  }
}

export default NetworkApi