import React from "react";
import {Button, Form, Spinner} from "react-bootstrap";

interface CustomButtonProps {
    loading?: boolean
    reload?: Function
    title: string
    style?: any
    disabled?: boolean

    type?: "submit" | "button" | undefined
}

const CustomButton = ({loading, reload, title, style, disabled, type}: CustomButtonProps) => {
    return (
        <Button style={style}
                type={type ? type : "button"}
                className={'btn primaryBackgroundColor fontRegular borderRadiusSmall all-hover paddingTop10 paddingBottom10 custom-button'}
                disabled={disabled || loading}
                onClick={() => reload ? reload() : null}
        >
            {
                loading ? (
                    <Spinner style={{marginRight: 10}} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : null
            }
            <span>{title}</span>
        </Button>
    )
}
export default CustomButton