import * as React from 'react'
import UserMenu from './UserMenu'
import MainInfo from './edit/MainInfo'
import { useCallback, useState } from 'react'
import Password from './edit/Password'
import Phone from './edit/Phone'
import Email from './edit/Email'

const InfoEdit = () => {
  const [main, updateMain] = useState(true)
  const [password, updatePassword] = useState(false)
  const [phone, updatePhone] = useState(false)
  const [mail, updateMail] = useState(false)
  const showMain = () => {
    updateMain(true)
    updatePassword(false)
    updatePhone(false)
    updateMail(false)
  };
  const showPassword = () => {
    updateMain(false)
    updatePassword(true)
    updatePhone(false)
    updateMail(false)
  };
  const showPhone = () => {
    updateMain(false)
    updatePassword(false)
    updatePhone(true)
    updateMail(false)
  };
  const showMail = () => {
    updateMain(false)
    updatePassword(false)
    updatePhone(false)
    updateMail(true)
  };
  return (
    <main>
      <div className="account lightBackgroundColor paddingTop40 paddingBottom70">
        <div className="container">
          <div className="row">
            <UserMenu/>
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12">
              <div className="headTab">
                <div className="row">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div className={`borDer borderRadiusSmall ${main === true ? 'primaryBackgroundColor' : 'defaultBackgroundColor'}`} onClick={showMain}>
                      <a className="d-flex align-items-center paddingTop15 paddingBottom15 paddingLeft20">
                        <div className="marginRight15">
                          <div className={`coverBackgroundSize ${main === true ? 'lightBackgroundColor' : 'secondaryBackgroundColor'}`}
                               style={{ width: 32, height: 32, WebkitMaskImage: 'url(/images/user.png)' }}/>
                        </div>
                        <div className={`textMedium font16 ${main === true ? 'text-light' : 'text-Primary'}`}>
                          Хувийн мэдээлэл<span className="d-block fontLight font14">Munkh-Erdene</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div className={`borDer borderRadiusSmall ${password === true ? 'primaryBackgroundColor' : 'defaultBackgroundColor'}`} onClick={showPassword}>
                      <a className="d-flex align-items-center paddingTop15 paddingBottom15 paddingLeft20">
                        <div className="marginRight15">
                          <div className={`coverBackgroundSize ${password === true ? 'lightBackgroundColor' : 'secondaryBackgroundColor'}`}
                               style={{ width: 32, height: 32, WebkitMaskImage: 'url(/images/password.png)' }}/>
                        </div>
                        <div className={`textMedium font16 ${password === true ? 'text-light' : 'text-Primary'}`}>
                          Нууц үг<span className="d-block fontLight font14">Шинэчлэх өөрчлөх</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div className={`borDer borderRadiusSmall ${phone === true ? 'primaryBackgroundColor' : 'defaultBackgroundColor'}`} onClick={showPhone}>
                      <a className="d-flex align-items-center paddingTop15 paddingBottom15 paddingLeft20">
                        <div className="marginRight15">
                          <div className={`coverBackgroundSize ${phone === true ? 'lightBackgroundColor' : 'secondaryBackgroundColor'}`}
                               style={{ width: 32, height: 32, WebkitMaskImage: 'url(/images/mobile.png)' }}/>
                        </div>
                        <div className={`textMedium font16 ${phone === true ? 'text-light' : 'text-Primary'}`}>
                          Гар утас<span className="d-block fontLight font14">Баталгаажаагүй</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div className={`borDer borderRadiusSmall ${mail === true ? 'primaryBackgroundColor' : 'defaultBackgroundColor'}`} onClick={showMail}>
                      <a className="d-flex align-items-center paddingTop15 paddingBottom15 paddingLeft20">
                        <div className="marginRight15">
                          <div className={`coverBackgroundSize ${mail === true ? 'lightBackgroundColor' : 'secondaryBackgroundColor'}`}
                               style={{ width: 32, height: 32, WebkitMaskImage: 'url(/images/email.png)' }}/>
                        </div>
                        <div className={`textMedium font16 ${mail === true ? 'text-light' : 'text-Primary'}`}>
                          Имэйл хаяг<span className="d-block fontLight font14">Баталгаажсан</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {
                main === true ? (
                  <MainInfo/>
                ) : null
              }
              {
                password === true ? (
                  <Password/>
                ) : null
              }
              {
                phone === true ? (
                  <Phone/>
                ) : null
              }
              {
                mail === true ? (
                  <Email/>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default InfoEdit