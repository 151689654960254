import * as React from 'react'
import { Link } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { useState } from 'react'
import fetchApi from '../../../api/fetchApi'
import config from '../../../config'
import { notification } from 'antd'
import { useIntl } from 'react-intl'

interface State {
  loading: boolean
  result: any
}

const initialState: State = {
  loading: true,
  result: {},
}

const About = () => {
  const intl = useIntl();
  const [state, updateState] = useState(initialState)

  const fetchPage = useCallback(async ()=>{
    try {
      updateState({
        ...state,
        loading: true,
      })
      const response = await fetchApi.requestGet(`${config.get('myPostApiUrl')}/api/page/detail/bidnii-tukhai`)
      updateState({
        loading: false,
        result: response.result || {},
      })
    } catch (e: any) {
      notification.error({
        message: e.message?.[0] || 'Алдаа гарлаа дахин оролдоно уу',
      })
    }
  },[state])
  useEffect(()=>{
    fetchPage()
  },[])

  return (
    <main>
      <div className="about">
        <div className="defaultBackgroundColor">
          <div className="feachuredImage paddingTop70 paddingBottom70"
               style={{backgroundImage: 'url(images/header.jpg)'}}>
            <h1 className="title margin0 padding0 textLight text-center">{intl.formatMessage({id: 'app.title', defaultMessage: ''})}</h1>
            <div className="breadcrumb marginTop10">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb margin0 font17">
                  <li className="breadcrumb-item textLight">{intl.formatMessage({id: 'home.page', defaultMessage: ''})}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {intl.formatMessage({id: 'about.us', defaultMessage: ''})}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="navigation d-flex justify-content-center paddingTop20 paddingBottom20 font16 borderBottom ">
            <Link className="position-relative active" to={'/about'}>{intl.formatMessage({id: 'about.us', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/workplace'}>{intl.formatMessage({id: 'workplace', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/help'}>{intl.formatMessage({id: 'help.center', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/contact'}>{intl.formatMessage({id: 'contact.us', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/branch'}>{intl.formatMessage({id: 'branch.location', defaultMessage: ''})}</Link>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"/>
              <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-12 col-sm-12">
                <div className="paddingTop50 paddingBottom50" dangerouslySetInnerHTML={{__html: state.result?.page?.content || ""}}/>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"/>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default About