import * as React from 'react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import fetchApi from '../../api/fetchApi'
import config from '../../config'
import { formatMoney } from '../../utils/utils'
import { Modal } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import UserContext from '../../context/UserContext'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

interface State {
  loading: boolean
  formData: any
  aimag: any
  codeList: any[]
  senderSum: any
  senderBag: any
  receiverSum: any
  receiverBag: any
  data: any
  calculate: any
}

const initialState: State = {
  loading: true,
  formData: {},
  aimag: {},
  codeList: [],
  senderSum: {},
  senderBag: {},
  receiverSum: {},
  receiverBag: {},
  calculate: null,
  data: {
    serviceId: 1,
    additionalServiceList: [],
    sender: {
      email: '',
      fname: '',
      lname: '',
      phone1: '',
      phone2: '',
      zipcode: '',
      w3w: '',
      gps: {
        lat: '',
        lng: '',
      },
      address: {
        id: '',
        aimag: '',
        apartmentCode: '',
        bag: '',
        detail: '',
        sum: '',
        zipcode: '',
      },
    },
    receiver: {
      email: '',
      fname: '',
      lname: '',
      phone1: '',
      phone2: '',
      zipcode: '',
      w3w: '',
      gps: {
        lat: '',
        lng: '',
      },
      address: {
        id: '',
        aimag: '',
        apartmentCode: '',
        bag: '',
        detail: '',
        sum: '',
        zipcode: '',
      },
    },
    items: [
      {
        deliveryCost: 0,
        name: 'бараа',
        weightTypeName: 'Жижиг',
        weightTypeNo: 1,
        weight: 0,
        warningName: 'Энгийн',
        warningValue: 'HANDLE_WITH_CARE',
      },
    ],
  },
}
const ParcelView = () => {
  const intl = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { addToast } = useToasts()
  const [state, updateState] = useState(initialState)
  const [loading, updateLoading] = useState(false)
  const formData = useCallback(async () => {
    let [formData, zipcode, codeList] = await Promise.all([
      await fetchApi.requestGet(`${config.get('orderApiUrl')}/api/front/order/formData`),
      await fetchApi.requestGet(`${config.get('coreApiUrl')}/api/zipcode/childList?zipcode=20000`),
      await fetchApi.requestGet(`${config.get('coreApiUrl')}/api/systemcode/list/WARNING`),
    ])
    updateState({
      ...state,
      formData: formData.result?.result || {},
      aimag: zipcode?.result?.result || {},
      codeList: codeList?.result?.result?.codeList || [],
      data: {
        ...state.data,
        sender: {
          ...state.data.sender,
          zipcode: '',
          address: {
            ...state.data.sender.address,
            aimag: '',
            bag: '',
            sum: '',
            zipcode: '',
          },
        },
      },
    })
  }, [state])
  const senderAimag = useCallback(async (zipcode: string) => {
    const response = await fetchApi.requestGet(`${config.get('coreApiUrl')}/api/zipcode/childList?zipcode=${zipcode}`)
    updateState({
      ...state,
      senderSum: response.result?.result || {},
      senderBag: {},
      data: {
        ...state.data,
        sender: {
          ...state.data.sender,
          zipcode: '',
          address: {
            ...state.data.sender.address,
            aimag: '',
            bag: '',
            sum: '',
            zipcode: '',
          },
        },
      },
    })
  }, [state])
  const senderSum = useCallback(async (zipcode: string) => {
    const response = await fetchApi.requestGet(`${config.get('coreApiUrl')}/api/zipcode/childList?zipcode=${zipcode}`)
    updateState({
      ...state,
      senderBag: response.result?.result || {},
    })
  }, [state])
  const senderBag = useCallback(async (zipcode: string) => {
    const bagzipcode = state.senderBag.childList.find((item: any) => item.zipcode === zipcode)
    updateState({
      ...state,
      calculate: Date.now(),
      data: {
        ...state.data,
        sender: {
          ...state.data.sender,
          zipcode: bagzipcode.zipcode,
          address: {
            ...state.data.sender.address,
            aimag: state.senderSum?.zipcode?.nameShortMn || null,
            bag: bagzipcode.nameMn,
            sum: state.senderBag?.zipcode?.nameShortMn || null,
            zipcode: bagzipcode.zipcode,
          },
        },
      },
    })
  }, [state])
  const receiverAimag = useCallback(async (zipcode: string) => {
    const response = await fetchApi.requestGet(`${config.get('coreApiUrl')}/api/zipcode/childList?zipcode=${zipcode}`)
    updateState({
      ...state,
      receiverSum: response.result?.result || {},
      receiverBag: {},
      data: {
        ...state.data,
        receiver: {
          ...state.data.receiver,
          zipcode: '',
          address: {
            ...state.data.receiver.address,
            aimag: '',
            bag: '',
            sum: '',
            zipcode: '',
          },
        },
      },
    })
  }, [state])
  const receiverSum = useCallback(async (zipcode: string) => {
    const response = await fetchApi.requestGet(`${config.get('coreApiUrl')}/api/zipcode/childList?zipcode=${zipcode}`)
    updateState({
      ...state,
      receiverBag: response.result?.result || {},
    })
  }, [state])
  const receiverBag = useCallback(async (zipcode: string) => {
    const bagzipcode = state.receiverBag.childList.find((item: any) => item.zipcode === zipcode)
    updateState({
      ...state,
      calculate: Date.now(),
      data: {
        ...state.data,
        receiver: {
          ...state.data.receiver,
          zipcode: bagzipcode.zipcode,
          address: {
            ...state.data.receiver.address,
            aimag: state.receiverSum?.zipcode?.nameShortMn || null,
            bag: bagzipcode.nameMn,
            sum: state.receiverBag?.zipcode?.nameShortMn || null,
            zipcode: bagzipcode.zipcode,
          },
        },
      },
    })
  }, [state])
  const addItem = useCallback(() => {
    const item = {
      deliveryCost: 0,
      name: 'бараа',
      weightTypeName: 'Жижиг',
      weightTypeNo: 1,
      weight: 0,
      warningName: 'Энгийн',
      warningValue: 'HANDLE_WITH_CARE',
    }
    const items = state.data.items || []
    items.push(item)
    updateState({
      ...state,
      calculate: Date.now(),
      data: {
        ...state.data,
        items: items,
      },
    })
  }, [state])
  const itemRemove = useCallback((index: number) => {
    const items = (state.data.items || []).filter((item: any, i: number) => i !== index)
    if (items.length <= 0) {
      return
    }
    updateState({
      ...state,
      calculate: Date.now(),
      data: {
        ...state.data,
        items: items,
      },
    })
  }, [state])
  const itemValueChange = useCallback(async (value, name, index) => {
    console.log(value + ' ' + name + ' ' + index)
    const items = (state.data?.items || []).map((item: any, ind: number) => {
      if (ind === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    updateState({
      ...state,
      calculate: Date.now(),
      data: {
        ...state.data,
        items,
      },
    })
  }, [state])
  const getDeliveryCostList = useCallback(async () => {
    const payload = {
      receiverZipcode: state.data.receiver.zipcode,
      senderZipcode: state.data.sender.zipcode,
      serviceId: state.data.serviceId,
      weightTypeNoList: state.data.items.map((item: any) => item.weightTypeNo),
    }
    const response = await fetchApi.requestPost(`${config.get('orderApiUrl')}/api/front/order/getDeliveryCostList`, payload)
    const deliveryCostList = response.result?.result?.deliveryCostList || []
    const items = (state.data.items || []).map((item: any, index: number) => {
      return {
        ...item,
        deliveryCost: deliveryCostList[index].deliveryCost,
      }
    })
    updateState({
      ...state,
      calculate: null,
      data: {
        ...state.data,
        items,
      },
    })
  }, [state])
  const additionalService = useCallback((item: any) => {
    let additionalServiceList = (state.data.additionalServiceList || [])
    if (additionalServiceList.indexOf(item.id) > -1) {
      additionalServiceList = additionalServiceList.filter((id: number) => id != item.id)
    } else {
      additionalServiceList.push(item.id)
    }
    updateState({
      ...state,
      calculate: Date.now(),
      data: {
        ...state.data,
        additionalServiceList,
      },
    })
  }, [state])
  useEffect(() => {
    if (state.calculate && state.data.sender.zipcode && state.data.receiver.zipcode) {
      getDeliveryCostList()
    }
  }, [state.calculate])
  useEffect(() => {
    formData()
  }, [])
  const totalWeight = useMemo(() => {
    return (state.data?.items || []).reduce((total: number, item: any) => total + parseFloat(item.weight),
      0)
  }, [state])
  const totalPrice = useMemo(() => {
    return (state.data?.items || []).reduce((total: number, item: any) => total + item.deliveryCost,
      0)
  }, [state])
  const servicePrice = useMemo(() => {
    let additionalServiceListIds = (state.data.additionalServiceList || [])
    const additionalServiceList = state.formData?.additionalServiceList || []
    const selectAdditionalService = additionalServiceList.filter((item: any) => additionalServiceListIds.indexOf(item.id) > -1)
    return (selectAdditionalService || []).reduce((total: number, item: any) => total + item.cost,
      0)
  }, [state])
  const createOrder = useCallback(async () => {
    if (!state.data?.sender?.fname || !state.data?.sender?.lname) {
      addToast(intl.formatMessage({id: 'fill.sender.name', defaultMessage: ''}), { appearance: 'error' })
      return
    }
    if (!state.data?.sender?.phone1) {
      addToast(intl.formatMessage({id: 'fill.sender.phone', defaultMessage: ''}), { appearance: 'error' })
      return
    }
    if (!state.data?.sender?.zipcode || !state.data?.sender?.address?.detail) {
      addToast(intl.formatMessage({id: 'fill.sender.address', defaultMessage: ''}), { appearance: 'error' })
      return
    }
    if (!state.data?.receiver?.fname || !state.data?.receiver?.lname) {
      addToast(intl.formatMessage({id: 'fill.receiver.name', defaultMessage: ''}), { appearance: 'error' })
      return
    }
    if (!state.data?.receiver?.phone1) {
      addToast(intl.formatMessage({id: 'fill.receiver.phone', defaultMessage: ''}), { appearance: 'error' })
      return
    }
    if (!state.data?.receiver?.zipcode || !state.data?.receiver?.address?.detail) {
      addToast(intl.formatMessage({id: 'fill.receiver.address', defaultMessage: ''}), { appearance: 'error' })
      return
    }
    updateLoading(true)
    let additionalServiceListIds = (state.data.additionalServiceList || [])
    const data = state.data || {}
    const payload = {
      frontMemberId: user.id,
      paymentType: "PRE_PAID",
      serviceId: data.serviceId,
      svc: 'WEB',
      additionalServiceList: additionalServiceListIds.map((id: any) => {
        return { id, selected: true }
      }),
      sender: state.data.sender,
      receiver: state.data.receiver,
      lineItemList: (data.items || []).map((item: any, index: number) => {
        return {
          name: item.name,
          deliveryCost: item.delvieryCost,
          lineItemDescriptionList: [],
          additionalCost: 0,
          weight: item.weight,
          weightTypeNo: item.weightTypeNo,
          warningValue: item.warningValue,
        }
      }),
    }
    const response = await fetchApi.requestOrderPost(`${config.get('orderApiUrl')}/api/front/order/create`, payload)
    if (response.success) {
      const order = response.result?.result?.order || {}
      const url = response.result?.result?.url || ""
      if (order.id) {
        // updatePayment({
        //   order: order,
        //   show: true,
        //   url
        // })
        navigate(`/order/payment/${order.id}`)
      } else {
        addToast(intl.formatMessage({id: 'order.error', defaultMessage: ''}), { appearance: 'error' })
      }
    } else {
      addToast(intl.formatMessage({id: 'order.error', defaultMessage: ''}), { appearance: 'error' })
    }
    updateLoading(false)
  }, [state])
  return (
    <main>
      <div className="about">
        <div className="defaultBackgroundColor">
          <div className="feachuredImage paddingTop70 paddingBottom70"
            style={{ backgroundImage: 'url(/images/header.jpg)' }}>
            <h1 className="title margin0 padding0 textLight text-center">
            {intl.formatMessage({id: 'payment.title', defaultMessage: ''})}
            </h1>
            <div className="breadcrumb marginTop10">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb margin0 font17">
                  <li className="breadcrumb-item textLight">{intl.formatMessage({id: 'individual', defaultMessage: ''})}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {intl.formatMessage({id: 'payment.title', defaultMessage: ''})}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-12 col-sm-12">
                <div className="send">
                  <div className="paddingTop50 paddingBottom70">
                    <form>
                      <div className="step_1 marginBottom50">
                        {/*<div className="text-left fontBold font20">*/}
                        {/*  Алхам 1. Хаанаас, хаашаа*/}
                        {/*</div>*/}
                        <div className="first">
                          <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                              <h3>{intl.formatMessage({id: 'sender', defaultMessage: ''})}</h3>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'last.name', defaultMessage: ''})}
                                </div>
                                <input type="text" value={state.data.sender.fname}
                                  onChange={(e) => updateState({
                                    ...state,
                                    data: {
                                      ...state.data,
                                      sender: {
                                        ...state.data.sender,
                                        fname: e.target.value,
                                      },
                                    },
                                  })} className="form-control" />
                              </div>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'first.name', defaultMessage: ''})}
                                </div>
                                <input type="text" value={state.data.sender.lname}
                                  onChange={(e) => updateState({
                                    ...state,
                                    data: {
                                      ...state.data,
                                      sender: {
                                        ...state.data.sender,
                                        lname: e.target.value,
                                      },
                                    },
                                  })}
                                  className="form-control" />
                              </div>
                              <div className="marginTop15">
                                <div className="row">
                                  <div
                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <div
                                      className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'phone', defaultMessage: ''})}
                                      1
                                    </div>
                                    <input type="text"
                                      value={state.data.sender.phone1}
                                      onChange={(e) => updateState({
                                        ...state,
                                        data: {
                                          ...state.data,
                                          sender: {
                                            ...state.data.sender,
                                            phone1: e.target.value,
                                          },
                                        },
                                      })}
                                      className="form-control" />
                                  </div>
                                  <div
                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <div
                                      className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'phone', defaultMessage: ''})}
                                      2
                                    </div>
                                    <input type="text"
                                      value={state.data.sender.phone2}
                                      onChange={(e) => updateState({
                                        ...state,
                                        data: {
                                          ...state.data,
                                          sender: {
                                            ...state.data.sender,
                                            phone2: e.target.value,
                                          },
                                        },
                                      })}
                                      className="form-control" />
                                  </div>
                                </div>
                              </div>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'city', defaultMessage: ''})}
                                </div>
                                <select className="form-control"
                                  value={state.senderSum?.zipcode?.zipcode || ''}
                                  onChange={(e) => senderAimag(e.target.value)}>
                                  <option value="">{intl.formatMessage({id: 'select.city', defaultMessage: ''})}</option>
                                  {
                                    (state.aimag?.childList || []).map((item: any) => {
                                      return (
                                        <option
                                          value={item.zipcode}>{item.nameMn}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'district', defaultMessage: ''})}
                                </div>
                                <select className="form-control"
                                  onChange={(e) => senderSum(e.target.value)}
                                  value={state.senderBag?.zipcode?.zipcode || ''}>
                                  <option value="">{intl.formatMessage({id: 'select.district', defaultMessage: ''})}</option>
                                  {
                                    (state.senderSum?.childList || []).map((item: any) => {
                                      return (
                                        <option
                                          value={item.zipcode}>{item.nameMn}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'khoroo', defaultMessage: ''})}
                                </div>
                                <select className="form-control"
                                  value={state.data.sender?.zipcode || ''}
                                  onChange={(e) => senderBag(e.target.value)}>
                                  <option value="">{intl.formatMessage({id: 'select.khoroo', defaultMessage: ''})}</option>
                                  {
                                    (state.senderBag?.childList || []).map((item: any) => {
                                      return (
                                        <option
                                          value={item.zipcode}>{item.nameMn}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'address.detail', defaultMessage: ''})}
                                </div>
                                <textarea
                                  onChange={(e) => updateState({
                                    ...state,
                                    data: {
                                      ...state.data,
                                      sender: {
                                        ...state.data.sender,
                                        address: {
                                          ...state.data.sender.address,
                                          detail: e.target.value,
                                        }
                                      },
                                    },
                                  })}
                                  className="form-control">{state.data.sender.address.detail}</textarea>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                              <h3>{intl.formatMessage({id: 'receiver', defaultMessage: ''})}</h3>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'last.name', defaultMessage: ''})}
                                </div>
                                <input type="text" value={state.data.receiver.fname}
                                  onChange={(e) => updateState({
                                    ...state,
                                    data: {
                                      ...state.data,
                                      receiver: {
                                        ...state.data.receiver,
                                        fname: e.target.value,
                                      },
                                    },
                                  })} className="form-control" />
                              </div>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'first.name', defaultMessage: ''})}
                                </div>
                                <input type="text" value={state.data.receiver.lname}
                                  onChange={(e) => updateState({
                                    ...state,
                                    data: {
                                      ...state.data,
                                      receiver: {
                                        ...state.data.receiver,
                                        lname: e.target.value,
                                      },
                                    },
                                  })}
                                  className="form-control" />
                              </div>
                              <div className="marginTop15">
                                <div className="row">
                                  <div
                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <div
                                      className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'phone', defaultMessage: ''})}
                                      1
                                    </div>
                                    <input type="text"
                                      value={state.data.receiver.phone1}
                                      onChange={(e) => updateState({
                                        ...state,
                                        data: {
                                          ...state.data,
                                          receiver: {
                                            ...state.data.receiver,
                                            phone1: e.target.value,
                                          },
                                        },
                                      })}
                                      className="form-control" />
                                  </div>
                                  <div
                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <div
                                      className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'phone', defaultMessage: ''})}
                                      2
                                    </div>
                                    <input type="text"
                                      value={state.data.sender.phone2}
                                      onChange={(e) => updateState({
                                        ...state,
                                        data: {
                                          ...state.data,
                                          receiver: {
                                            ...state.data.receiver,
                                            phone2: e.target.value,
                                          },
                                        },
                                      })}
                                      className="form-control" />
                                  </div>
                                </div>
                              </div>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'city', defaultMessage: ''})}
                                </div>
                                <select className="form-control"
                                  value={state.receiverSum?.zipcode?.zipcode || ''}
                                  onChange={(e) => receiverAimag(e.target.value)}>
                                  <option value="">{intl.formatMessage({id: 'select.city', defaultMessage: ''})}</option>
                                  {
                                    (state.aimag?.childList || []).map((item: any) => {
                                      return (
                                        <option
                                          value={item.zipcode}>{item.nameMn}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'district', defaultMessage: ''})}
                                </div>
                                <select className="form-control"
                                  onChange={(e) => receiverSum(e.target.value)}
                                  value={state.receiverBag?.zipcode?.zipcode || ''}>
                                  <option value="">{intl.formatMessage({id: 'select.district', defaultMessage: ''})}</option>
                                  {
                                    (state.receiverSum?.childList || []).map((item: any) => {
                                      return (
                                        <option
                                          value={item.zipcode}>{item.nameMn}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'khoroo', defaultMessage: ''})}
                                </div>
                                <select className="form-control"
                                  value={state.data.receiver?.zipcode || ''}
                                  onChange={(e) => receiverBag(e.target.value)}>
                                  <option value="">{intl.formatMessage({id: 'select.khoroo', defaultMessage: ''})}</option>
                                  {
                                    (state.receiverBag?.childList || []).map((item: any) => {
                                      return (
                                        <option
                                          value={item.zipcode}>{item.nameMn}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                              <div className="marginTop15">
                                <div
                                  className="fontMedium font16 marginBottom10 marginLeft10">{intl.formatMessage({id: 'address.detail', defaultMessage: ''})}
                                </div>
                                <textarea
                                  onChange={(e) => updateState({
                                    ...state,
                                    data: {
                                      ...state.data,
                                      receiver: {
                                        ...state.data.receiver,
                                        address: {
                                          ...state.data.receiver.address,
                                          detail: e.target.value,
                                        }
                                      },
                                    },
                                  })}
                                  className="form-control">{state.data.receiver.address.detail}</textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="step_2 marginBottom50">
                        <div className="text-left fontBold font20">{intl.formatMessage({id: 'parcel', defaultMessage: ''})}</div>
                        <div className="marginTop40">
                          <div className="row">
                            <table className={'table'}>
                              <tbody>
                                <tr>
                                  <td style={{ width: '30' }}>{intl.formatMessage({id: 'parcel.tissue.name', defaultMessage: ''})}</td>
                                  <td style={{ width: '180' }}>{intl.formatMessage({id: 'parcel.size', defaultMessage: ''})}</td>
                                  <td style={{ width: '128' }}>{intl.formatMessage({id: 'parcel.warning', defaultMessage: ''})}</td>
                                  <td style={{ width: '18' }}>{intl.formatMessage({id: 'parcel.weight', defaultMessage: ''})}</td>
                                  <td style={{ width: '120' }}>{intl.formatMessage({id: 'parcel.unit.price', defaultMessage: ''})}</td>
                                  <td style={{ width: '114' }}>...</td>
                                </tr>
                                {
                                  (state.data.items || []).map((item: any, index: number) => {
                                    return (
                                      <tr key={index}>
                                        <td style={{ width: '30' }}>
                                          <input value={item.name}
                                            onChange={(e) => itemValueChange(e.target.value, 'name', index)}
                                            className="form-control" />
                                        </td>
                                        <td style={{ width: '180' }}>
                                          <select value={item.weightTypeNo}
                                            onChange={(e) => itemValueChange(e.target.value, 'weightTypeNo', index)}
                                            className="form-control">
                                            {
                                              (state.formData?.weightTypeList || []).map((item: any, index: number) => {
                                                return (
                                                  <option key={index}
                                                    value={item.id}>{item.name}</option>
                                                )
                                              })
                                            }

                                          </select>
                                        </td>
                                        <td style={{ width: '128' }}>
                                          <select value={item.warningValue}
                                            onChange={(e) => itemValueChange(e.target.value, 'warningValue', index)}
                                            className="form-control">
                                            {
                                              (state.codeList || []).map((code: any, index: number) => {
                                                return (
                                                  <option key={index}
                                                    value={code.value}>{code.name}</option>
                                                )
                                              })
                                            }
                                          </select>
                                        </td>
                                        <td style={{ width: '18' }}>
                                          <input value={item.weight} type="number"
                                            onChange={(e) => itemValueChange(e.target.value, 'weight', index)}
                                            className="form-control" />
                                        </td>
                                        <td style={{ width: '120' }}>{formatMoney(item.deliveryCost)}</td>
                                        <td style={{ width: 114 }}>
                                          <img src={'/images/icons/delete.png'}
                                            onClick={() => itemRemove(index)} />
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                            <button onClick={() => addItem()} style={{
                              width: 215,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }} type="button"
                              className="all-hover btn primaryBackgroundColor textLight fontMedium paddingTop10 paddingBottom10 paddingLeft40 paddingRight40">
                              <img style={{ width: 20 }}
                                src={'/images/icons/plus-white.png'} />
                              {intl.formatMessage({id: 'add.parcel', defaultMessage: ''})}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-12 col-sm-12">
                <div className="sidebar">
                  <div className="paddingTop50 paddingLeft50 paddingBottom50">
                    <div className="title">
                      <h3 className="text-left fontBold font20 margin0">
                      {intl.formatMessage({id: 'parcel.information', defaultMessage: ''})}
                      </h3>
                    </div>
                    <div className="step_3 content marginTop40 padding30 borDer borderRadiusSmall">
                      <div className="marginTop30">
                        <div className="font18 fontBold marginBottom15">
                        {intl.formatMessage({id: 'main.information', defaultMessage: ''})}
                        </div>
                        <ul className="padding0 margin0">
                          {
                            (state.formData?.serviceList || []).map((item: any, index: number) => {
                              return (
                                <li onClick={() => updateState({
                                  ...state,
                                  calculate: Date.now(),
                                  data: {
                                    ...state.data,
                                    serviceId: item.id,
                                  },
                                })} key={index} style={{ cursor: 'pointer' }}
                                  className="paddingBottom10">
                                  <div className="d-flex align-items-center">
                                    <div className="marginRight15">
                                      <input type="checkbox"
                                        checked={item.id === state.data.serviceId}
                                        className="form-check-input"
                                      />
                                    </div>
                                    <div className="fontRegular font16">
                                      {item.name}
                                    </div>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      <div className="marginTop30">
                        <div className="font18 fontBold marginBottom15">
                        {intl.formatMessage({id: 'additional.service', defaultMessage: ''})}
                        </div>
                        <ul className="padding0 margin0">
                          {
                            (state.formData?.additionalServiceList || []).map((item: any, index: number) => {
                              return (
                                <li key={index} style={{ cursor: 'pointer' }}
                                  onClick={() => additionalService(item)}
                                  className="paddingBottom10">
                                  <div className="d-flex align-items-center">
                                    <div className="marginRight15">
                                      <input type="checkbox"
                                        checked={(state.data?.additionalServiceList || []).indexOf(item.id) > -1}
                                        className="form-check-input"
                                      />
                                    </div>
                                    <div className="fontRegular font16">
                                      {item.name}
                                      <span
                                        className="d-block fontLight text-muted font14">{formatMoney(item.cost)}</span>
                                    </div>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      <div className="marginTop20">
                        <div className="font18 fontBold marginBottom15">
                        {intl.formatMessage({id: 'order.information', defaultMessage: ''})}
                        </div>
                        <ul className="padding0 margin0">
                          <li className="borderBottom paddingBottom10 paddingTop10">
                            <div className="d-flex justify-content-between">
                              <div className="fontLight text-muted font16">
                              {intl.formatMessage({id: 'total.quantity', defaultMessage: ''})}:
                              </div>
                              <div className="fontBold textSecondary font16 d-block">
                                {(state.data?.items || []).length}
                              </div>
                            </div>
                          </li>
                          <li className="borderBottom paddingBottom10 paddingTop10">
                            <div className="d-flex justify-content-between">
                              <div className="fontLight text-muted font16">
                              {intl.formatMessage({id: 'total.weight', defaultMessage: ''})}:
                              </div>
                              <div className="fontBold textSecondary font16 d-block">
                                {totalWeight}
                              </div>
                            </div>
                          </li>
                          <li className="borderBottom paddingBottom10 paddingTop10">
                            <div className="d-flex justify-content-between">
                              <div className="fontLight text-muted font16">
                              {intl.formatMessage({id: 'report.total', defaultMessage: ''})}:
                              </div>
                              <div className="fontBold textSecondary font16 d-block">
                                {formatMoney(totalPrice)}
                              </div>
                            </div>
                          </li>
                          <li className="borderBottom paddingBottom10 paddingTop10">
                            <div className="d-flex justify-content-between">
                              <div className="fontLight text-muted font16">
                              {intl.formatMessage({id: 'additional.service', defaultMessage: ''})}:
                              </div>
                              <div className="fontBold textSecondary font16 d-block">
                                {formatMoney(servicePrice)}
                              </div>
                            </div>
                          </li>
                          <li className="borderBottom paddingBottom10 paddingTop10">
                            <div className="d-flex justify-content-between">
                              <div className="fontLight text-muted font16">
                              {intl.formatMessage({id: 'noat', defaultMessage: ''})}:
                              </div>
                              <div className="fontBold textSecondary font16 d-block">
                                {formatMoney((totalPrice + servicePrice) * 0.1)}
                              </div>
                            </div>
                          </li>
                          <li className="borderBottom paddingBottom10 paddingTop10">
                            <div className="d-flex justify-content-between">
                              <div className="fontLight text-muted font16">
                              {intl.formatMessage({id: 'payment.total', defaultMessage: ''})}:
                              </div>
                              <div className="fontBold textSecondary font16 d-block">
                                {formatMoney((totalPrice + servicePrice))}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="pay marginTop15">
                        {
                          loading ? (
                            <button type="button" style={{ width: '100%' }}
                              className="all-hover btn primaryBackgroundColor textLight fontMedium paddingTop10 paddingBottom10 paddingLeft40 paddingRight40">{intl.formatMessage({id: 'information.processing', defaultMessage: ''})}</button>
                          ) : (
                            <button type="button" style={{ width: '100%' }} onClick={() => createOrder()}
                              className="all-hover btn primaryBackgroundColor textLight fontMedium paddingTop10 paddingBottom10 paddingLeft40 paddingRight40">{intl.formatMessage({id: 'parcel.pay', defaultMessage: ''})}
                            </button>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ParcelView