import React, { useCallback, useEffect, useState } from 'react'
import { detect } from 'detect-browser'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import Home from './Home'
import Track from './Track'
import HeaderView from './layout/HeaderView'
import Footer from './layout/Footer'
import ParcelView from './views/ParcelView'
import About from './views/about/About'
import Contact from './views/about/Contact'
import Help from './views/about/Help'
import Workplace from './views/about/Workplace'
import Branch from './views/about/Branch'
import OnlineShop from './views/OnlineShop'
import Price from './views/Price'
import Login from './user/Login'
import UserInfo from './user/UserInfo'
import Register from './user/Register'
import InfoEdit from './user/InfoEdit'
import RecoverPassword from './user/RecoverPassword'
import Anket from './views/about/Anket'
import Page from './Page'
import UserContext from '../context/UserContext'
import fetchApi from '../api/fetchApi'
import config from '../config'
import ResetPassword from './user/ResetPassword'
import { useCookies } from 'react-cookie'
import Payment from './views/Payment'
import Content from './Content'
import ItemReport from './views/about/ItemReport'
import { IntlProvider } from 'react-intl';
import mnLang from "../../src/lib/mn.json"
import enLang from "../../src/lib/en.json"
import DeliveryDownloader from './views/DeliveryDownloader'

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="/order" element={<ParcelView />} />
            <Route path="/order/payment/:frontOrderId" element={<Payment />} />
            {/*<Route path="/order" element={<OrderRequestView/>}/>*/}
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/help" element={<Help />} />
            <Route path="/anket" element={<Anket />} />
            <Route path="/workplace" element={<Workplace />} />
            <Route path="/branch" element={<Branch />} />
            <Route path="/onlineshop" element={<OnlineShop />} />
            <Route path="/price" element={<Price />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/recover" element={<RecoverPassword />} />
            <Route path="/reset/:token" element={<ResetPassword />} />
            <Route path="/profile" element={<UserInfo />} />
            <Route path="/user/edit/info" element={<InfoEdit />} />
            <Route path="/page/:slug" element={<Page />} />
            <Route path="/content/:slug" element={<Content />} />
            <Route path="/ios" element={<DeliveryDownloader />} ></Route>
          </Route>
          <Route path="/item/:barcode" element={<SubLayout />} >
            <Route index element={<ItemReport />} />
          </Route>          
        </Routes>
      </BrowserRouter>
    </>
  )
}

function SubLayout() {
  const [lang, changeLang] = useState<string>("mn");
  const [loading, updateLoading] = useState(true)

  const updateLanguage = useCallback((lang: string) => {
    localStorage.setItem("language", lang);
    changeLang(lang)
  }, [lang]);

  const initLoad = useCallback(async () => {
    const lng = localStorage.getItem("language") || "mn";
    changeLang(lng)
    updateLoading(false)
  }, [loading])



  useEffect(() => {
    initLoad().then()
  }, [])

  return (
    <>
      <IntlProvider locale={lang} messages={lang == 'mn' ? mnLang : enLang}>
        {loading ? <div>Loading.....</div> : <Outlet />}
      </IntlProvider>
    </>
  )
}

function MainLayout() {
  const [cookies, setCookie, removeCookie] = useCookies(['accessToken'])
  const [lang, changeLang] = useState<string>("mn");
  const [user, changeUser] = useState<any>(null)
  const [loading, updateLoading] = useState(true)

  const updateLanguage = useCallback((lang: string) => {
    localStorage.setItem("language", lang);
    changeLang(lang)
  }, [lang]);

  const initLoad = useCallback(async () => {
    const lng = localStorage.getItem("language") || "mn";
    changeLang(lng)
    try {
      let token = localStorage.getItem('accessToken')
      if (token) {
        const response = await fetchApi.requestGet(`${config.get('myPostApiUrl')}/api/auth/info`)
        if (response.success) {
          changeUser(response.result)
          localStorage.setItem('orderAccessToken', response.result.orderAccessToken)
          updateLoading(false)
        } else {
          changeUser(null)
          updateLoading(false)
        }
      } else {
        changeUser(null)
        updateLoading(false)
      }
    } catch (e: any) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('orderAccessToken')
      changeUser(null)
      updateLoading(false)
    }
  }, [loading])



  useEffect(() => {
    console.log('MainLayout on mount')
    console.log(cookies)
    const accessToken = cookies.accessToken || null
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken)
      removeCookie('accessToken')
    }
    initLoad().then()
  }, [])

  return (
    <>
      <IntlProvider locale={lang} messages={lang == 'mn' ? mnLang : enLang}>
        <ToastProvider autoDismiss={true} autoDismissTimeout={5000}>
          <UserContext.Provider value={{
            user, changeUser, initLoad, changeLanguage: (ln: string) => {
              console.log(ln)
              updateLanguage(ln)
            },
          }}>
            <HeaderView />
            {loading ? <div>Loading.....</div> : <Outlet />}
            <Footer />
          </UserContext.Provider>
        </ToastProvider>
      </IntlProvider>
    </>
  )
}

export default App
