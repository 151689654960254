import * as React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useContext, useEffect, useState } from 'react'
import fetchApi from "../../api/fetchApi";
import config from "../../config";
import {useToasts} from "react-toast-notifications";
import CustomButton from "../lib/CustomButton";
import UserContext from "../../context/UserContext";
import { useIntl } from 'react-intl';
interface State {
  loading: boolean
  email: string
  password: string
}
const initialState: State = {
  loading: false,
  email: "",
  password: ""
}
const Login = () => {
  const intl = useIntl()
  const location = useLocation();
  const {user, changeUser} = useContext(UserContext);
  const [state, updateState] = useState(initialState);
  const { addToast } = useToasts();
  const navigate = useNavigate()
  const onFinish = useCallback(async (e)=>{
    e.preventDefault()
    updateState({...state,loading: true})
    const response = await fetchApi.requestPost(`${config.get('myPostApiUrl')}/api/auth/login`,state);
    if(response.success){
      updateState(initialState);
      if(response.result?.accessToken){
        localStorage.setItem('accessToken',response.result.accessToken);
        localStorage.setItem('orderAccessToken',response.result.orderAccessToken);
      }
      changeUser(response.result);
      navigate('/')
    }else{
      addToast(response.message, { appearance: 'error' })
    }
    updateState({...state,loading: false})
  },[state]);
  useEffect(()=>{
    const search = location.search;
    const verify = new URLSearchParams(search).get('verify')
    if(verify){
      addToast(intl.formatMessage({id: 'login.verify', defaultMessage: ''}), { appearance: 'success' })
      navigate('/login')
    }
  },[])
  return (<main>
      <div className="login defaultBackgroundColor text-center d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="content paddingTop100 paddingBottom100">
            <div className="top">
              <div className="middle paddingBottom25">
                <div className="first paddingBottom25">
                  <h2 className="margin0 font32 fontBold">{intl.formatMessage({id: 'login', defaultMessage: ''})}</h2>
                </div>
                <div className="last">
                  <form onSubmit={(e)=>onFinish(e)}>
                    <div className="paddingBottom5">
                      <input type="text" placeholder="Имэйл" value={state.email} onChange={(e)=>updateState({...state, email: e.target.value})}
                             className="form-control fontRegular borderRadiusSmall"/>
                    </div>
                    <div className="paddingTop10 paddingBottom10">
                      <input type="password" placeholder="Нууц үг" value={state.password} onChange={(e)=>updateState({...state, password: e.target.value})}
                             className="form-control fontRegular borderRadiusSmall"/>
                    </div>
                    <div className="paddingTop10 paddingBottom20 d-flex justify-content-between">
                      <div className="text-start remember d-flex align-items-center">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                        <label className="form-check-label" htmlFor="exampleCheck1">{intl.formatMessage({id: 'remember.me', defaultMessage: ''})}</label>
                      </div>
                      <div className="forget">
                        <Link to={'/recover'} className="textSecondary fontRegular">
                          {intl.formatMessage({id: 'forgot.password', defaultMessage: ''})}
                        </Link>
                      </div>
                    </div>
                    <div className="paddingTop10">
                      <CustomButton loading={state.loading} title={intl.formatMessage({id: 'login', defaultMessage: ''})} type={"submit"}/>
                    </div>
                  </form>
                </div>
              </div>
              <div className="bottom">
                <div className="socialLogin">
                  <div className="top">
                    <div className="title">
                      <h2 className="margin0 font18 position-relative black fontRegular d-inline-block">{intl.formatMessage({id: 'or', defaultMessage: ''})}</h2>
                    </div>
                    <div className="button d-flex align-items-center justify-content-between paddingTop25">
                      <a className="gg borderRadiusSmall d-flex justify-content-center all-hover" href="/api/auth/facebook">
                        <div className="d-flex align-items-center">
                          <div className="icon lightBackgroundColor"
                               style={{ WebkitMaskImage: 'url(/images/icons/small2.svg)' }}/>
                          <div className="paddingLeft10 text textLight fontRegular">Facebook</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="register paddingTop25">
                  <div className="text textMuted font16 fontRegular">
                    {intl.formatMessage({id: 'not.registered', defaultMessage: ''})}
                    <Link to={'/register'} className="textPrimary paddingLeft5 fontRegular">
                      {intl.formatMessage({id: 'register', defaultMessage: ''})}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Login