import * as React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useCallback } from 'react'
import fetchApi from '../api/fetchApi'
import config from '../config'
import { notification } from 'antd'
import { useState } from 'react'
import { useEffect } from 'react'
import ErrorPage from './ErrorPage'

interface State {
  loading: boolean
  result: any
}

const initialState: State = {
  loading: true,
  result: {},
}


const Page = () => {
  const { slug } = useParams()
  const [state, updateState] = useState(initialState)
  const fetchMenu = useCallback(async () => {
    try {
      updateState({
        ...state,
        loading: true,
      })
      const response = await fetchApi.requestGet(`${config.get('myPostApiUrl')}/api/page/detail/${slug}`)
      updateState({
        loading: false,
        result: response.result || {},
      })
    } catch (e: any) {
      notification.error({
        message: e.message?.[0] || 'Алдаа гарлаа дахин оролдоно уу',
      })
    }
  }, [state,slug])
  useEffect(() => {
    fetchMenu().then()
  }, [slug])
  return (
    <>
      {
        state.loading ?
          <div>
            Loading
          </div> : (
            <div className="about">
              {
                state.result?.page ? (
                  <div className="defaultBackgroundColor">
                    <div className="feachuredImage paddingTop70 paddingBottom70"
                         style={{ backgroundImage: `url(${state.result?.page?.cover || ""})` }}>
                      <h1 className="title margin0 padding0 textLight text-center">
                        {state.result?.page?.title || ""}
                      </h1>
                      {/*<div className="breadcrumb marginTop10">*/}
                      {/*  <nav aria-label="breadcrumb">*/}
                      {/*    <ol className="breadcrumb margin0 font17">*/}
                      {/*      <li className="breadcrumb-item textLight">Хувь хүн</li>*/}
                      {/*      <li className="breadcrumb-item">Бүтээгдэхүүн, үйлчилгээ</li>*/}
                      {/*      <li className="breadcrumb-item active" aria-current="page">Дотоод шуудан*/}
                      {/*      </li>*/}
                      {/*    </ol>*/}
                      {/*  </nav>*/}
                      {/*</div>*/}
                    </div>
                    {
                      (state.result?.menu || []).length ? (
                        <div className="navigation d-flex justify-content-center paddingTop20 paddingBottom20 font16 borderBottom ">
                          {
                            state.result?.menu.map((item:any, index: number)=>{
                              return (
                                <Link key={index} className={`position-relative ${state.result.page.slug == item.slug ? "active" : null}`} to={`/page/${item.slug}`}>{item.title}</Link>
                              )
                            })
                          }
                        </div>
                      ) : null
                    }
                    <div className="container">
                      <div className="row">
                        <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"></div>
                        <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-12 col-sm-12">
                          <div className="paddingTop50 paddingBottom50" dangerouslySetInnerHTML={{__html: state.result?.page?.content || ""}}/>
                        </div>
                        <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ErrorPage/>
                )
              }
            </div>
          )
      }
    </>
  )
}

export default Page