import * as React from 'react'

const OnlineShop = () => {
  return (
    <main>
      <div className="about">
        <div className="defaultBackgroundColor">
          <div className="feachuredImage paddingTop70 paddingBottom70"
               style={{backgroundImage: 'url(/images/header.jpg)'}}>
            <h1 className="title margin0 padding0 textLight text-center">
              Онлайн худалдааны хүргэлт
            </h1>
            <div className="breadcrumb marginTop10">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb margin0 font17">
                  <li className="breadcrumb-item textLight">Хувь хүн</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Онлайн худалдааны хүргэлт
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"/>
              <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-12 col-sm-12">
                <div className="paddingTop50 paddingBottom50">
                  <img src="/images/page3.jpg" alt={''}/>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"/>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default OnlineShop