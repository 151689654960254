import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Form, ListGroup, Modal, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import config from '../config'
import NetworkApi from '../api/networkApi'
import { Swiper, SwiperSlide } from 'swiper/react'
import fetchApi from '../api/fetchApi'
import UserContext from "../context/UserContext";
import { useIntl } from 'react-intl'

interface State {
  loading: boolean
  visible: boolean
  hits: any[]
  barcodes: any[]
  notFound: boolean
}

interface Data {
  loading: boolean
  result: any
}
const initialData: Data = {
  loading: true,
  result: {}
}

const dateFormat = 'YYYY-MM-DD HH:mm'
const Home = () => {
  const intl = useIntl();
  const { user } = useContext(UserContext);
  const [show, setShow] = useState(false)
  const [data, updateData] = useState(initialData)
  const [track, setTrack] = useState<State>({
    loading: false,
    visible: false,
    hits: [],
    barcodes: [],
    notFound: false,
  })
  const loaParcel = useCallback(async (q: any) => {
    setTrack((prevState: any) => {
      return {
        ...prevState,
        notFound: false,
        loading: true,
      }
    })
    const response = await NetworkApi.requestGet(`${config.get('coreApiUrl')}/api/order/history`, { q: q })
    const hits = response.result?.hits || []
    let barcodes: any[] = hits.map((hit: any) => (hit._source || {}).barcode)
    let uniquesBarcodes: any[] = []

    barcodes.forEach((c) => {
      if (!uniquesBarcodes.includes(c)) {
        uniquesBarcodes.push(c)
      }
    })
    if (hits.length == 0) {
      setShow(true)
      setTrack({
        ...track,
        loading: false,
        visible: false,
      })
    } else {
      setTrack({
        loading: false,
        hits,
        visible: hits.length != 0,
        barcodes: uniquesBarcodes,
        notFound: hits.length === 0,
      })
    }

  }, [])
  const fetchContent = useCallback(async () => {
    try {
      updateData({
        ...data,
        loading: true
      })
      const response = await fetchApi.requestGet(`${config.get('myPostApiUrl')}/api/slider/list`)
      updateData({
        loading: false,
        result: response.result
      })
    } catch (e: any) {
      console.log('error', e)
    }
  }, [data])
  useEffect(() => {
    fetchContent()
  }, []);
  return (
    <main>
      <div className="top defaultBackgroundColor paddingTop20 paddingBottom20 borderBottom">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <div className="first">
                <Swiper
                  autoplay={true}
                  slidesPerView={1}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}>
                  {
                    (data.result.rows || []).map((row: any, index: number) => {
                      return (
                        <SwiperSlide>
                          <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 col-sm-12">
                              <div className="item d-flex align-items-center">
                                <div>
                                  <div className="title">
                                    <h3 className="textDark font26 fontBold">
                                      {row.title}
                                    </h3>
                                  </div>
                                  <div className="desciption paddingTop10 paddingBottom5">
                                    <p className="textSecondary">
                                      {row.description}
                                    </p>
                                  </div>
                                  <div className="more">
                                    <Link to={`/content/${row.slug}`}
                                      className="d-inline-block primaryBackgroundColor all-hover textLight fontRegular borderRadiusSmall font15">
                                      {intl.formatMessage({ id: 'view.more', defaultMessage: '' })}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 col-sm-12">
                              <div className="image d-flex align-items-center">
                                <img alt={'cover'} src={row.cover} />
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="last d-flex align-items-center">
                <div className="item">
                  <div className="textPrimary font22 fontBold paddingBottom15">
                  {intl.formatMessage({ id: 'branch.location.check', defaultMessage: '' })}
                  </div>
                  <Form className="d-flex border borderRadiusSmall padding5" onSubmit={(e) => {

                    let c: any = e.target
                    let n = c.elements['q']?.value
                    if (n) {
                      loaParcel(n)
                    }
                    e.preventDefault()
                    return false
                  }}>
                    <Form.Control
                      required
                      type="text"
                      className="borderNone fontRegular"
                      placeholder={intl.formatMessage({ id: 'home.input.placeholder', defaultMessage: '' })}
                      name="q"
                    />
                    <button type="submit"
                      className="btn all-hover coverBackgroundContain coverBackgroundPosition primaryBackgroundColor borderRadiusSmall paddingTop10 paddingBottom10 paddingLeft15 paddingRight15">
                      <div className="icon lightBackgroundColor"
                        style={{ WebkitMaskImage: 'url("/images/icons/search.svg")' }} />
                    </button>
                  </Form>
                  <Toast bg="danger" onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Header>

                      <strong className="me-auto">{intl.formatMessage({ id: 'error', defaultMessage: '' })}</strong>

                    </Toast.Header>
                  
                    <Toast.Body>
                    {intl.formatMessage({ id: 'branch.notFound', defaultMessage: '' })}
                      </Toast.Body>
                  </Toast>
                  <div className="marginTop15">
                    <p className="margin0 textSecondary fontRegular">
                    {intl.formatMessage({ id: 'branch.result.number', defaultMessage: '' })}
                     <br />
                     {intl.formatMessage({ id: 'home.example', defaultMessage: '' })}: 1000540, RR94569737MN
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom paddingTop15 paddingBottom15 lightBackgroundColor">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="box border defaultBackgroundColor borderRadiusSmall all-hover">
                <Link to={'/page/boodol'}>
                  <div className="text-center paddingTop50 paddingBottom45">
                    <div className="icon paddingBottom15">
                      <svg height={80} viewBox="0 0 82 91" stroke="none" strokeWidth={1} fillRule="evenodd">
                        <rect fill="#FFFFFF" x={0} y={0} width={72} height={82} rx={8} />
                        <rect stroke="#404b9b" strokeWidth={3} fill="none" x="1.5" y="1.5" width={69} height={79}
                          rx={8} />
                        <rect fill="#FFFFFF" x={10} y={10} width={52} height={10} rx={2} />
                        <rect fill="#404b9b" x={14} y={32} width={26} height={2} rx={1} />
                        <path fill="#404b9b"
                          d="M51.439,36.561 C52.025,37.147 52.975,37.147 53.56,36.561 L59.561,30.561 C60.146,29.975 60.146,29.025 59.561,28.439 C58.975,27.854 58.025,27.854 57.439,28.439 L52.517,33.396 L50.561,31.44 C49.975,30.854 49.025,30.854 48.439,31.439 C47.854,32.025 47.854,32.975 48.439,33.561 L51.439,36.561 Z" />
                        <rect fill="#404b9b" x={14} y={48} width={26} height={2} rx={1} />
                        <path fill="#404b9b"
                          d="M51.439,52.561 C52.025,53.147 52.975,53.147 53.56,52.561 L59.561,46.561 C60.146,45.975 60.146,45.025 59.561,44.439 C58.975,43.854 58.025,43.854 57.439,44.439 L52.517,49.396 L50.561,47.44 C49.975,46.854 49.025,46.854 48.439,47.439 C47.854,48.025 47.854,48.975 48.439,49.561 L51.439,52.561 Z" />
                        <rect fill="#404b9b" x={14} y={64} width={26} height={2} rx={1} />
                        <path fill="#404b9b"
                          d="M51.439,68.561 C52.025,69.147 52.975,69.147 53.56,68.561 L59.561,62.561 C60.146,61.975 60.146,61.025 59.561,60.439 C58.975,59.854 58.025,59.854 57.439,60.439 L52.517,65.396 L50.561,63.44 C49.975,62.854 49.025,62.854 48.439,63.439 C47.854,64.025 47.854,64.975 48.439,65.561 L51.439,68.561 Z" />
                        <path fill="#404b9b"
                          d="M58.454,55.186 C58.455,54.336 58.616,53.591 58.892,52.938 C48.106,53.233 39.419,61.949 39.419,72.647 C39.419,75.184 39.925,77.604 40.815,79.835 C41.136,79.639 41.481,79.461 41.863,79.314 C45.779,77.801 49.44,75.852 52.997,73.73 C55.93,67.892 58.444,61.798 58.454,55.186"
                          opacity="0.200000003" />
                        <path fill="#404b9b"
                          d="M79.922,71.913 C79.922,62.248 72.086,54.412 62.42,54.412 C52.754,54.412 44.918,62.248 44.918,71.913 C44.918,81.579 52.754,89.416 62.42,89.416 C72.086,89.416 79.922,81.579 79.922,71.913" />
                        <path stroke="#FFFFFF" strokeWidth={3} fill="none"
                          d="M79.922,71.913 C79.922,62.248 72.086,54.412 62.42,54.412 C52.754,54.412 44.918,62.248 44.918,71.913 C44.918,81.579 52.754,89.416 62.42,89.416 C72.086,89.416 79.922,81.579 79.922,71.913 Z"
                          strokeLinecap="round" strokeLinejoin="round" />
                        <polyline stroke="#FFFFFF" strokeWidth={3} fill="none" strokeLinecap="round"
                          strokeLinejoin="round" points="53.904 74.822 58.061 78.979 70.774 66.266" />
                      </svg>
                    </div>
                    <div className="title marginTop35">
                      <h4 className="textDark fontMedium font18 margin0">
                      {intl.formatMessage({ id: 'home.products', defaultMessage: '' })}
                        <br />{intl.formatMessage({ id: 'home.service', defaultMessage: '' })}</h4>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="box border defaultBackgroundColor borderRadiusSmall all-hover">
                <Link to={'/branch'}>
                  <div className="text-center paddingTop50 paddingBottom45">
                    <div className="icon paddingBottom15">
                      <svg height={80} viewBox="0 0 84 91" fillRule="evenodd" stroke="#404b9b" strokeWidth={3}
                        fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <polygon stroke="none" strokeWidth={1} fill="#FFFFFF"
                          points="2.233 80.465 2.15 86.215 28.725 79.712 54.975 89.154 81.554 79.712 81.637 73.962 76.642 71.848 28.115 70.777" />
                        <polygon
                          points="2.233 80.465 2.15 86.215 28.725 79.712 54.975 89.154 81.554 79.712 81.637 73.962 76.642 71.848 28.115 70.777" />
                        <polygon stroke="none" strokeWidth={1} fill="#FFFFFF"
                          points="72.788 12.598 50.691 21.026 29.936 11.585 8.854 18.087 2.233 80.466 28.809 73.963 55.058 83.404 81.638 73.963" />
                        <path stroke="#FFFFFF" d="M50.691,21.027 L55.059,83.404" />
                        <path stroke="#FFFFFF" d="M29.248,11.585 L29.247,73.962" />
                        <polyline
                          points="4.101 62.261 2.233 80.465 28.809 73.962 55.058 83.404 81.637 73.962 74.368 23.556" />
                        <polyline points="53.943 19.786 50.691 21.026 29.936 11.585 8.854 18.088 7.167 33.982" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF"
                          d="M74.993,10.466 C74.993,5.514 70.979,1.5 66.027,1.5 C61.076,1.5 57.062,5.514 57.062,10.466 C57.062,15.418 61.076,19.432 66.027,19.432 C70.979,19.432 74.993,15.418 74.993,10.466" />
                        <path
                          d="M74.993,10.466 C74.993,5.514 70.979,1.5 66.027,1.5 C61.076,1.5 57.062,5.514 57.062,10.466 C57.062,15.418 61.076,19.432 66.027,19.432 C70.979,19.432 74.993,15.418 74.993,10.466 Z" />
                        <path d="M66.027,20.709 L66.027,29.91" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF"
                          d="M64.993,50.466 C64.993,45.514 60.979,41.5 56.027,41.5 C51.076,41.5 47.062,45.514 47.062,50.466 C47.062,55.418 51.076,59.432 56.027,59.432 C60.979,59.432 64.993,55.418 64.993,50.466" />
                        <path
                          d="M64.993,50.466 C64.993,45.514 60.979,41.5 56.027,41.5 C51.076,41.5 47.062,45.514 47.062,50.466 C47.062,55.418 51.076,59.432 56.027,59.432 C60.979,59.432 64.993,55.418 64.993,50.466 Z" />
                        <path d="M56.027,60.709 L56.027,69.91" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF"
                          d="M20.318,48.564 C20.318,43.368 16.106,39.155 10.909,39.155 C5.713,39.155 1.5,43.368 1.5,48.564 C1.5,53.76 5.713,57.973 10.909,57.973 C16.106,57.973 20.318,53.76 20.318,48.564" />
                        <path
                          d="M20.318,48.564 C20.318,43.368 16.106,39.155 10.909,39.155 C5.713,39.155 1.5,43.368 1.5,48.564 C1.5,53.76 5.713,57.973 10.909,57.973 C16.106,57.973 20.318,53.76 20.318,48.564 Z" />
                        <path d="M10.909,57.973 L10.909,67.384" />
                      </svg>
                    </div>
                    <div className="title marginTop35">
                      <h4 className="textDark fontMedium font18 margin0">{intl.formatMessage({ id: 'home.branch', defaultMessage: '' })}<br />{intl.formatMessage({ id: 'home.branch.location', defaultMessage: '' })}</h4>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="box border defaultBackgroundColor borderRadiusSmall all-hover">
                <Link to={'/page/t-v-aguulakh'}>
                  <div className="text-center paddingTop50 paddingBottom45">
                    <div className="icon paddingBottom15">
                      <svg height={80} viewBox="0 0 100 86" fillRule="evenodd" stroke="#404b9b" strokeWidth={3}
                        fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <polygon stroke="none" strokeWidth={1} fill="#fff" points="7 84 59 84 59 32 7 32" />
                        <path stroke="none" strokeWidth={1} fill="#fff"
                          d="M59.256,45 L7.744,45 C6.781,45 6,44.105 6,43 C6,41.895 6.781,41 7.744,41 L59.256,41 C60.219,41 61,41.895 61,43 C61,44.105 60.219,45 59.256,45 Z"
                          opacity="0.2" />
                        <polygon points="7.5 84.5 59.5 84.5 59.5 41.5 7.5 41.5" />
                        <path stroke="none" strokeWidth={1} fill="#404b9b"
                          d="M7.628,40.868 L59.876,40.868 L7.628,40.868 Z" />
                        <polygon points="5.5 41.5 60.5 41.5 60.5 33.5 5.5 33.5" />
                        <path stroke="none" strokeWidth={1} fill="#fff"
                          d="M39.301,52.967 C39.301,52.967 47.669,43.441 49.719,34.451 C51.769,25.461 48.851,24.109 43.955,23.187 C39.058,22.266 36.086,21.624 36.989,18.451 C37.892,15.278 43.955,12.353 43.955,12.353 C43.955,12.353 40.486,8.792 45.724,5.975 C50.961,3.157 54.255,2.146 58.677,2.005 C63.1,1.863 64.064,4.825 61.297,7.546 C58.53,10.266 55.423,19.727 63.298,24.947 C71.172,30.167 86.118,36.515 90.367,43.387 C94.616,50.259 96.794,53.159 96.974,65.102 C97.153,77.045 94.499,84.775 81.379,84.775 L58.677,84.775 C58.677,84.775 43.438,83.974 43.438,71.296" />
                        <path
                          d="M43.955,76.225 C43.955,76.225 46.737,84.5 60.594,84.5 L83.591,84.5 C83.591,84.5 97.006,84.707 97.006,69.523 C97.006,54.339 96.564,44.167 77.989,33.996 C59.415,23.824 59.415,22.35 59.415,15.274 C59.415,8.198 63.1,5.25 63.1,5.25 C63.1,5.25 61.184,0.827 53.961,2.301 C46.737,3.775 40.988,8.935 43.347,11.736 C45.705,14.537 56.909,19.552 56.024,32.523" />
                        <path
                          d="M42.809,12.71 C42.809,12.71 36.27,16.543 36.418,19.933 C36.565,23.324 48.8,20.818 50.275,27.304 C51.749,33.79 40.897,52.463 40.897,52.463" />
                        <path d="M50.275,27.304 C50.275,27.304 51.386,22.196 61.297,21.58" />
                        <path d="M2,84.5 L98,84.5" />
                        <polygon stroke="none" strokeWidth={1} fill="#FFFFFF" points="24 83 72 83 72 51 24 51" />
                        <path stroke="none" strokeWidth={1} fill="#404b9b"
                          d="M70.75,64 L25.25,64 C24.559,64 24,63.328 24,62.499 C24,61.672 24.559,61 25.25,61 L70.75,61 C71.441,61 72,61.672 72,62.499 C72,63.328 71.441,64 70.75,64"
                          opacity="0.2" />
                        <polygon points="25.5 84.5 70.5 84.5 70.5 60.5 25.5 60.5" />
                        <polygon points="23.5 60.5 73.5 60.5 73.5 52.5 23.5 52.5" />
                        <path stroke="none" strokeWidth={1} fill="#404b9b"
                          d="M54.298,67.679 C52.808,66.189 50.393,66.189 48.904,67.679 L48.435,68.148 L47.966,67.679 C46.476,66.189 44.061,66.189 42.572,67.679 C41.082,69.169 41.082,71.584 42.572,73.073 L43.041,73.542 L47.376,77.877 C47.961,78.462 48.909,78.462 49.494,77.877 L53.829,73.542 L54.298,73.073 C55.788,71.584 55.788,69.169 54.298,67.679" />
                      </svg>
                    </div>
                    <div className="title marginTop35">
                      <h4 className="textDark fontMedium font18 margin0">
                      {intl.formatMessage({ id: 'home.logistics', defaultMessage: '' })}
                         <br /> {intl.formatMessage({ id: 'home.service', defaultMessage: '' })}</h4>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="box border defaultBackgroundColor borderRadiusSmall all-hover">
                <Link to={`${user ? '/order' : '/login'}`}>
                  <div className="text-center paddingTop50 paddingBottom45">
                    <div className="icon paddingBottom15">
                      <svg height={80} viewBox="0 0 90 91" fillRule="evenodd" stroke="#404b9b" strokeWidth={3}
                        strokeLinecap="round" strokeLinejoin="round" fill="none">
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF"
                          d="M68.447,72.014 C68.162,66.358 67.914,60.702 67.858,55.038 C67.027,54.926 66.186,54.849 65.325,54.849 C55.44,54.849 47.347,62.439 46.435,72.089 C53.772,72.079 61.11,72.047 68.447,72.014" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF"
                          d="M69.538,25 L2.462,25 C1.654,25 1,24.328 1,23.5 C1,22.672 1.654,22 2.462,22 L69.538,22 C70.346,22 71,22.672 71,23.5 C71,24.328 70.346,25 69.538,25" />
                        <polygon fill="#FFFFFF" points="70.5 73.5 34.378 73.5 1.5 73.5 1.5 7.5 70.5 7.5" />
                        <polygon stroke="none" strokeWidth={1} fill="#404b9b"
                          points="1.5 21.38 70.325 21.38 70.325 7.586 1.5 7.586" />
                        <path d="M1.5,21.5 L70.5,21.5" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF"
                          d="M56.448,1.5 L56.358,1.5 C54.926,1.5 53.765,2.661 53.765,4.093 L53.765,12.845 C53.765,14.277 54.926,15.438 56.358,15.438 L56.448,15.438 C57.88,15.438 59.041,14.277 59.041,12.845 L59.041,4.093 C59.041,2.661 57.88,1.5 56.448,1.5" />
                        <path
                          d="M56.551,1.5 L56.449,1.5 C54.82,1.5 53.5,2.666 53.5,4.105 L53.5,12.895 C53.5,14.334 54.82,15.5 56.449,15.5 L56.551,15.5 C58.18,15.5 59.5,14.334 59.5,12.895 L59.5,4.105 C59.5,2.666 58.18,1.5 56.551,1.5 Z" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF"
                          d="M35.958,1.5 L35.868,1.5 C34.436,1.5 33.275,2.661 33.275,4.093 L33.275,12.845 C33.275,14.277 34.436,15.438 35.868,15.438 L35.958,15.438 C37.39,15.438 38.551,14.277 38.551,12.845 L38.551,4.093 C38.551,2.661 37.39,1.5 35.958,1.5" />
                        <path
                          d="M36.551,1.5 L36.449,1.5 C34.82,1.5 33.5,2.666 33.5,4.105 L33.5,12.895 C33.5,14.334 34.82,15.5 36.449,15.5 L36.551,15.5 C38.18,15.5 39.5,14.334 39.5,12.895 L39.5,4.105 C39.5,2.666 38.18,1.5 36.551,1.5 Z" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF"
                          d="M15.467,1.5 L15.377,1.5 C13.945,1.5 12.784,2.661 12.784,4.093 L12.784,12.845 C12.784,14.277 13.945,15.438 15.377,15.438 L15.467,15.438 C16.899,15.438 18.06,14.277 18.06,12.845 L18.06,4.093 C18.06,2.661 16.899,1.5 15.467,1.5" />
                        <path
                          d="M15.551,1.5 L15.449,1.5 C13.82,1.5 12.5,2.666 12.5,4.105 L12.5,12.895 C12.5,14.334 13.82,15.5 15.449,15.5 L15.551,15.5 C17.18,15.5 18.5,14.334 18.5,12.895 L18.5,4.105 C18.5,2.666 17.18,1.5 15.551,1.5 Z" />
                        <polygon points="10.5 65.5 61.5 65.5 61.5 28.5 10.5 28.5" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF" d="M27.795,28.439 L27.795,66.047" />
                        <path d="M27.5,28.439 L27.5,65.047" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF" d="M44.03,28.439 L44.03,66.047" />
                        <path d="M44.5,28.439 L44.5,65.047" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF" d="M61.266,40.791 L10.559,40.791" />
                        <path d="M61.266,40.791 L10.559,40.791" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF" d="M61.266,53.419 L10.559,53.419" />
                        <path d="M61.266,53.419 L10.559,53.419" />
                        <path stroke="none" strokeWidth={1} fill="#404b9b"
                          d="M87.858,71.882 C87.858,62.199 80.008,54.349 70.325,54.349 C60.641,54.349 52.791,62.199 52.791,71.882 C52.791,81.565 60.641,89.415 70.325,89.415 C80.008,89.415 87.858,81.565 87.858,71.882" />
                        <path stroke="#0320c6"
                          d="M87.858,71.882 C87.858,62.199 80.008,54.349 70.325,54.349 C60.641,54.349 52.791,62.199 52.791,71.882 C52.791,81.565 60.641,89.415 70.325,89.415 C80.008,89.415 87.858,81.565 87.858,71.882 Z" />
                        <path stroke="none" strokeWidth={1} fill="#404b9b"
                          d="M87.858,71.882 C87.858,62.199 80.008,54.349 70.325,54.349 C60.641,54.349 52.791,62.199 52.791,71.882 C52.791,81.565 60.641,89.415 70.325,89.415 C80.008,89.415 87.858,81.565 87.858,71.882" />
                        <path d="M84.723,57.484 L82.781,59.426" />
                        <path
                          d="M87.858,71.882 C87.858,62.199 80.008,54.349 70.325,54.349 C60.641,54.349 52.791,62.199 52.791,71.882 C52.791,81.565 60.641,89.415 70.325,89.415 C80.008,89.415 87.858,81.565 87.858,71.882 Z" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF"
                          d="M70.5,46.328 C68.291,46.328 66.5,48.225 66.5,50.565 L66.5,54.348 L74.5,54.348 L74.5,50.565 C74.5,48.225 72.709,46.328 70.5,46.328" />
                        <path
                          d="M70.5,46.5 C68.291,46.5 66.5,48.392 66.5,50.726 L66.5,54.5 L74.5,54.5 L74.5,50.726 C74.5,48.392 72.709,46.5 70.5,46.5 Z" />
                        <path stroke="#FFFFFF" d="M70.5,71.882 L70.5,83.563" />
                        <path stroke="none" strokeWidth={1} fill="#FFFFFF"
                          d="M74,71.5 C74,69.566 72.433,68 70.501,68 C68.567,68 67,69.566 67,71.5 C67,73.434 68.567,75 70.501,75 C72.433,75 74,73.434 74,71.5" />
                        <path d="M40.621,69.5 L52.792,69.5" />
                        <path d="M53.946,77.5 L33.968,77.5" />
                        <path d="M55.619,81.5 L44.51,81.5" />
                      </svg>
                    </div>
                    <div className="title marginTop35">
                      <h4 className="textDark fontMedium font18 margin0">
                      {intl.formatMessage({ id: 'home.order', defaultMessage: '' })}
                     <br /> {intl.formatMessage({ id: 'home.give', defaultMessage: '' })}</h4>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={track.visible} onHide={() => {
        setTrack({
          ...track,
          visible: false,
        })
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({ id: 'home.parcel.check', defaultMessage: '' })}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {track.barcodes.length > 0 ? (
            <>
              {(track.barcodes || []).map((barcode) => {
                return (
                  <ListGroup>
                    <ListGroup.Item>{intl.formatMessage({ id: 'home.delivery.history', defaultMessage: '' })} #{barcode}</ListGroup.Item>
                    {(track.hits || []).map((hit: any) => {
                      return (
                        <ListGroup.Item variant="light">
                          {
                            (hit._source || {}).scanDate ?
                              <span>
                                {moment((hit._source || {}).scanDate).format(dateFormat)}
                                <b>{(hit._source || {}).barcode}</b> {((hit._source || {}).user || {}).fname} {((hit._source || {}).shipmentStatus || {}).name} {hit._source?.reason || ''}
                                 {(hit._source || {}).sign ? `( ${(hit._source || {}).sign} )` : ""} {(hit._source || {}).signImage ? <img width="100" height="50" src={`https://mypost.mn/delivery_api/${(hit._source || {}).signImage}`}/> : null}
                              </span>
                              :
                              <span>
                                {moment((hit._source || {}).timestamp).format(dateFormat)}
                                <b>{(hit._source || {}).barcode}</b> {((hit._source || {}).user || {}).fname} {((hit._source || {}).shipmentStatus || {}).name}
                                 {(hit._source || {}).sign ? `( ${(hit._source || {}).sign} )` : ""} {(hit._source || {}).signImage ? <img width="100" height="50" src={`https://mypost.mn/delivery_api/${(hit._source || {}).signImage}`}/> : null}
                              </span>
                          }
                        </ListGroup.Item>
                      )
                    })}

                  </ListGroup>
                )
              })}
            </>
          ) : null}

        </Modal.Body>
      </Modal>
    </main>

  )
}

export default Home