import * as React from 'react'
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import UserContext from "../../../context/UserContext";
import CustomButton from "../../lib/CustomButton";
import fetchApi from "../../../api/fetchApi";
import config from "../../../config";
import {useToasts} from "react-toast-notifications";
import { useIntl } from 'react-intl';
interface State {
  loading: boolean
  verify: boolean
  verifyLoading: boolean
  phone: string
  code: string
}
const initialState: State = {
  loading: false,
  verify: false,
  verifyLoading: false,
  phone: "",
  code: ""
}
const Phone = () => {
  const intl = useIntl()
  const {user,initLoad} = useContext(UserContext);
  const [state, updateState] = useState(initialState);
  const {addToast} = useToasts();
  const getCode = useCallback(async ()=>{
    updateState({...state, loading: true})
    const response = await fetchApi.requestPost(`${config.get('myPostApiUrl')}/api/auth/phone`,state);
    if(response.success){
      addToast(`Баталгаажуулах код ${state.phone} руу илгээлээ`, { appearance: 'success' });
      if(initLoad){
        initLoad()
      }
    }else{
      addToast(response.message, { appearance: 'error' })
    }
    updateState({...state,loading: false})
  },[state]);
  const verify = useCallback(async ()=>{
    updateState({...state,verifyLoading: true})
    const response = await fetchApi.requestPost(`${config.get('myPostApiUrl')}/api/auth/phone/verify`,state);
    if(response.success){
      addToast(intl.formatMessage({id: 'phone.submission.successful', defaultMessage: ''}), { appearance: 'error' })
      if(initLoad){
        initLoad()
      }
    }else{
      addToast(response.message, { appearance: 'error' })
    }
    updateState({...state,verifyLoading: false})
  },[state]);
  const remove = useCallback(async ()=>{
    const response = await fetchApi.requestGet(`${config.get('myPostApiUrl')}/api/auth/phone/remove`);
    if(response.success){
      if(initLoad){
        initLoad()
      }
    }else{
      addToast(response.message, { appearance: 'error' })
    }
    updateState({...state,loading: false})
  },[state]);
  const retry = useCallback(async ()=>{
    const response = await fetchApi.requestGet(`${config.get('myPostApiUrl')}/api/auth/phone/retry`);
    if(response.success){
      addToast(`Баталгаажуулах код ${state.phone} руу дахин илгээлээ`, { appearance: 'success' });
    }else{
      addToast(response.message, { appearance: 'error' })
    }
    updateState({...state,loading: false})
  },[state]);
  const phone = useMemo(()=>{
    return (user.label || []).find((item:any)=>item.key === "phone") || null;
  },[user]);
  useEffect(()=>{
    if(user){
      updateState({...state, phone: user.phone, verify: true})
    }
  },[user]);
  console.log(phone)
  return (
    <div className="bottomTab marginTop20 defaultBackgroundColor borDer borderRadiusSmall padding30">
      <div className="title">
        <h2 className="margin0 padding0 font18 fontMedium">{intl.formatMessage({id: 'phone.verification', defaultMessage: ''})}</h2>
        <p className="marginTop5 marginBottom0 fontLight text-muted">
          {intl.formatMessage({id: 'phone.verification.warning', defaultMessage: ''})}
        </p>
        {/*<p className="margin0 textPrimary">*/}
        {/*  Таны 88118348 дугаарт илгээсэн кодыг оруулна уу*/}
        {/*</p>*/}
      </div>
      <div className="body marginTop20 phone">
        {
          phone && phone.value === "ACTIVE" ? (
              <div className="row">
                <div className="col-xxl-6 col-xl-4 col-lg-6 col-md-6 col-sm-6 marginBottom15">
                  <input type="number" disabled={!!phone} value={state.phone} onChange={(e)=>updateState({...state, phone: e.target.value})} className="form-control fontRegular borderRadiusSmall"/>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 button-container">
                  <CustomButton title={intl.formatMessage({id: 'phone.get.code.again', defaultMessage: ''})} reload={retry}/>
                </div>
              </div>
          ) : (
              <div className="row">
                <div className="col-xxl-6 col-xl-4 col-lg-6 col-md-6 col-sm-6 marginBottom15">
                  <input type="number" disabled={!!phone} value={state.phone} onChange={(e)=>updateState({...state, phone: e.target.value})} className="form-control fontRegular borderRadiusSmall"/>
                </div>
                {
                  phone ? (
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 button-container">
                        <CustomButton title={intl.formatMessage({id: 'phone.get.code.again', defaultMessage: ''})} reload={retry}/>
                        <CustomButton style={{marginLeft: 20}} title={intl.formatMessage({id: 'delete', defaultMessage: ''})} reload={remove}/>
                      </div>
                  ) : (
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <CustomButton loading={state.loading} title={intl.formatMessage({id: 'save', defaultMessage: ''})} reload={getCode}/>
                      </div>
                  )
                }
              </div>
          )
        }
        {
          phone && phone.value === "PENDING" ? (
              <div className="row">
                <div className="col-xxl-6 col-xl-4 col-lg-6 col-md-6 col-sm-6 marginBottom15">
                  <input type="number" value={state.code} onChange={(e)=>updateState({...state, code: e.target.value})} className="form-control fontRegular borderRadiusSmall"/>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <CustomButton loading={state.verifyLoading} title={intl.formatMessage({id: 'phone.verify', defaultMessage: ''})} reload={verify}/>
                </div>
              </div>
          ) : null
        }
      </div>
    </div>
  )
}

export default Phone