import * as React from 'react'
import { Link } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import fetchApi from '../../../api/fetchApi'
import config from '../../../config'
import { useIntl } from 'react-intl'

interface State {
  loading: boolean
  result: any[]
}
const initialState: State = {
  loading: true,
  result: []
}
interface Data {
  show: boolean
  record: any
}
const initialData: Data = {
  show: false,
  record: null
}
const Help = () => {
  const intl = useIntl()
  const [state, updateState] = useState(initialState);
  const [data, updateData] = useState(initialData);
  const fetchContent = useCallback(async ()=>{
    const response = await fetchApi.requestGet(`${config.get("myPostApiUrl")}/api/help/list`);
    updateState({
      loading: false,
      result: response.result || []
    })
  },[])
  useEffect(()=>{
    fetchContent()
  },[])
  return (
    <main>
      <div className="about page">
        <div className="defaultBackgroundColor">
          <div className="feachuredImage paddingTop70 paddingBottom70"
               style={{ backgroundImage: 'url(images/header.jpg)' }}>
            <h1 className="title margin0 padding0 textLight text-center">{intl.formatMessage({id: 'help', defaultMessage: ''})}</h1>
            <div className="breadcrumb marginTop10">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb margin0 font17">
                  <li className="breadcrumb-item textLight">{intl.formatMessage({id: 'home.page', defaultMessage: ''})}</li>
                  <li className="breadcrumb-item active" aria-current="page">{intl.formatMessage({id: 'help', defaultMessage: ''})}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="navigation d-flex justify-content-center paddingTop20 paddingBottom20 font16 borderBottom ">
            <Link className="position-relative" to={'/about'}>{intl.formatMessage({id: 'about.us', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/workplace'}>{intl.formatMessage({id: 'work.place', defaultMessage: ''})}</Link>
            <Link className="position-relative active" to={'/help'}>{intl.formatMessage({id: 'help', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/contact'}>{intl.formatMessage({id: 'contact.us', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/branch'}>{intl.formatMessage({id: 'branch.location', defaultMessage: ''})}</Link>
          </div>
          <div className="container">
            <div className="faq paddingBottom70">
              <div className="marginTop40">
                {
                  (state.result || []).map((item:any, index: number)=>{
                    return (
                      <a
                        key={index}
                        className="border paddingLeft30 paddingRight30 paddingTop15 paddingBottom15 borderRadiuslarge d-inline-flex"
                        onClick={() => updateData({
                          show: true,
                          record: item
                        })}>
                        <div className="d-flex">
                          <div className="icon secondaryBackgroundColor marginRight15"
                               style={{ WebkitMaskImage: `url('images/icons/faq.svg')` }}/>
                          <div>
                            <p className="margin0 padding0 fontRegular font16">{item.title}</p>
                          </div>
                        </div>
                      </a>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={data.show} onHide={() => updateData(initialData)} aria-labelledby="contained-modal-title-vcenter"
             centered>
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">{data.record?.title}</h5>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{__html: data.record?.content || ""}}/>
        </Modal.Body>
      </Modal>
    </main>
  )
}

export default Help