import * as React from 'react'
import {Link} from 'react-router-dom'
import {useCallback, useContext, useState} from "react";
import fetchApi from "../../api/fetchApi";
import config from "../../config";
import {useToasts} from "react-toast-notifications";
import CustomButton from "../lib/CustomButton";
import UserContext from "../../context/UserContext";
import { useIntl } from 'react-intl';

interface State {
    loading: boolean,
    first_name: string
    last_name: string
    email: string
    gender: string
    password: string
    password_confirm: string
}

const initialState: State = {
    loading: false,
    first_name: "",
    last_name: "",
    email: "",
    gender: "MALE",
    password: "",
    password_confirm: "",
}
const Register = () => {
    const intl = useIntl()
    const [state, updateState] = useState(initialState);
    const {addToast} = useToasts();
    const onFinish = useCallback(async (e) => {
        e.preventDefault()
        updateState({...state, loading: true})
        if (state.password !== state.password_confirm) {
            addToast(intl.formatMessage({id: 'new.again.password.incorrect', defaultMessage: ''}), {appearance: 'error'})
            return;
        }
        const response = await fetchApi.requestPost(`${config.get('myPostApiUrl')}/api/auth/register`, state);
        if (response.success) {
            addToast(`Та имэйл баталгаажуулна уу!. Баталгаажуулах имэйл ${state.email} руу илгээлээ`, {appearance: 'success'})
            updateState(initialState)
        } else {
            addToast(response.message, {appearance: 'error'})
            updateState({...state, loading: false})
        }
    }, [state])
    return (
        <main>
            <div className="login defaultBackgroundColor text-center d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="content paddingTop100 paddingBottom100">
                        <div className="top">
                            <div className="middle paddingBottom25">
                                <div className="first paddingBottom25">
                                    <h2 className="margin0 font32 fontBold">{intl.formatMessage({id: 'register', defaultMessage: ''})}</h2>
                                </div>
                                <div className="last">
                                    <form onSubmit={(e)=>onFinish(e)}>
                                        <div className="paddingBottom5">
                                            <input type="text" value={state.last_name} placeholder={intl.formatMessage({id: 'last.name', defaultMessage: ''})}
                                                   onChange={(e) => {
                                                       updateState({...state, last_name: e.target.value})
                                                   }}
                                                   className="form-control fontRegular borderRadiusSmall"/>
                                        </div>
                                        <div className="paddingBottom5">
                                            <input type="text" value={state.first_name} placeholder={intl.formatMessage({id: 'first.name', defaultMessage: ''})}
                                                   onChange={(e) => {
                                                       updateState({...state, first_name: e.target.value})
                                                   }}
                                                   className="form-control fontRegular borderRadiusSmall"/>
                                        </div>
                                        <div className="paddingTop10 paddingBottom10">
                                            <input type="email" value={state.email} placeholder={intl.formatMessage({id: 'email', defaultMessage: ''})}
                                                   onChange={(e) => {
                                                       updateState({...state, email: e.target.value})
                                                   }}
                                                   className="form-control fontRegular borderRadiusSmall"/>
                                        </div>
                                        <div className="paddingTop10 paddingBottom10">
                                            <select placeholder={intl.formatMessage({id: 'gender', defaultMessage: ''})} value={state.gender}
                                                    className="form-control fontRegular borderRadiusSmall"
                                                    onChange={(e) => {
                                                        updateState({...state, gender: e.target.value})
                                                    }}>
                                                <option value="MALE">{intl.formatMessage({id: 'male', defaultMessage: ''})}</option>
                                                <option value="FEMALE">{intl.formatMessage({id: 'female', defaultMessage: ''})}</option>
                                            </select>
                                        </div>
                                        <div className="paddingTop10 paddingBottom10">
                                            <input type="password" value={state.password}
                                                   placeholder={intl.formatMessage({id: 'register.password', defaultMessage: ''})}
                                                   onChange={(e) => {
                                                       updateState({...state, password: e.target.value})
                                                   }}
                                                   className="form-control fontRegular` borderRadiusSmall"/>
                                        </div>
                                        <div className="paddingTop10 paddingBottom10">
                                            <input type="password" value={state.password_confirm} onChange={(e) => {
                                                updateState({...state, password_confirm: e.target.value})
                                            }} placeholder={intl.formatMessage({id: 'register.password.again', defaultMessage: ''})}
                                                   className="form-control fontRegular borderRadiusSmall"/>
                                        </div>
                                        <div className="paddingTop10">
                                            <CustomButton loading={state.loading} title={intl.formatMessage({id: 'register', defaultMessage: ''})} type={"submit"}/>
                                        </div>
                                        <div className="forget paddingTop10">
                                            <Link to={'/login'}
                                                  className="btn fontRegular borderRadiusSmall all-hover paddingTop10 paddingBottom10">
                                                {intl.formatMessage({id: 'back', defaultMessage: ''})}
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Register