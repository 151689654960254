import * as React from 'react'
import {useCallback, useState} from "react";
import CustomButton from "../../lib/CustomButton";
import {useToasts} from "react-toast-notifications";
import fetchApi from "../../../api/fetchApi";
import config from "../../../config";
import { useIntl } from 'react-intl';
interface State {
  loading: boolean
  old_password: string
  password: string
  password_confirm: string
}
const initialState: State = {
  loading: false,
  old_password: "",
  password: "",
  password_confirm: ""
}
const Password = () => {
  const intl = useIntl()
  const [state, updateState] = useState(initialState);
  const {addToast} = useToasts();
  const changePassword = useCallback(async ()=>{
    if(state.password !== state.password_confirm){
      addToast(intl.formatMessage({id: 'new.again.password.incorrect', defaultMessage: ''}), {appearance: 'error'})
      return;
    }
    const response = await fetchApi.requestPost(`${config.get('myPostApiUrl')}/api/auth/password/change`,state);
    if(response.success){
      addToast(intl.formatMessage({id: 'change.password.successful', defaultMessage: ''}), { appearance: 'success' })
      updateState(initialState)
    }else{
      addToast(response.message, { appearance: 'error' })
    }
    updateState({...state,loading: false})
  },[state]);
  return (
    <div className="bottomTab marginTop20 defaultBackgroundColor borDer borderRadiusSmall padding30">
      <div className="title">
        <h2 className="margin0 padding0 font18 fontMedium">{intl.formatMessage({id: 'change.password', defaultMessage: ''})}</h2>
      </div>
      <div className="body marginTop20">
        <form className="row">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div className="marginBottom5 marginLeft5">{intl.formatMessage({id: 'old.password', defaultMessage: ''})}</div>
            <input type="password" value={state.old_password} onChange={(e)=>updateState({...state, old_password: e.target.value})} className="form-control fontRegular borderRadiusSmall"/>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div className="marginBottom5 marginLeft5">{intl.formatMessage({id: 'new.password', defaultMessage: ''})}</div>
            <input type="password" value={state.password} onChange={(e)=>updateState({...state, password: e.target.value})} className="form-control fontRegular borderRadiusSmall"/>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div className="marginBottom5 marginLeft5">{intl.formatMessage({id: 'new.again.password', defaultMessage: ''})}</div>
            <input type="password" value={state.password_confirm} onChange={(e)=>updateState({...state, password_confirm: e.target.value})} className="form-control fontRegular borderRadiusSmall"/>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 marginTop25">
            <CustomButton title={intl.formatMessage({id: 'change.password', defaultMessage: ''})} reload={changePassword} loading={state.loading}/>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Password