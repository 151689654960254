import * as React from 'react'

const Email = () => {
  return (
    <div className="bottomTab marginTop20 defaultBackgroundColor borDer borderRadiusSmall padding30">
      <div className="title">
        <h2 className="margin0 padding0 font18 fontMedium">И-мэйл хаяг баталгаажсан</h2>
        {/*<p className="marginTop5 marginBottom0 fontLight text-muted">*/}
        {/*  Хэрэв та өөр мэйл хаяг бүртгүүлэх бол И-мэйл хаяг солих товчыг дарна уу*/}
        {/*</p>*/}
        {/*<p className="margin0 textPrimary fontLight">*/}
        {/*  Таны naranmunkhuu0603@gmail.com хаягт илгээсэн кодыг оруулна уу*/}
        {/*</p>*/}
      </div>
      {/*<div className="body marginTop20 phone">*/}
      {/*  <form className="row">*/}
      {/*    <div className="col-xxl-6 col-xl-4 col-lg-6 col-md-6 col-sm-6 marginBottom15">*/}
      {/*      <input type="number" className="form-control fontRegular borderRadiusSmall"/>*/}
      {/*    </div>*/}
      {/*    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">*/}
      {/*      <button type="submit"*/}
      {/*              className="all-hover btn primaryBackgroundColor textLight fontMedium paddingTop10 paddingBottom10 paddingLeft30 paddingRight30">*/}
      {/*        Баталгаажуулах*/}
      {/*      </button>*/}
      {/*    </div>*/}
      {/*  </form>*/}
      {/*</div>*/}
    </div>
  )
}

export default Email