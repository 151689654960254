import React, { FC, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import UserContext from '../../context/UserContext'

interface Props {
  style?: object
}

const Footer = ({ style }: Props) => {
  const intl = useIntl()
  const {user} = useContext(UserContext);
  return (
    <footer className="paddingTop30 paddingBottom30 defaultBackgroundColor borderTop">
      <div className="top">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between paddingBottom30">
            <div className="logo">
              <Link to={'/'}>
                <img src="/images/logo.jpg" width={225} />
              </Link>
            </div>
            <div className="app">
              <span className="font16 fontMedium marginRight15 text">{intl.formatMessage({id: 'customers.app', defaultMessage: ''})}</span>
              <Link to={''} style={{marginRight: 5,}}>
                <img width={115} src="/images/apple.png" />
              </Link>
              <Link to={''}>
                <img width={120} src="/images/google.png" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom borderTop paddingTop15">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="left">
              <p className="copyright textSecondary margin0 font16 fontRegular">© 2021 {intl.formatMessage({ id: 'app.title', defaultMessage: '' })}</p>
            </div>
            <div className="right">
              <div className="menu">
                <ul className="d-flex margin0 padding0">
                  <li className="paddingLeft15 paddingRight15">
                    <Link to={'/about'} className="textSecondary font16 all-hover fontRegular">{intl.formatMessage({id: 'about.us', defaultMessage: ''})}</Link>
                  </li>
                  <li className="paddingLeft15 paddingRight15">
                    <Link to={user ? "/order" : "/login"} className="textSecondary font16 all-hover fontRegular">{intl.formatMessage({id: 'calculator', defaultMessage: ''})}</Link>
                  </li>
                  <li className="paddingLeft15 paddingRight15">
                    <Link to={'/workplace'} className="textSecondary font16 all-hover fontRegular">{intl.formatMessage({id: 'workplace', defaultMessage: ''})}</Link>
                  </li>
                  <li>
                    <Link to={'/contact'} className="textSecondary font16 all-hover fontRegular">{intl.formatMessage({id: 'contact.us', defaultMessage: ''})}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

  )
}

export default Footer