import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Nav, Tab, TabContainer } from 'react-bootstrap'
import { useCallback, useState } from 'react'
import UserMenu from './UserMenu'
import { useEffect } from "react";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
import fetchApi from '../../api/fetchApi'
import config from '../../config'
import moment from "moment";
import { formatMoney } from '../../utils/utils'
import { useIntl } from 'react-intl'
const queryString = require('query-string');

interface State {
  loading: boolean
  sender: any
  receiver: any
  list: any[]
  max: number
  page: number
  total: number
}
const initialState: State = {
  loading: true,
  sender: null,
  receiver: null,
  list: [],
  max: 20,
  page: 1,
  total: 0
}
interface Filter {
  page: number
  max: number
  svc: string
  status: string
  paymentStatus: string
}
const initialFilter: Filter = {
  page: 1,
  max: 20,
  svc: "WEB",
  status: "PENDING",
  paymentStatus: "PAID"
}
const UserInfo = () => {
  const intl = useIntl();
  const { user } = useContext(UserContext);
  const [state, updateState] = useState(initialState);
  const [navTab, setNavTab] = useState('activity')
  const [filter, updateFilter] = useState(initialFilter)
  const navigate = useNavigate();
  const fetchOrder = useCallback(async () => {
    if (user) {
      if(filter.status === "DELIVERING"){
        const payload = {
          frontMemberId: user.id
        }
        const response = await fetchApi.requestGet(`${config.get('deliveryApiUrl')}/api/delivery/order/list?${queryString.stringify(payload)}`);
        if (response.success) {
          const result = response.result?.result || {}
          updateState({
            ...state,
            list: result.list,
            max: result.max,
            page: result.page,
            total: result.total
          })
        } else {
          updateState({
            ...state,
            list: [],
            max: 20,
            page: 1,
            total: 0
          })
        }
      }else{
        const payload = {
          ...filter,
          frontMemberId: user.id
        }
        const response = await fetchApi.requestGet(`${config.get('orderApiUrl')}/api/front/order/list?${queryString.stringify(payload)}`);
        if (response.success) {
          const result = response.result?.result || {}
          updateState({
            ...state,
            list: result.list,
            max: result.max,
            page: result.page,
            total: result.total
          })
        } else {
          updateState({
            ...state,
            list: [],
            max: 20,
            page: 1,
            total: 0
          })
        }
      }
    }
  }, [navTab]);
  useEffect(() => {
    fetchOrder()
  }, [filter])
  useEffect(() => {
    if (!user) {
      navigate('/login')
    }
  }, []);
  const changeTabKey = useCallback((e) => {
    if (e === "pending") {
      updateFilter({
        ...filter,
        status: "PENDING",
        paymentStatus: "UNPAID"
      })
      setNavTab(e)
    } else if (e === "canceled") {
      updateFilter({
        ...filter,
        status: "CANCELLED",
        paymentStatus: ""
      })
      setNavTab(e)
    } else if (e === "success") {
      updateFilter({
        ...filter,
        status: "DELIVERED",
        paymentStatus: ""
      })
      setNavTab(e)
    } else if(e==="delivering"){
      updateFilter({
        ...filter,
        status: "DELIVERING",
        paymentStatus: ""
      })
      setNavTab(e)
    } else {
      updateFilter({
        ...filter,
        status: "PENDING",
        paymentStatus: "PAID"
      })
      setNavTab(e)
    }
  }, [navTab])
  return (
    <main>
      <div className="account lightBackgroundColor paddingTop40 paddingBottom70">
        <div className="container">
          <div className="row">
            <UserMenu />
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12">
              <div className="defaultBackgroundColor borDer borderRadiusSmall padding30">
                <div className="body">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="paddingRight15">
                        <div className="d-flex align-items-center justify-content-between">
                          <h3 className="margin0 padding0 font18 fontBold text-uppercase">
                            {intl.formatMessage({ id: 'ordering.address', defaultMessage: '' })}
                          </h3>
                        </div>
                        <div className="marginTop10">
                          {
                            !state.sender ? (
                              <div>
                                {intl.formatMessage({ id: 'data.empty', defaultMessage: '' })}
                              </div>
                            ) : (
                              <ul className="padding0 margin0 list">
                                <li className="borderBottom paddingTop10 paddingBottom10 d-inline-block">
                                  <div className="float-start text-muted">
                                    Илээгчийн мэдээлэл
                                  </div>
                                  <div className="float-end fontMedium">
                                    Наран Мөнх-Эрдэнэ
                                  </div>
                                </li>
                                <li className="borderBottom paddingTop10 paddingBottom10 d-inline-block">
                                  <div className="float-start text-muted">
                                    Утасны дугаар
                                  </div>
                                  <div className="float-end fontMedium">
                                    88118348, 90113323
                                  </div>
                                </li>
                                <li className="borderBottom paddingTop10 paddingBottom10 d-inline-block">
                                  <div className="float-start text-muted">
                                    Цахим шуудан
                                  </div>
                                  <div className="float-end fontMedium">
                                    naranmunkhuu@gmail.com
                                  </div>
                                </li>
                                <li className="paddingTop10 address">
                                  <div className="text-muted marginBottom5">
                                    Хаяг дэлгэрэнгүй
                                  </div>
                                  <p>
                                    Улаанбаатар / Баянгол дүүрэг / 12-р хороо / Стелла төв / 4-н давхарт 412 тоот
                                  </p>
                                </li>
                              </ul>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="paddingLeft15">
                        <div className="d-flex align-items-center justify-content-between">
                          <h3 className="margin0 padding0 font18 fontBold text-uppercase">
                            {intl.formatMessage({ id: 'shipping.address', defaultMessage: '' })}
                          </h3>
                        </div>
                        <div className="marginTop10">
                          {
                            !state.receiver ? (
                              <div >
                                {intl.formatMessage({ id: 'data.empty', defaultMessage: '' })}
                              </div>
                            ) : (
                              <ul className="padding0 margin0 list">
                                <li className="borderBottom paddingTop10 paddingBottom10 d-inline-block">
                                  <div className="float-start text-muted">
                                    Хүлээн авагч
                                  </div>
                                  <div className="float-end fontMedium">
                                    Даариймаа Баттөмөр
                                  </div>
                                </li>
                                <li className="borderBottom paddingTop10 paddingBottom10 d-inline-block">
                                  <div className="float-start text-muted">
                                    Утасны дугаар
                                  </div>
                                  <div className="float-end fontMedium">
                                    88118348, 90113323
                                  </div>
                                </li>
                                <li className="borderBottom paddingTop10 paddingBottom10 d-inline-block">
                                  <div className="float-start text-muted">
                                    Цахим шуудан
                                  </div>
                                  <div className="float-end fontMedium">
                                    batxac@gmail.com
                                  </div>
                                </li>
                                <li className="paddingTop10 address">
                                  <div className="text-muted marginBottom5">
                                    Хаяг дэлгэрэнгүй
                                  </div>
                                  <p>
                                    Дархан хот / Нутгийн удирдлагын ордон / Бурхантын хөндийн гудамж, / Дархан 45059, Монгол
                                    Улс
                                  </p>
                                </li>
                              </ul>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="defaultBackgroundColor borDer borderRadiusSmall padding30 marginTop20">
                <div>
                  <Tab.Container activeKey={navTab}>
                    <Nav activeKey={navTab} onSelect={e => changeTabKey(e)} className="nav nav-tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="pending">{intl.formatMessage({ id: 'payment.pending', defaultMessage: '' })}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="activity">{intl.formatMessage({ id: 'payment.active', defaultMessage: '' })}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="delivering">{intl.formatMessage({ id: 'payment.delivering', defaultMessage: '' })}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="canceled">{intl.formatMessage({ id: 'payment.canceled', defaultMessage: '' })}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="success">{intl.formatMessage({ id: 'payment.delivered', defaultMessage: '' })}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Tab.Container>
                </div>
                <div>
                  <TabContainer activeKey={navTab}>
                    <Tab.Content>
                      <Tab.Pane eventKey="pending">
                        {
                          (state.list || []).map((item: any, index: number) => {
                            return (
                              <div key={index} className="paddingTop30 paddingBottom15 items">
                                <div className="row">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                        {intl.formatMessage({ id: 'status', defaultMessage: '' })}
                                        <span style={{ color: '#428bca' }}
                                          className="d-block fontMedium font16 textSecondary">{item.paymentStatus}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'date', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{moment(item.orderDate).format("YYYY/MM/DD HH:mm")}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'order.number', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{item.id}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title" style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center"
                                    }}>
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'total.price', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{formatMoney(item.totalAmount)}</span>
                                      </h2>
                                      <button style={{ marginLeft: 10 }} className="all-hover btn primaryBackgroundColor textLight fontMedium padding5">{intl.formatMessage({id: 'pay', defaultMessage: ''})}</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </Tab.Pane>
                      <Tab.Pane eventKey="activity">
                        {
                          (state.list || []).map((item: any, index: number) => {
                            return (
                              <div key={index} className="paddingTop30 paddingBottom15 items">
                                <div className="row">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'status', defaultMessage: ''})}
                                        <span style={{ color: '#428bca' }}
                                          className="d-block fontMedium font16 textSecondary">{item.paymentStatus}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'date', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{moment(item.orderDate).format("YYYY/MM/DD HH:mm")}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'order.number', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{item.id}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'total.price', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{formatMoney(item.totalAmount)}</span>
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </Tab.Pane>
                      <Tab.Pane eventKey="delivering">
                        {
                          (state.list || []).map((item: any, index: number) => {
                            return (
                              <div key={index} className="paddingTop30 paddingBottom15 items">
                                <div className="row">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                        {intl.formatMessage({ id: 'status', defaultMessage: '' })}
                                        <span style={{ color: '#428bca' }}
                                              className="d-block fontMedium font16 textSecondary">{intl.formatMessage({ id: 'payment.delivering', defaultMessage: '' })}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                        {intl.formatMessage({id: 'date', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{moment(item.orderDate).format("YYYY/MM/DD HH:mm")}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                        {intl.formatMessage({id: 'order.number', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{item.id}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title" style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center"
                                    }}>
                                      <h2 className="fontRegular font14 text-muted">
                                        {intl.formatMessage({id: 'order.barcode', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{item.barcode}</span>
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </Tab.Pane>
                      <Tab.Pane eventKey="canceled">
                        {
                          (state.list || []).map((item: any, index: number) => {
                            return (
                              <div key={index} className="paddingTop30 paddingBottom15 items">
                                <div className="row">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'status', defaultMessage: ''})}
                                        <span style={{ color: '#428bca' }}
                                          className="d-block fontMedium font16 textSecondary">{item.paymentStatus}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'date', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{moment(item.orderDate).format("YYYY/MM/DD HH:mm")}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'order.number', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{item.id}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'total.price', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{formatMoney(item.totalAmount)}</span>
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </Tab.Pane>
                      <Tab.Pane eventKey="success">
                        {
                          (state.list || []).map((item: any, index: number) => {
                            return (
                              <div key={index} className="paddingTop30 paddingBottom15 items">
                                <div className="row">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'status', defaultMessage: ''})}
                                        <span style={{ color: '#428bca' }}
                                          className="d-block fontMedium font16 textSecondary">{item.paymentStatus}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'date', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{moment(item.orderDate).format("YYYY/MM/DD HH:mm")}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'order.number', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{item.id}</span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="title">
                                      <h2 className="fontRegular font14 text-muted">
                                      {intl.formatMessage({id: 'total.price', defaultMessage: ''})}
                                        <span className="d-block fontMedium font16 textSecondary">{formatMoney(item.totalAmount)}</span>
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </Tab.Pane>
                    </Tab.Content>
                  </TabContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
export default UserInfo