import * as React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useCallback } from 'react'
import CustomButton from '../../lib/CustomButton'
import fetchApi from '../../../api/fetchApi'
import config from '../../../config'
import { useToasts } from 'react-toast-notifications'
import { useIntl } from 'react-intl'
// import * as moment from "moment";

type gender = 'Эрэгтэй' | 'Эмэгтэй' | ''

interface Profile {
  id: number,
  surname: string,
  name: string,
  familyName: string,
  birthDate: string,
  birthLocation: string,
  gender: gender,
  nationality: string,
  socialGroup: string,
  address: string,
  phone: string,
  mail: string,
  married: boolean,
  diploma: any,
  portrait: any,
  citizenFront: any,
  citizenBack: any,
}

interface FamilyMember {
  id: number,
  relation: string,
  fullName: string,
  birthDate: string,
  profession: string,
  appointment: string,
  position: string,
  phone: string,
}

interface School {
  id: number,
  name: string,
  startDate: string,
  endDate: string,
  occupation: string,
  certificate: string,
  GPA: string
}

type ability = 'Сайн' | 'Дунд' | 'Муу' | ''

const ExamValues = ['IELTS', 'TOEFL', 'TOPIK', 'JLPT']
type examination = 'IELTS' | 'TOEFL' | 'TOPIK' | 'JLPT' | ''

interface Language {
  id: number,
  name: string,
  understand: ability,
  speak: ability,
  read: ability,
  write: ability,
  translate: ability,
  exam: examination,
  score: number,
}

type skills =
  'Ms Access'
  | 'Ms Excel'
  | 'Ms Word'
  | 'Ms Powerpoint'
  | 'Adobe InDesign'
  | 'Adobe Photoshop'
  | 'Бусад'
  | ''

interface Program {
  id: number,
  name: skills,
  skill: ability,
}

interface Equipment {
  id: number,
  name: string,
  skill: ability,
}

interface Experience {
  id: number,
  organizationName: string,
  direction: string,
  position: string,
  startDate: string,
  endDate: string,
  salary: string,
  role: string,
  achievements: string,
  reason: string,
  name: string,
  appoint: string,
  phone: string,
}

type loanType = 'Цалингийн зээл' | 'Лизинг' | 'Орон сууцны зээл' | 'Малчны зээл' | 'Тэтгэвэрийн зээл' | 'Зээлийн кард' |
  'Төслийн зээл' | 'Хөрөнгө оруулалтын зээл' | 'Эргэлтийн хөрөнгийн зээл' | ''

interface OtherInfo {
  id: number,
  interest: string,
  purpose: string,
  salary: {
    min: number,
    max: number,
  },
  loan: boolean,
  loanType: string,
  additionalInfo: string,
}

interface Brief {
  citizenID: string,
  insuranceID: string,
  healthID: string,
  jobTitle: string,
}

interface CV {
  loading: boolean
  profile: Profile,
  brief: Brief,
  family: FamilyMember[],
  education: {
    schools: School[],
    training: School[]
  },
  skills: {
    languages: Language[],
    programs: Program[],
    equipments: Equipment[]
  },
  exp: Experience[],
  other: OtherInfo,
}

interface Vacancy {
  id: number,
  department: string,
  title: string,
  total: number,
  status: string,
  start_date: string,
  end_date: string
}

const initialCV: CV = {
  loading: false,
  brief: {
    citizenID: '',
    insuranceID: '',
    healthID: '',
    jobTitle: '',
  },
  profile: {
    id: 0,
    surname: '',
    name: '',
    familyName: '',
    birthDate: '',
    birthLocation: '',
    gender: '',
    nationality: '',
    socialGroup: '',
    address: '',
    phone: '',
    mail: '',
    married: false,
    diploma: null,
    portrait: null,
    citizenFront: null,
    citizenBack: null,
  },
  family: [{
    id: 0,
    relation: '',
    fullName: '',
    birthDate: '',
    profession: '',
    appointment: '',
    position: '',
    phone: '',
  }],
  education: {
    schools: [{
      id: 0,
      name: '',
      startDate: '',
      endDate: '',
      occupation: '',
      certificate: '',
      GPA: '',
    }],
    training: [{
      id: 0,
      name: '',
      startDate: '',
      endDate: '',
      occupation: '',
      certificate: '',
      GPA: '',
    }],
  },
  skills: {
    languages: [{
      id: 0,
      name: '',
      understand: 'Сайн',
      speak: 'Сайн',
      read: 'Сайн',
      write: 'Сайн',
      translate: 'Сайн',
      exam: 'IELTS',
      score: 100,
    }],
    programs: [{ id: 0, name: 'Ms Access', skill: 'Сайн' }, { id: 1, name: 'Ms Excel', skill: 'Сайн' },
    { id: 2, name: 'Ms Word', skill: 'Сайн' }, { id: 3, name: 'Ms Powerpoint', skill: 'Сайн' },
    { id: 4, name: 'Adobe InDesign', skill: 'Сайн' }, { id: 5, name: 'Adobe Photoshop', skill: 'Сайн' },
    { id: 6, name: 'Бусад', skill: 'Сайн' }],
    equipments: [{ id: 0, name: 'Принтер', skill: 'Сайн' }, { id: 1, name: 'Хувилагч', skill: 'Сайн' },
    { id: 2, name: 'Скайнер', skill: 'Сайн' }, { id: 3, name: 'Факс', skill: 'Сайн' },
    { id: 4, name: 'Камер', skill: 'Сайн' }, { id: 5, name: 'Дотоод сүлжээ ашиглах', skill: 'Сайн' },
    { id: 6, name: 'Бусад', skill: 'Сайн' }],
  },
  exp: [{
    id: 0,
    organizationName: '',
    direction: '',
    position: '',
    startDate: '',
    endDate: '',
    salary: '',
    role: '',
    achievements: '',
    reason: '',
    name: '',
    appoint: '',
    phone: '',
  }],
  other: {
    id: 0,
    interest: '',
    purpose: '',
    salary: {
      min: 420000,
      max: 4200000,
    },
    loan: false,
    loanType: '',
    additionalInfo: '',
  },
}
const Anket = () => {
  const intl = useIntl();
  const { addToast } = useToasts();
  const [profile, changeProfile] = useState(initialCV)
  const [current, changeVacancies] = useState<Vacancy[]>([])

  const fetchCurrent = useCallback(async () => {
    const response = await fetchApi.requestGet(`${config.get('myPostApiUrl')}/api/work/list/all`)
    console.log(response)
    if (response.success) {
      changeVacancies(response.result)
    } else {
      changeVacancies([])
    }
  }, [current])

  useEffect(() => {
    fetchCurrent()
  }, [])

  const addFamily = useCallback(() => {
    const family = profile.family
    family.push({
      id: 0,
      relation: '',
      fullName: '',
      birthDate: '',
      profession: '',
      appointment: '',
      position: '',
      phone: '',
    })
    changeProfile({
      ...profile,
      family: family,
    })
  }, [profile])
  const addSchools = useCallback(() => {
    const schools = profile.education.schools
    schools.push({
      id: 0,
      name: '',
      startDate: '',
      endDate: '',
      occupation: '',
      certificate: '',
      GPA: '',
    })
    changeProfile({
      ...profile,
      education: {
        ...profile.education,
        schools,
      },
    })
  }, [profile])
  const AddTraining = useCallback(() => {
    const training = profile.education.training
    training.push({
      id: 0,
      name: '',
      startDate: '',
      endDate: '',
      occupation: '',
      certificate: '',
      GPA: '',
    })
    changeProfile({
      ...profile,
      education: {
        ...profile.education,
        training,
      },
    })
  }, [profile])
  const addLanguages = useCallback(() => {
    const languages = profile.skills.languages
    languages.push({
      id: 0,
      name: '',
      understand: 'Сайн',
      speak: 'Сайн',
      read: 'Сайн',
      write: 'Сайн',
      translate: 'Сайн',
      exam: 'IELTS',
      score: 100,
    })
    changeProfile({
      ...profile,
      skills: {
        ...profile.skills,
        languages,
      },
    })
  }, [profile])
  const addExp = useCallback(() => {
    const exp = profile.exp
    exp.push({
      id: 0,
      organizationName: '',
      direction: '',
      position: '',
      startDate: '',
      endDate: '',
      salary: '',
      role: '',
      achievements: '',
      reason: '',
      name: '',
      appoint: '',
      phone: '',
    })
    changeProfile({
      ...profile,
      exp,
    })
  }, [profile])
  const itemFamilyChange = useCallback(async (value, name, index) => {
    const items: FamilyMember[] = (profile.family || []).map((item: FamilyMember, ind: number) => {
      if (ind === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    changeProfile({
      ...profile,
      family: items,
    })
  }, [profile])

  const itemSchoolChange = useCallback(async (value, name, index) => {
    const items: School[] = (profile.education.schools || []).map((item: School, ind: number) => {
      if (ind === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    changeProfile({
      ...profile,
      education: {
        ...profile.education,
        schools: items,
      },
    })
  }, [profile])

  const itemTrainingChange = useCallback(async (value, name, index) => {
    const items: School[] = (profile.education.training || []).map((item: School, ind: number) => {
      if (ind === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    changeProfile({
      ...profile,
      education: {
        ...profile.education,
        training: items,
      },
    })
  }, [profile])

  const itemLanguageChange = useCallback(async (value, name, index) => {
    const items: Language[] = (profile.skills.languages || []).map((item: Language, ind: number) => {
      if (ind === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    changeProfile({
      ...profile,
      skills: {
        ...profile.skills,
        languages: items,
      },
    })
  }, [profile])

  const itemProgramChange = useCallback(async (value, name, index) => {
    const items: Program[] = (profile.skills.programs || []).map((item: Program, ind: number) => {
      if (ind === index) {
        return {
          ...item,
          skill: value,
        }
      } else {
        return item
      }
    })
    changeProfile({
      ...profile,
      skills: {
        ...profile.skills,
        programs: items,
      },
    })
  }, [profile])

  const itemEquipChange = useCallback(async (value, name, index) => {
    const items: Equipment[] = (profile.skills.equipments || []).map((item: Equipment, ind: number) => {
      if (ind === index) {
        return {
          ...item,
          skill: value,
        }
      } else {
        return item
      }
    })
    changeProfile({
      ...profile,
      skills: {
        ...profile.skills,
        equipments: items,
      },
    })
  }, [profile])

  const itemExpChange = useCallback(async (value, name, index) => {
    const items: Experience[] = (profile.exp || []).map((item: Experience, ind: number) => {
      if (ind === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    changeProfile({
      ...profile,
      exp: items,
    })
  }, [profile])
  const onFinish = useCallback(async (e) => {
    e.preventDefault();
    changeProfile({ ...profile, loading: true })
    const response = await fetchApi.requestPost(`${config.get('myPostApiUrl')}/api/anket/create`, profile)
    if (response.success) {
      changeProfile(initialCV);
      addToast(intl.formatMessage({ id: 'received.application', defaultMessage: '' }), { appearance: 'success' })
    } else {
      changeProfile({ ...profile, loading: false })
      addToast(intl.formatMessage({ id: 'error.application', defaultMessage: '' }), { appearance: 'error' })
    }
  }, [profile])
  const onImageUpload = useCallback((blobInfo, name) => {
    console.log(blobInfo)
    var xhr: any, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', `${config.get('myPostApiUrl')}/api/front/upload`);

    xhr.onload = function () {
      var json;
      json = JSON.parse(xhr.responseText);
      if (json.code === 0) {
        changeProfile({
          ...profile,
          profile: {
            ...profile.profile,
            [name]: json.result
          }
        })
      } else {
        addToast(".png, .jpeg, .jpg зураг оруулна уу", { appearance: 'error' })
        console.log(json)
      }
    };

    formData = new FormData();
    if (localStorage.getItem('accessToken')) {
      xhr.setRequestHeader("accesstoken", localStorage.getItem('accessToken'))
    }
    formData.append('image', blobInfo.target.files[0]);
    xhr.send(formData);
  }, [profile])
  return (
    <main>
      <div className="about anket">
        <div className="defaultBackgroundColor">
          <div className="feachuredImage paddingTop70 paddingBottom70"
            style={{ backgroundImage: 'url(images/header.jpg)' }}>
            <h1 className="title margin0 padding0 textLight text-center">{intl.formatMessage({ id: 'workplace.anket.fill', defaultMessage: '' })}</h1>
            <div className="breadcrumb marginTop10">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb margin0 font17">
                  <li className="breadcrumb-item textLight">{intl.formatMessage({ id: 'home.page', defaultMessage: '' })}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {intl.formatMessage({ id: 'workplace', defaultMessage: '' })}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div
            className="navigation d-flex justify-content-center paddingTop20 paddingBottom20 font16 borderBottom ">
            <Link to={'/about'} className="position-relative">{intl.formatMessage({ id: 'about.us', defaultMessage: '' })}</Link>
            <Link to={'/workplace'} className="position-relative active">{intl.formatMessage({ id: 'workplace', defaultMessage: '' })}</Link>
            <Link to={'/help'} className="position-relative">{intl.formatMessage({ id: 'help.center', defaultMessage: '' })}</Link>
            <Link to={'/contact'} className="position-relative">{intl.formatMessage({ id: 'contact.us', defaultMessage: '' })}</Link>
            <Link to={'/branch'} className="position-relative">{intl.formatMessage({ id: 'branch.location', defaultMessage: '' })}</Link>
          </div>
          <div className="container">
            <div className="row">
              <div className="container">
                <div className="paddingTop50 paddingBottom50">
                  <div className="text-start">
                    <div className="fontMedium font18 marginBottom15"
                      style={{ color: 'red' }}>{intl.formatMessage({ id: 'anket.caution', defaultMessage: '' })}
                    </div>
                    <p className="marginBottom5">1. {intl.formatMessage({ id: 'anket.caution1', defaultMessage: '' })}</p>
                    <p className="marginBottom5">2. {intl.formatMessage({ id: 'anket.caution2', defaultMessage: '' })}</p>
                    <p className="marginBottom5">3. {intl.formatMessage({ id: 'anket.caution3', defaultMessage: '' })}</p>
                    <p className="marginBottom5">4. {intl.formatMessage({ id: 'anket.caution4', defaultMessage: '' })}</p>
                    <p className="margin0">5. {intl.formatMessage({ id: 'anket.caution5', defaultMessage: '' })} </p>
                  </div>
                </div>
                <form onSubmit={(e) => onFinish(e)}>
                  <div className="title marginBottom20 alignCenter marginBottom50">
                    <h3 className="textDark margin0 font20 fontBold marginBottom20">{intl.formatMessage({ id: 'app.title', defaultMessage: '' })}</h3>
                    <h3 className="textPrimary margin0 font20 fontBold upperCase">{intl.formatMessage({ id: 'anket.desc', defaultMessage: '' })}</h3>
                  </div>
                  <div className="paddingBottom50">
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'anket.work.name', defaultMessage: '' })}
                          </div>
                          <div className='font14 textSecondary marginBottom10'>
                            <select defaultValue={profile.brief.jobTitle}
                              className="form-select"
                              required={true}
                              aria-label="Default select example"
                              onChange={(e) => changeProfile({
                                ...profile,
                                brief: { ...profile.brief, jobTitle: e.target.value },
                              })}>
                              <option value="">--</option>
                              {
                                current && current.map((item: Vacancy) => {
                                  return <option key={item.id}
                                    value={item.id}>{item.title}</option>
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'registration.number.mgl', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.brief.citizenID} className="form-control fontLight" required={true}
                            onChange={(e) => changeProfile({
                              ...profile,
                              brief: { ...profile.brief, citizenID: e.target.value },
                            })} />
                        </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'labor.insurance.number', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.brief.insuranceID} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              brief: { ...profile.brief, insuranceID: e.target.value },
                            })} />
                        </div>
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'medical.insurance.number', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.brief.healthID} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              brief: { ...profile.brief, healthID: e.target.value },
                            })} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item paddingBottom50">
                    <div className="title marginBottom20">
                      <h3 className="textDark margin0 font20 fontBold upperCase">1. {intl.formatMessage({ id: 'individual.information', defaultMessage: '' })}</h3>
                    </div>
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'last.name', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.profile.surname} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: {
                                ...profile.profile,
                                surname: e.target.value,
                              },
                            })} />
                        </div>
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'family.name', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.profile.familyName} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: {
                                ...profile.profile,
                                familyName: e.target.value,
                              },
                            })} />
                        </div>
                        <div className='marginBottom10 gender'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'gender', defaultMessage: '' })}</div>
                          <select className="form-select padding9"
                            required={true}
                            aria-label="Default select example"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: {
                                ...profile.profile,
                                gender: e.target.value === '1' ? 'Эрэгтэй' : 'Эмэгтэй',
                              },
                            })}>
                            <option selected disabled>{intl.formatMessage({ id: 'please.select', defaultMessage: '' })}</option>
                            <option value="1">{intl.formatMessage({ id: 'male', defaultMessage: '' })}</option>
                            <option value="2">{intl.formatMessage({ id: 'female', defaultMessage: '' })}</option>
                          </select>
                        </div>
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'born.city', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.profile.birthLocation} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: {
                                ...profile.profile,
                                birthLocation: e.target.value,
                              },
                            })} />
                        </div>
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'living.address', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.profile.address} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: {
                                ...profile.profile,
                                address: e.target.value,
                              },
                            })} />
                        </div>
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'email', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.profile.mail} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: { ...profile.profile, mail: e.target.value },
                            })} />
                        </div>

                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'first.name', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.profile.name} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: { ...profile.profile, name: e.target.value },
                            })} />
                        </div>
                        <div className='marginBottom10 datePicker'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'born.date', defaultMessage: '' })}
                          </div>
                          <input type="date" value={profile.profile.birthDate} required={true} name="birthday"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: {
                                ...profile.profile,
                                birthDate: e.target.value,
                              },
                            })} />
                        </div>
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'nationality', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.profile.nationality} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: {
                                ...profile.profile,
                                nationality: e.target.value,
                              },
                            })} />
                        </div>

                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'social.group', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.profile.socialGroup} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: {
                                ...profile.profile,
                                socialGroup: e.target.value,
                              },
                            })} />
                        </div>
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'phone', defaultMessage: '' })}
                          </div>
                          <input type="text" value={profile.profile.phone} required={true} className="form-control fontLight"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: { ...profile.profile, phone: e.target.value },
                            })} />
                        </div>
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'married', defaultMessage: '' })}
                          </div>
                          <select className="form-select padding9"
                            aria-label="Default select example"
                            onChange={(e) => changeProfile({
                              ...profile,
                              profile: {
                                ...profile.profile,
                                married: e.target.value === '1',
                              },
                            })}>
                            <option selected>{intl.formatMessage({ id: 'please.select', defaultMessage: '' })}</option>
                            <option value="1">{intl.formatMessage({ id: 'yes', defaultMessage: '' })}</option>
                            <option value="2">{intl.formatMessage({ id: 'no', defaultMessage: '' })}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'diploma.copy', defaultMessage: '' })}
                          </div>
                          <div className="mb-3">
                            <input type="file" className="form-control"
                              aria-label="file example"
                              onChange={(e) => onImageUpload(e, "diploma")} />
                            {
                              profile.profile.diploma ? (
                                <img src={profile.profile.diploma} />
                              ) : null
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'portrait', defaultMessage: '' })}
                          </div>
                          <div className="mb-3">
                            <input type="file" className="form-control"
                              aria-label="file example"
                              onChange={(e) => onImageUpload(e, "portrait")} />
                            {
                              profile.profile.portrait ? (
                                <img src={profile.profile.portrait} />
                              ) : null
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'id.photo.front', defaultMessage: '' })}
                          </div>
                          <div className="mb-3">
                            <input type="file" className="form-control"
                              aria-label="file example"
                              onChange={(e) => onImageUpload(e, "citizenFront")} />
                            {
                              profile.profile.citizenFront ? (
                                <img src={profile.profile.citizenFront} />
                              ) : null
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                        <div className='marginBottom10'>
                          <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'id.photo.back', defaultMessage: '' })}
                          </div>
                          <div className="mb-3">
                            <input type="file" className="form-control"
                              aria-label="file example"
                              onChange={(e) => onImageUpload(e, "citizenBack")} />
                            {
                              profile.profile.citizenBack ? (
                                <img className="anket-image" src={profile.profile.citizenBack} />
                              ) : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item paddingBottom50">
                    <div className='marginBottom10 font18 fontMedium'>1.10 {intl.formatMessage({ id: 'marital.status', defaultMessage: '' })}</div>
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'anket.who', defaultMessage: '' })}
                          </td>
                          <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'anket.first.last.name', defaultMessage: '' })}
                          </td>
                          <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'born.date', defaultMessage: '' })}
                          </td>
                          <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'profession', defaultMessage: '' })}
                          </td>
                          <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'working.organization', defaultMessage: '' })}
                          </td>
                          <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'working.position', defaultMessage: '' })}
                          </td>
                          <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'phone', defaultMessage: '' })}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          profile.family.map((fam: FamilyMember, index) => {
                            return <tr>
                              <td className="border padding0"><input type="text"
                                className="form-control fontLight "
                                onChange={(e) => itemFamilyChange(e.target.value, 'relation', index)} />
                              </td>
                              <td className="border padding0"><input type="text"
                                className="form-control fontLight "
                                onChange={(e) => itemFamilyChange(e.target.value, 'fullName', index)} />
                              </td>
                              <td className="border padding0"><input type="text"
                                className="form-control fontLight "
                                onChange={(e) => itemFamilyChange(e.target.value, 'birthDate', index)} />
                              </td>
                              <td className="border padding0"><input type="text"
                                className="form-control fontLight "
                                onChange={(e) => itemFamilyChange(e.target.value, 'profession', index)} />
                              </td>
                              <td className="border padding0"><input type="text"
                                className="form-control fontLight "
                                onChange={(e) => itemFamilyChange(e.target.value, 'appointment', index)} />
                              </td>
                              <td className="border padding0"><input type="text"
                                className="form-control fontLight "
                                onChange={(e) => itemFamilyChange(e.target.value, 'position', index)} />
                              </td>
                              <td className="border padding0"><input type="text"
                                className="form-control fontLight "
                                onChange={(e) => itemFamilyChange(e.target.value, 'phone', index)} />
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <button type="button"
                          className="primaryBackgroundColor marginTop15 paddingLeft40 font16 paddingRight40 paddingTop15 paddingBottom15 borderRadiusSmall textLight btnItem fontBold sendBtn"
                          onClick={() => addFamily()}>{intl.formatMessage({ id: 'add.family.member', defaultMessage: '' })}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="item paddingBottom50">
                    <div className="">
                      <div className="title marginBottom20">
                        <h3 className="textDark margin0 font20 fontBold upperCase">2.
                          {intl.formatMessage({ id: 'education.information', defaultMessage: '' })} </h3>
                      </div>
                      <div className='marginBottom10 font18 fontMedium '>2.1 {intl.formatMessage({ id: 'education', defaultMessage: '' })}</div>
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.school', defaultMessage: '' })}
                            </td>
                            <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.start.date', defaultMessage: '' })}
                            </td>
                            <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.end.date', defaultMessage: '' })}
                            </td>
                            <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.profession', defaultMessage: '' })}
                            </td>
                            <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.diploma.number', defaultMessage: '' })}
                            </td>
                            <td className="font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.average', defaultMessage: '' })}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            profile.education.schools.map((sch: School, index) => {
                              return <tr>
                                <td className="border padding0"><input type="text"
                                  className="form-control fontLight "
                                  onChange={(e) => itemSchoolChange(e.target.value, 'name', index)} />
                                </td>
                                <td className="border padding0"><input type="text"
                                  className="form-control fontLight "
                                  onChange={(e) => itemSchoolChange(e.target.value, 'startDate', index)} />
                                </td>
                                <td className="border padding0"><input type="text"
                                  className="form-control fontLight "
                                  onChange={(e) => itemSchoolChange(e.target.value, 'endDate', index)} />
                                </td>
                                <td className="border padding0"><input type="text"
                                  className="form-control fontLight "
                                  onChange={(e) => itemSchoolChange(e.target.value, 'occupation', index)} />
                                </td>
                                <td className="border padding0"><input type="text"
                                  className="form-control fontLight "
                                  onChange={(e) => itemSchoolChange(e.target.value, 'certificate', index)} />
                                </td>
                                <td className="border padding0"><input type="text"
                                  className="form-control fontLight "
                                  onChange={(e) => itemSchoolChange(e.target.value, 'GPA', index)} />
                                </td>
                              </tr>
                            })
                          }
                        </tbody>
                      </table>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <button type="button"
                            className="primaryBackgroundColor marginBottom15 marginTop15 paddingLeft40 font16 paddingRight40 paddingTop15 paddingBottom15 borderRadiusSmall textLight btnItem fontBold sendBtn"
                            onClick={() => addSchools()}>{intl.formatMessage({ id: 'add.school', defaultMessage: '' })}
                          </button>
                        </div>
                      </div>
                      <div className="">
                        <div className='marginBottom10 font18'>2.2 {intl.formatMessage({ id: 'profession.training', defaultMessage: '' })}</div>
                        <table className="table table-sm">
                          <thead>
                            <tr className=''>
                              <td className=" font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.school', defaultMessage: '' })}
                              </td>
                              <td className=" font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.start.date', defaultMessage: '' })}
                              </td>
                              <td className=" font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.end.date', defaultMessage: '' })}
                              </td>
                              <td className=" font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.profession', defaultMessage: '' })}
                              </td>
                              <td className=" font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.diploma.number', defaultMessage: '' })}
                              </td>
                              <td className=" font14 primaryBackgroundColor textLight">{intl.formatMessage({ id: 'education.average', defaultMessage: '' })}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              profile.education.training.map((tr: School, index) => {
                                return <tr>
                                  <td className="border padding0"><input type="text"
                                    className="form-control fontLight "
                                    onChange={(e) => itemTrainingChange(e.target.value, 'name', index)} />
                                  </td>
                                  <td className="border padding0"><input type="text"
                                    className="form-control fontLight "
                                    onChange={(e) => itemTrainingChange(e.target.value, 'startDate', index)} />
                                  </td>
                                  <td className="border padding0"><input type="text"
                                    className="form-control fontLight "
                                    onChange={(e) => itemTrainingChange(e.target.value, 'endDate', index)} />
                                  </td>
                                  <td className="border padding0"><input type="text"
                                    className="form-control fontLight "
                                    onChange={(e) => itemTrainingChange(e.target.value, 'occupation', index)} />
                                  </td>
                                  <td className="border padding0"><input type="text"
                                    className="form-control fontLight "
                                    onChange={(e) => itemTrainingChange(e.target.value, 'certificate', index)} />
                                  </td>
                                  <td className="border padding0"><input type="text"
                                    className="form-control fontLight "
                                    onChange={(e) => itemTrainingChange(e.target.value, 'GPA', index)} />
                                  </td>
                                </tr>
                              })
                            }
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <button type="button"
                              className="primaryBackgroundColor marginTop15 paddingLeft40 font16 paddingRight40 paddingTop15 paddingBottom15 borderRadiusSmall textLight btnItem fontBold sendBtn"
                              onClick={() => AddTraining()}>{intl.formatMessage({ id: 'add.profession', defaultMessage: '' })}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item paddingBottom50">
                    <div className="">
                      <div className="title marginBottom20">
                        <h3 className="textDark margin0 font20 fontBold upperCase">3. {intl.formatMessage({ id: 'skill.information', defaultMessage: '' })}</h3>
                      </div>
                      <div className="">
                        <div className='marginBottom10 font18'>3.1 {intl.formatMessage({ id: 'language.knowledge', defaultMessage: '' })}</div>
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              <th scope="col"
                                className=" font14 textLight primaryBackgroundColor vertical">{intl.formatMessage({ id: 'language.name', defaultMessage: '' })}
                              </th>
                              <th scope="col" className=" font14 textLight vertical">{intl.formatMessage({ id: 'language.listen', defaultMessage: '' })}
                              </th>
                              <th scope="col" className=" font14 textLight vertical">{intl.formatMessage({ id: 'language.talk', defaultMessage: '' })}
                              </th>
                              <th scope="col" className=" font14 textLight vertical">{intl.formatMessage({ id: 'language.read', defaultMessage: '' })}
                              </th>
                              <th scope="col" className=" font14 textLight vertical">{intl.formatMessage({ id: 'language.write', defaultMessage: '' })}
                              </th>
                              <th scope="col" className=" font14 textLight vertical">{intl.formatMessage({ id: 'language.translate', defaultMessage: '' })}
                              </th>
                              <th scope="col" className=" font14 textLight vertical">{intl.formatMessage({ id: 'language.exam.name', defaultMessage: '' })}
                              </th>
                              <th scope="col" className=" font14 textLight vertical">{intl.formatMessage({ id: 'language.exam.point', defaultMessage: '' })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (profile.skills.languages || []).map((lang: Language, index) => {
                                return (
                                  <tr key={index}>
                                    <td className=" font16 vertical">
                                      <input type="text"
                                        className="form-control fontLight"
                                        onChange={(e) => itemLanguageChange(e.target.value, 'name', index)} />
                                    </td>
                                    <td>
                                      <select defaultValue={lang.understand}
                                        onChange={(e) => itemLanguageChange(e.target.value, 'understand', index)}
                                        className="form-control">
                                        <option key={1} value={'Сайн'}>{intl.formatMessage({ id: 'good', defaultMessage: '' })}</option>
                                        <option key={2} value={'Дунд'}>{intl.formatMessage({ id: 'middle', defaultMessage: '' })}</option>
                                        <option key={3} value={'Муу'}>{intl.formatMessage({ id: 'bad', defaultMessage: '' })}</option>
                                      </select>
                                    </td>
                                    <td>
                                      <select defaultValue={lang.speak}
                                        onChange={(e) => itemLanguageChange(e.target.value, 'speak', index)}
                                        className="form-control">
                                        {
                                          ([intl.formatMessage({ id: 'good', defaultMessage: '' }), intl.formatMessage({ id: 'middle', defaultMessage: '' }), intl.formatMessage({ id: 'bad', defaultMessage: '' })]).map((item: any, index: number) => {
                                            return (
                                              <option key={index}
                                                value={item}>{item}</option>
                                            )
                                          })
                                        }
                                      </select>
                                    </td>
                                    <td>
                                      <select defaultValue={lang.read}
                                        onChange={(e) => itemLanguageChange(e.target.value, 'read', index)}
                                        className="form-control">
                                        {
                                          ([intl.formatMessage({ id: 'good', defaultMessage: '' }), intl.formatMessage({ id: 'middle', defaultMessage: '' }), intl.formatMessage({ id: 'bad', defaultMessage: '' })]).map((item: any, index: number) => {
                                            return (
                                              <option key={index}
                                                value={item}>{item}</option>
                                            )
                                          })
                                        }
                                      </select>
                                    </td>
                                    <td>
                                      <select defaultValue={lang.write}
                                        onChange={(e) => itemLanguageChange(e.target.value, 'write', index)}
                                        className="form-control">
                                        {
                                          ([intl.formatMessage({ id: 'good', defaultMessage: '' }), intl.formatMessage({ id: 'middle', defaultMessage: '' }), intl.formatMessage({ id: 'bad', defaultMessage: '' })]).map((item: any, index: number) => {
                                            return (
                                              <option key={index}
                                                value={item}>{item}</option>
                                            )
                                          })
                                        }
                                      </select>
                                    </td>
                                    <td>
                                      <select defaultValue={lang.translate}
                                        onChange={(e) => itemLanguageChange(e.target.value, 'translate', index)}
                                        className="form-control">
                                        {
                                          ([intl.formatMessage({ id: 'good', defaultMessage: '' }), intl.formatMessage({ id: 'middle', defaultMessage: '' }), intl.formatMessage({ id: 'bad', defaultMessage: '' })]).map((item: any, index: number) => {
                                            return (
                                              <option key={index}
                                                value={item}>{item}</option>
                                            )
                                          })
                                        }
                                      </select>
                                    </td>
                                    <td><select defaultValue={lang.exam}
                                      onChange={(e) => itemLanguageChange(e.target.value, 'exam', index)}
                                      className="form-control">
                                      {
                                        ExamValues.map((item: any, index: number) => {
                                          return (
                                            <option key={index}
                                              value={item}>{item}</option>
                                          )
                                        })
                                      }
                                    </select></td>
                                    <td><input type="text"
                                      className="form-control fontLight"
                                      defaultValue={lang.score}
                                      onChange={(e) => itemLanguageChange(e.target.value, 'score', index)} />
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <button type="button"
                              className="primaryBackgroundColor marginBottom15 marginTop15 paddingLeft40 font16 paddingRight40 paddingTop15 paddingBottom15 borderRadiusSmall textLight btnItem fontBold sendBtn"
                              onClick={() => addLanguages()}>{intl.formatMessage({ id: 'add.language', defaultMessage: '' })}
                            </button>
                          </div>
                        </div>
                        <div className='marginBottom10 font18'>3.2 {intl.formatMessage({ id: 'computer.title', defaultMessage: '' })}
                        </div>
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              {
                                profile.skills.programs.map((item: Program) => {
                                  return <th scope="col"
                                    className=" font14 textLight vertical"> {item.name}
                                  </th>
                                })
                              }
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {
                                profile.skills.programs.map((item: Program, index) => {
                                  return <td>
                                    <select defaultValue={item.skill}
                                      onChange={(e) => itemProgramChange(e.target.value, item.name, index)}
                                      className="form-control">
                                      {
                                        ([intl.formatMessage({ id: 'good', defaultMessage: '' }), intl.formatMessage({ id: 'middle', defaultMessage: '' }), intl.formatMessage({ id: 'bad', defaultMessage: '' })]).map((item: any, index: number) => {
                                          return (
                                            <option key={index}
                                              value={item}>{item}</option>
                                          )
                                        })
                                      }
                                    </select>
                                  </td>
                                })
                              }
                            </tr>
                          </tbody>
                        </table>
                        <div className='marginBottom10 font18 fontMedium'>3.3 {intl.formatMessage({ id: 'office.title', defaultMessage: '' })}
                        </div>
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              {
                                profile.skills.equipments.map((item: Equipment) => {
                                  return <th>
                                    {item.name}
                                  </th>
                                })
                              }
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {
                                profile.skills.equipments.map((item: Equipment, index) => {
                                  return <td className=" font14 vertical">
                                    <select defaultValue={item.skill}
                                      onChange={(e) => itemEquipChange(e.target.value, item.name, index)}
                                      className="form-control">
                                      {
                                        ([intl.formatMessage({ id: 'good', defaultMessage: '' }), intl.formatMessage({ id: 'middle', defaultMessage: '' }), intl.formatMessage({ id: 'bad', defaultMessage: '' })]).map((item: any, index: number) => {
                                          return (
                                            <option key={index}
                                              value={item}>{item}</option>
                                          )
                                        })
                                      }
                                    </select>
                                  </td>
                                })
                              }
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="item paddingBottom50">
                    <div className="">
                      <div className="title marginBottom20">
                        <h3 className="textDark margin0 font20 fontBold upperCase">4. {intl.formatMessage({ id: 'employment.information', defaultMessage: '' })}</h3>
                      </div>
                      {
                        profile.exp.map((item: Experience, index) => {
                          return <div className="">
                            <div className='marginBottom10 font18 fontMedium'>{intl.formatMessage({ id: 'work.experience', defaultMessage: '' })}
                            </div>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th className=" font14 primaryBackgroundColor textLight  vertical alignCenter">
                                    {intl.formatMessage({ id: 'organization.name', defaultMessage: '' })}
                                  </th>
                                  <th className=" font14 primaryBackgroundColor textLight  vertical alignCenter">
                                    {intl.formatMessage({ id: 'organization.areas', defaultMessage: '' })}
                                  </th>
                                  <th className=" font14 primaryBackgroundColor textLight  vertical alignCenter">
                                    {intl.formatMessage({ id: 'organization.position', defaultMessage: '' })}
                                  </th>
                                  <th className=" font14 primaryBackgroundColor textLight  vertical alignCenter">
                                  {intl.formatMessage({ id: 'organization.start.date', defaultMessage: '' })}
                                  </th>
                                  <th className=" font14 primaryBackgroundColor textLight  vertical alignCenter">
                                  {intl.formatMessage({ id: 'organization.end.date', defaultMessage: '' })}
                                  </th>
                                  <th className=" font14 primaryBackgroundColor textLight  vertical alignCenter">
                                  {intl.formatMessage({ id: 'organization.salary', defaultMessage: '' })}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="border vertical">
                                    <input type="text"
                                      className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'organizationName', index)} />
                                  </td>
                                  <td className="border vertical">
                                    <input type="text"
                                      className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'direction', index)} />
                                  </td>
                                  <td className="border vertical">
                                    <input type="text"
                                      className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'position', index)} />
                                  </td>
                                  <td className="border vertical">
                                    <input type="text"
                                      className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'startDate', index)} />
                                  </td>
                                  <td className="border vertical">
                                    <input type="text"
                                      className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'endDate', index)} />
                                  </td>
                                  <td className="border vertical">
                                    <input type="text"
                                      className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'salary', index)} />
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={3}
                                    className="border vertical font14 alignCenter">{intl.formatMessage({ id: 'working.role', defaultMessage: '' })}
                                  </td>
                                  <td colSpan={3}
                                    className="border vertical font14 alignCenter">{intl.formatMessage({ id: 'working.achievements', defaultMessage: '' })}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={3} className="border vertical">
                                    <input type="text" className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'role', index)} />
                                  </td>
                                  <td colSpan={3} className="border vertical">
                                    <input type="text" className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'achievements', index)} />
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={5}
                                    className="border vertical alignCenter font14">{intl.formatMessage({ id: 'officer.information', defaultMessage: '' })}
                                  </td>
                                  <td rowSpan={2}
                                    className="border vertical alignCenter font14">{intl.formatMessage({ id: 'working.end.reason', defaultMessage: '' })}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2} className="border vertical font14">{intl.formatMessage({ id: 'first.name', defaultMessage: '' })}</td>
                                  <td colSpan={2} className="border vertical font14">{intl.formatMessage({ id: 'officer.officer', defaultMessage: '' })}
                                  </td>
                                  <td colSpan={2} className="border vertical font14">{intl.formatMessage({ id: 'phone', defaultMessage: '' })}</td>
                                </tr>
                                <tr>
                                  <td colSpan={2} className="border vertical">
                                    <input type="text" className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'reason', index)} />
                                  </td>
                                  <td colSpan={2} className="border vertical">
                                    <input type="text" className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'name', index)} />
                                  </td>
                                  <td colSpan={1} className="border vertical">
                                    <input type="text" className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'phone', index)} />
                                  </td>
                                  <td colSpan={1} className="border vertical">
                                    <input type="text" className="form-control fontLight"
                                      onChange={(e) => itemExpChange(e.target.value, 'appoint', index)} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        })
                      }
                    </div>
                    <div>
                      <button type="button"
                        className="primaryBackgroundColor marginTop15 paddingLeft40 font16 paddingRight40 paddingTop15 paddingBottom15 borderRadiusSmall textLight btnItem fontBold sendBtn"
                        onClick={() => addExp()}>{intl.formatMessage({ id: 'add.work.experience', defaultMessage: '' })}
                      </button>
                    </div>
                  </div>
                  <div className="item paddingBottom30">
                    <div>
                      <div className="title marginBottom20">
                        <h3 className="textDark margin0 font20 fontBold upperCase">5. {intl.formatMessage({ id: 'other', defaultMessage: '' })}</h3>
                      </div>
                      <div>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className='marginBottom10'>
                              <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'interesting.job', defaultMessage: '' })}
                              </div>
                              <input type="text" className="form-control fontLight"
                                onChange={(e) => changeProfile({
                                  ...profile,
                                  other: {
                                    ...profile.other,
                                    interest: e.target.value,
                                  },
                                })} />
                            </div>
                            <div className='marginBottom10'>
                              <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'interesting.salary.maximum', defaultMessage: '' })}
                              </div>
                              <input type="text" className="form-control fontLight"
                                onChange={(e) => changeProfile({
                                  ...profile,
                                  other: {
                                    ...profile.other,
                                    salary: {
                                      ...profile.other.salary,
                                      min: Number(e.target.value),
                                    },
                                  },
                                })} />
                            </div>
                            <div className='marginBottom10'>
                              <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'have.loan', defaultMessage: '' })}
                              </div>
                              <select className="form-select padding9"
                                aria-label="Default select example"
                                onChange={(e) => changeProfile({
                                  ...profile, other: {
                                    ...profile.other,
                                    loan: e.target.value === '1',
                                  },
                                })}>
                                <option selected>{intl.formatMessage({ id: 'please.select', defaultMessage: '' })}</option>
                                <option value={1}>{intl.formatMessage({ id: 'yes', defaultMessage: '' })}</option>
                                <option value={0}>{intl.formatMessage({ id: 'no', defaultMessage: '' })}</option>
                              </select>
                            </div>

                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className='marginBottom10'>
                              <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'why.working', defaultMessage: '' })}
                              </div>
                              <input type="text" value={profile.other.purpose} onChange={(e) => changeProfile({
                                ...profile,
                                other: {
                                  ...profile.other,
                                  purpose: e.target.value,
                                },
                              })} className="form-control fontLight" />
                            </div>
                            <div className='marginBottom10'>
                              <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'interesting.salary.minimum', defaultMessage: '' })}
                              </div>
                              <input type="text" className="form-control fontLight"
                                onChange={(e) => changeProfile({
                                  ...profile,
                                  other: {
                                    ...profile.other,
                                    salary: {
                                      ...profile.other.salary,
                                      max: Number(e.target.value),
                                    },
                                  },
                                })} />
                            </div>
                            <div className='marginBottom10'>
                              <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'have.loan.type', defaultMessage: '' })}
                              </div>
                              <select defaultValue={profile.other.loanType}
                                onChange={(e) => changeProfile({
                                  ...profile,
                                  other: {
                                    ...profile.other,
                                    loanType: e.target.value,
                                  },
                                })}
                                className="form-control">
                                {
                                  (['Цалингийн зээл', 'Лизинг', 'Орон сууцны зээл', 'Малчны зээл', 'Тэтгэвэрийн зээл', 'Зээлийн кард', 'Төслийн зээл', 'Хөрөнгө оруулалтын зээл', 'Эргэлтийн хөрөнгийн зээл']).map((item: string, index: number) => {
                                    return (
                                      <option key={index}
                                        value={item}>{item}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className='marginBottom10'>
                              <div className='font14 textSecondary marginBottom10'>{intl.formatMessage({ id: 'anket.additional.information', defaultMessage: '' })}
                              </div>
                              <textarea className="form-control"
                                id="exampleFormControlTextarea1"
                                onChange={(e) => changeProfile({
                                  ...profile,
                                  other: {
                                    ...profile.other,
                                    additionalInfo: e.target.value,
                                  },
                                })} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="marginBottom70">
                    <CustomButton type="submit" loading={profile.loading} title={intl.formatMessage({ id: 'send.application', defaultMessage: '' })} />
                    {/*<a*/}
                    {/*    className="primaryBackgroundColor paddingLeft40 font16 paddingRight40 paddingTop15 paddingBottom15 borderRadiusSmall textLight btnItem fontBold sendBtn"*/}
                    {/*    onClick={() => console.log('profile-', profile)}>Анкет илгээх </a>*/}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Anket
