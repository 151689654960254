import * as React from 'react'
import { Link } from 'react-router-dom'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import fetchApi from '../../../api/fetchApi'
import config from '../../../config'
import moment from 'moment';
import { Pagination } from 'react-bootstrap'
import UserContext from '../../../context/UserContext'
import { useIntl } from 'react-intl'

const queryString = require("query-string");

interface State {
  loading: boolean
  result: any
}
const initialState:State = {
  loading: true,
  result: {}
}
interface Filter {
  page: number
  limit: number
}
const initialFilter: Filter = {
  page: 1,
  limit: 5
}
const Workplace = () => {
  const intl = useIntl();
  const {user} = useContext(UserContext)
  const [state, updateState] = useState(initialState);
  const [filter, updateFilter] = useState(initialFilter);
  const fetchContent = useCallback(async ()=>{
    const response = await fetchApi.requestGet(`${config.get("myPostApiUrl")}/api/work/list?${queryString.stringify(filter)}`);
    if(response.success){
      updateState({
        loading: false,
        result: response.result || {}
      })
    }else{
      updateState({
        loading: false,
        result: []
      })
    }
  },[state,filter])
  useEffect(()=>{
    fetchContent()
  },[filter])
  const pageChange = useCallback((value)=>{
    updateFilter({
      ...filter,
      page: value
    })
  },[filter])
  const paginationItems = useMemo(()=>{
    let active = filter.page;
    let items = [];
    const pageCount = (state.result?.count || []) % filter.limit > 0 ? (state.result?.count || []) / filter.limit + 1 : (state.result?.count || []) / filter.limit
    console.log(pageCount)
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item onClick={()=>pageChange(number)} key={number} active={number === active}>
          {number}
        </Pagination.Item>,
      );
    }
    return items
  },[state, filter])
  return (
    <main>
      <div className="about">
        <div className="defaultBackgroundColor">
          <div className="feachuredImage paddingTop70 paddingBottom70"
               style={{backgroundImage: 'url(images/header.jpg)'}}>
            <h1 className="title margin0 padding0 textLight text-center">{intl.formatMessage({id: 'workplace.title', defaultMessage: ''})}</h1>
            <div className="breadcrumb marginTop10">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb margin0 font17">
                  <li className="breadcrumb-item textLight">{intl.formatMessage({id: 'home.page', defaultMessage: ''})}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {intl.formatMessage({id: 'workplace', defaultMessage: ''})}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="navigation d-flex justify-content-center paddingTop20 paddingBottom20 font16 borderBottom ">
            <Link className="position-relative" to={'/about'}>{intl.formatMessage({id: 'about.us', defaultMessage: ''})}</Link>
            <Link className="position-relative active" to={'/workplace'}>{intl.formatMessage({id: 'workplace', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/help'}>{intl.formatMessage({id: 'help.center', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/contact'}>{intl.formatMessage({id: 'contact.us', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/branch'}>{intl.formatMessage({id: 'branch.location', defaultMessage: ''})}</Link>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"/>
              <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-12 col-sm-12">
                <div className="paddingTop50 paddingBottom50">
                  <div className="head d-flex justify-content-between marginBottom30 align-items-center">
                    <div>
                      <span className="d-block fontRegular font14">
                        {intl.formatMessage({id: 'total', defaultMessage: ''})}
                      </span>
                      <div className="font22 fontMedium">{state.result?.count || 0} {intl.formatMessage({id: 'workplace', defaultMessage: ''}).toLowerCase()}</div>
                    </div>
                    <div className="">
                      <Link to={'/anket'} className="border borderRadiuslarge paddingTop10 paddingBottom10 paddingLeft25 paddingRight25">
                        {intl.formatMessage({id: 'workplace.anket.fill', defaultMessage: ''})}</Link>
                    </div>
                  </div>
                  <div className="accordion" id="accordionExample">
                    {
                      (state.result?.rows || []).map((item:any, index: number)=>{
                        return (
                          <div key={index} className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <div className="accordion-button d-inline-block" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <div className="item">
                                  <div className="top d-inline-block borderBottom marginBottom15" style={{width: '100%'}}>
                                    <div className="float-start">
                                      <h3 className="margin0 padding0 font18 textSecondary">
                                        <Link to={''} className="fontMedium">{item?.title}</Link>
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="bottom">
                                    <div className="font14 fontRegular borderRight d-inline-block paddingRight15">
                                      {intl.formatMessage({id: 'start', defaultMessage: ''})}: {moment(item.start_date).format("YYYY-MM-DD")}  / {intl.formatMessage({id: 'end', defaultMessage: ''})}: {moment(item.end_date).format("YYYY-MM-DD")}
                                    </div>
                                    <div
                                      className="font14 fontRegular borderRight d-inline-block paddingLeft15 paddingRight15">
                                      +976 77012424
                                    </div>
                                    <div className="font14 fontRegular paddingLeft15 d-inline-block">
                                      hr@mypost.mn
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                 data-bs-parent="#accordionExample">
                              <div className="accordion-body" dangerouslySetInnerHTML={{__html: item.content || ""}}/>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <Pagination>
                    {paginationItems}
                  </Pagination>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"/>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Workplace