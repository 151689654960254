import React, {useCallback, useState} from "react";
import CustomButton from "../lib/CustomButton";
import { Link, useLocation, useParams } from 'react-router-dom'
import {useToasts} from "react-toast-notifications";
import fetchApi from "../../api/fetchApi";
import config from "../../config";
import { useIntl } from "react-intl";
interface State {
    loading: boolean
    password: string
    password_confirm: string
}
const initialState: State = {
    loading: false,
    password: "",
    password_confirm: ""
}
const ResetPassword = () => {
    const {token} = useParams()
    const intl = useIntl();
    const [state, updateState] = useState(initialState)
    const {addToast} = useToasts();
    const location = useLocation();
    const resetPassword = useCallback(async ()=>{
        if(state.password !== state.password_confirm){
            addToast(intl.formatMessage({id: 'new.again.password.incorrect', defaultMessage: ''}), {appearance: 'error'})
            return;
        }
        updateState({...state, loading: true});
        const response = await fetchApi.requestPost(`${config.get('myPostApiUrl')}/api/auth/reset/password`,{
            password: state.password,
            token: token
        });
        if(response.success){
            addToast(`Нууц үг амжилттай солигдлоо`, { appearance: 'success' });

        }else{
            addToast(response.message, { appearance: 'error' })
        }
        updateState(initialState)
    },[state, token]);
    return (
        <main>
            <div className="login defaultBackgroundColor text-center d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="content paddingTop100 paddingBottom100">
                        <div className="top">
                            <div className="middle paddingTop50 paddingBottom25">
                                <div className="first paddingBottom25">
                                    <h2 className="margin0 font32 fontBold">{intl.formatMessage({id: 'forgot.password', defaultMessage: ''})}</h2>
                                </div>
                                <div className="last">
                                    <form>
                                        <div className="paddingBottom5">
                                            <input type="password" value={state.password} onChange={(e)=>updateState({...state, password: e.target.value})} placeholder={intl.formatMessage({id: 'password', defaultMessage: ''})}
                                                   className="form-control fontRegular borderRadiusSmall"/>
                                        </div>
                                        <div className="paddingBottom5">
                                            <input type="password" value={state.password_confirm} onChange={(e)=>updateState({...state, password_confirm: e.target.value})} placeholder={intl.formatMessage({id: 'new.again.password', defaultMessage: ''})}
                                                   className="form-control fontRegular borderRadiusSmall"/>
                                        </div>
                                        <div className="paddingTop10">
                                            <CustomButton title={intl.formatMessage({id: 'send', defaultMessage: ''})} loading={state.loading} reload={resetPassword} />
                                        </div>
                                        <div className="forget paddingTop10">
                                            <Link to={'/login'}
                                                  className="btn fontRegular borderRadiusSmall all-hover paddingTop10 paddingBottom10">
                                                {intl.formatMessage({id: 'back', defaultMessage: ''})}
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
export default ResetPassword