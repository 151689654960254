import * as React from 'react'
import { Link } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import fetchApi from '../../../api/fetchApi'
import config from '../../../config'
import { useIntl } from 'react-intl'

const queryString = require("query-string");

interface State {
  loading: boolean
  result: any
}
const initialState:State = {
  loading: true,
  result: {}
}
interface Filter {
  page: number
  max: number
}
const initialFilter: Filter = {
  page: 1,
  max: 20
}
const Branch = () => {
  const intl = useIntl();
  const [state, updateState] = useState(initialState);
  const [filter, updateFilter] = useState(initialFilter);
  const fetchBranch = useCallback(async ()=>{
    const response = await fetchApi.requestGet(`${config.get('coreApiUrl')}/api/branch/front?${queryString.stringify(filter)}`);
    if(response.success){
      const result = response.result?.result || {}
      updateState({
        loading: false,
        result: result
      })
    }else{
      updateState({
        loading: false,
        result: {}
      })
    }
  },[filter])
  useEffect(()=>{
    fetchBranch()
  },[filter])
  return (
    <main>
      <div className="defaultBackgroundColor">
        <div className="location">
          <div className="feachuredImage paddingTop70 paddingBottom70"
               style={{ backgroundImage: 'url(images/header.jpg)' }}>
            <h1 className="title margin0 padding0 textLight text-center">{intl.formatMessage({id: 'branch.location', defaultMessage: ''})}</h1>
            <div className="breadcrumb marginTop10">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb margin0 font17">
                  <li className="breadcrumb-item textLight">{intl.formatMessage({id: 'home.page', defaultMessage: ''})}</li>
                  <li className="breadcrumb-item active" aria-current="page">{intl.formatMessage({id: 'contact.us', defaultMessage: ''})}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="navigation d-flex justify-content-center paddingTop20 paddingBottom20 font16">
            <Link className="position-relative" to={'/about'}>{intl.formatMessage({id: 'about.us', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/workplace'}>{intl.formatMessage({id: 'workplace', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/help'}>{intl.formatMessage({id: 'help.center', defaultMessage: ''})}</Link>
            <Link className="position-relative" to={'/contact'}>{intl.formatMessage({id: 'contact.us', defaultMessage: ''})}</Link>
            <Link className="position-relative active" to={'/branch'}>{intl.formatMessage({id: 'branch.location', defaultMessage: ''})}</Link>
          </div>
          <div className="bottom">
            <div className="container">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="paddingTop50 paddingBottom50">
                  {
                    (state.result?.branchList || []).map((branch:any, index: number)=> {
                      return (
                        <div key={index} className="listItems defaultBackgroundColor padding30">
                          <div className="row">
                            <div className="col-xxl-3 col-xxl-3 col-lg-3 col-md-6 col-sm-12">
                              <div className="image">
                                <img src="/images/map_1.jpg"/>
                              </div>
                            </div>
                            <div className="col-xxl-9 col-xxl-9 col-lg-9 col-md-6 col-sm-12">

                              <div className="row">
                                <div className="col-xxl-8 col-xl-7 col-lg-7 col-md-12 col-sm-12">
                                  <div className="">
                                    <h3 className="text-uppercase textPrimary fontBold font18">
                                      {branch.name}
                                    </h3>
                                    <div className="desciption marginTop20">
                                      <p className="fontRegular font16">
                                        {branch.addressString}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-12 col-sm-12">
                                  <div>
                                    <ul className="padding0 margin0 font16">
                                      <li><b className="fontMedium">{intl.formatMessage({id: 'phone', defaultMessage: ''})}:</b> +976 - {branch.phone1}</li>
                                      <li className="paddingTop15 paddingBottom15"><b className="fontMedium">{intl.formatMessage({id: 'email', defaultMessage: ''})}:</b> info@mypost.mn
                                      </li>
                                      <li className="paddingBottom15">{intl.formatMessage({id: 'mon.fri', defaultMessage: ''})}: 09:00 - 20:00</li>
                                      <li>{intl.formatMessage({id: 'sat.sun', defaultMessage: ''})}: 11:00 - 15:00</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                  {
                    !state.loading && !(state.result?.branchList || []).length ? (
                      <div><h5 style={{textAlign:"center"}}>{intl.formatMessage({id: 'data.empty', defaultMessage: ''})}</h5></div>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Branch