let configList: any = {
    coreApiUrl: 'https://mypost.mn/core_api',
    orderApiUrl: 'https://mypost.mn/order_api',
    deliveryApiUrl: 'https://mypost.mn/delivery_api',
    prefix: '/',
    myPostApiUrl: 'https://mypost.mn/front_api'
};
interface Config{
    get:(option:string)=>string
    config:(data:any)=>void
}
let config: Config = {
    get: (option: string) => {
        return configList[option]
    },
    config: (data: any) => {
        configList = {
            ...configList,
            ...data
        };
    }
};
export default config
