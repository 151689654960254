import * as React from 'react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import fetchApi from '../../api/fetchApi'
import config from '../../config'
import { useToasts } from 'react-toast-notifications'
import UserContext from '../../context/UserContext'
import { Link, useParams } from 'react-router-dom'
import { formatMoney } from '../../utils/utils'
import { useIntl } from 'react-intl'

interface State {
  loading: boolean
  order: any
  invoice: string
}

const initialState: State = {
  loading: true,
  order: null,
  invoice: ""
}
const Payment = () => {
  const intl = useIntl();
  const {frontOrderId} = useParams<any>();
  const {user} = useContext(UserContext);
  const { addToast } = useToasts()
  const [state, updateState] = useState(initialState)

  const fetchOrder = useCallback(async (oid:any)=>{
    console.log("order id ",oid)
    const [order, invoice] = await Promise.all([
      await fetchApi.requestPost(`${config.get('orderApiUrl')}/api/front/order/detail`,{orderId: oid, frontMemberId: user.id}),
      await fetchApi.requestPost(`${config.get('orderApiUrl')}/api/front/order/invoice`,{orderId: oid, frontMemberId: user.id}),
    ])
    console.log(order)
    console.log(invoice)
    updateState({
      loading: false,
      order: order?.result?.result?.order || {},
      invoice: invoice?.result?.result?.url || ""
    })
  },[])

  useEffect(()=>{
    fetchOrder(frontOrderId)
  },[frontOrderId])
  const totalWeight = useMemo(() => {
    return (state.order?.lineItemList || []).reduce((total: number, item: any) => total + parseFloat(item.weight),
      0)
  }, [state])
  const totalPrice = useMemo(() => {
    return (state.order?.lineItemList || []).reduce((total: number, item: any) => total + item.deliveryCost,
      0)
  }, [state])
  const servicePrice = useMemo(() => {
    return (state.order?.additionalServiceList || []).reduce((total: number, item: any) => total + item.cost,
      0)
  }, [state])
  return (
    <main>
      <div className="about">
        <div className="defaultBackgroundColor">
          <div className="feachuredImage paddingTop70 paddingBottom70"
               style={{ backgroundImage: 'url(/images/header.jpg)' }}>
            <h1 className="title margin0 padding0 textLight text-center">
              {intl.formatMessage({id: 'payment.title', defaultMessage: ''})}
            </h1>
            <div className="breadcrumb marginTop10">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb margin0 font17">
                  <li className="breadcrumb-item textLight">{intl.formatMessage({id: 'individual', defaultMessage: ''})}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {intl.formatMessage({id: 'payment.title', defaultMessage: ''})}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-12 col-sm-12">
                <div className="payment">
                  <div className="paddingTop50">
                    <div className="step_1 marginBottom50">
                      {
                        state.order && state.invoice ? (
                          <iframe src={`${state.invoice}`} width="100%" height="600" style={{border: "none"}}/>
                        ) : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-12 col-sm-12">
                <div className="sidebar">
                  <div className="paddingTop50 paddingLeft50 paddingBottom50">
                    <div className="title">
                      <h3 className="text-left fontBold font20 margin0">
                      {intl.formatMessage({id: 'order.information', defaultMessage: ''})}
                      </h3>
                    </div>
                    <div className="content marginTop40 padding30 borDer borderRadiusSmall">
                      <ul className="padding0 margin0">
                        <li className="borderBottom paddingBottom10 paddingTop10">
                          <div className="d-flex justify-content-between">
                            <div className="fontLight text-muted font16">
                            {intl.formatMessage({id: 'total.quantity', defaultMessage: ''})}
                            </div>
                            <div className="fontBold textSecondary font16 d-block">
                              {(state.order?.lineItemList || []).length}
                            </div>
                          </div>
                        </li>
                        <li className="borderBottom paddingBottom10 paddingTop10">
                          <div className="d-flex justify-content-between">
                            <div className="fontLight text-muted font16">
                            {intl.formatMessage({id: 'total.weight', defaultMessage: ''})}
                            </div>
                            <div className="fontBold textSecondary font16 d-block">
                              {totalWeight}
                            </div>
                          </div>
                        </li>
                        <li className="borderBottom paddingBottom10 paddingTop10">
                          <div className="d-flex justify-content-between">
                            <div className="fontLight text-muted font16">
                            {intl.formatMessage({id: 'report.total', defaultMessage: ''})}
                            </div>
                            <div className="fontBold textSecondary font16 d-block">
                              {formatMoney(totalPrice)}
                            </div>
                          </div>
                        </li>
                        <li className="borderBottom paddingBottom10 paddingTop10">
                          <div className="d-flex justify-content-between">
                            <div className="fontLight text-muted font16">
                            {intl.formatMessage({id: 'additional.service', defaultMessage: ''})}
                            </div>
                            <div className="fontBold textSecondary font16 d-block">
                              {formatMoney(servicePrice)}
                            </div>
                          </div>
                        </li>
                        <li className="borderBottom paddingBottom10 paddingTop10">
                          <div className="d-flex justify-content-between">
                            <div className="fontLight text-muted font16">
                            {intl.formatMessage({id: 'noat', defaultMessage: ''})}
                            </div>
                            <div className="fontBold textSecondary font16 d-block">
                              {formatMoney((totalPrice + servicePrice) * 0.1)}
                            </div>
                          </div>
                        </li>
                        <li className="borderBottom paddingBottom10 paddingTop10">
                          <div className="d-flex justify-content-between">
                            <div className="fontLight text-muted font16">
                            {intl.formatMessage({id: 'payment.total', defaultMessage: ''})}
                            </div>
                            <div className="fontBold textSecondary font16 d-block">
                              {formatMoney((totalPrice + servicePrice))}
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="pay">
                        <Link
                          className="fontBold primaryBackgroundColor textLight paddingTop20 paddingBottom20 borderRadiusSmall d-block text-center"
                          to="/order">{intl.formatMessage({id: 'order.end', defaultMessage: ''})}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Payment