import * as React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useCallback, useContext, useEffect} from "react";
import UserContext from "../../context/UserContext";
import { useIntl } from 'react-intl';

const UserMenu = () => {
  const intl = useIntl();
  const {user, changeUser} = useContext(UserContext);
  const navigate = useNavigate();
  const logout = useCallback((e)=>{
    e.preventDefault();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("orderAccessToken");
    changeUser(null)
    navigate('/')
  },[]);
  return (
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
      <div
        className="info paddingTop30 paddingBottom20 defaultBackgroundColor borDer borderRadiusSmall marginBottom25">
        <div className="name">
          <ul className="margin0 padding0 text-center">
            <li className="marginBottom25">
              <span className="border-pill">{(user?.last_name || " ").substring(0,1)}</span>
            </li>
            <li className="fontMedium font16">{user?.first_name} {user?.last_name}</li>
            {/*<li className="font14 fontLight">Хэрэглэгчйн дугаар 1615772</li>*/}
          </ul>
        </div>
        {/*<div className="margin15">*/}
        {/*  <div className="lightBackgroundColor borDer borderRadiusSmall padding15 d-flex align-items-center">*/}
        {/*    <div>*/}
        {/*      <img width={36} src="images/gift.png"/>*/}
        {/*    </div>*/}
        {/*    <div className="marginLeft10 font14 fontRegular text-muted">*/}
        {/*      Танд шуудан илгээх*/}
        {/*      <span className="d-block fontMedium font16 textSecondary">5 эрх байна </span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="menu marginLeft15 marginTop20">
          <ul className="margin0 padding0 uk-position-relative">
            <li className="marginBottom10">
              <Link to={'/profile'} className="text-muted">
              {intl.formatMessage({id: 'general.info', defaultMessage: ''})}
              </Link>
            </li>
            <li className="marginBottom10">
              <Link to={'/user/edit/info'} className="textSecondary">{intl.formatMessage({id: 'personal.information', defaultMessage: ''})}</Link>
            </li>
            <li>
              <Link to={'#'} onClick={(e)=>logout(e)} className="text-muted">{intl.formatMessage({id: 'logout', defaultMessage: ''})}</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default UserMenu