import * as React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useCallback } from 'react'
import fetchApi from '../api/fetchApi'
import config from '../config'
import { notification } from 'antd'
import { useState } from 'react'
import { useEffect } from 'react'
import ErrorPage from './ErrorPage'

interface State {
    loading: boolean
    result: any
    status: boolean
}

const initialState: State = {
    loading: true,
    result: {},
    status: false

}


const Content = () => {
    const { slug } = useParams()
    const [state, updateState] = useState(initialState)
    const fetchMenu = useCallback(async () => {
        try {
            updateState({
                ...state,
                loading: true,
            })
            const response = await fetchApi.requestGet(`${config.get('myPostApiUrl')}/api/slider/detail/${slug}`)
            console.log(response)
            updateState({
                loading: false,
                result: response.result || {},
                status: response.success
            })
        } catch (e: any) {
            notification.error({
                message: e.message?.[0] || 'Алдаа гарлаа дахин оролдоно уу',
            })
        }
    }, [state, slug])
    useEffect(() => {
        fetchMenu().then()
    }, [slug])
    return (
        <>
            {
                state.loading ?
                    <div>
                        Loading
                    </div> : (
                        <div className="about">
                            {
                                state.status == true ? (
                                    <div className="defaultBackgroundColor">
                                        <div className="feachuredImage paddingTop70 paddingBottom70">
                                            <h1 className="title margin0 padding0 textDark text-center">
                                                {state.result?.title || ""}
                                            </h1>
                                        </div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"></div>
                                                <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-12 col-sm-12">
                                                    <div className="paddingTop50 paddingBottom50">
                                                        {state.result?.description}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-12 col-sm-12"></div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        </div>
                    )
            }
        </>
    )
}

export default Content