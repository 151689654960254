import * as React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const Contact = () => {
  const intl = useIntl()
  return (
    <main>
      <div className="contact about">
        <div className="defaultBackgroundColor">
          <div className="feachuredImage paddingTop70 paddingBottom70"
            style={{ backgroundImage: 'url(images/header.jpg)' }}>
            <h1 className="title margin0 padding0 textLight text-center">{intl.formatMessage({ id: 'contact.us', defaultMessage: '' })}</h1>
            <div className="breadcrumb marginTop10">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb margin0 font17">
                  <li className="breadcrumb-item textLight">{intl.formatMessage({ id: 'home.page', defaultMessage: '' })}</li>
                  <li className="breadcrumb-item active" aria-current="page">{intl.formatMessage({ id: 'contact.us', defaultMessage: '' })}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="navigation d-flex justify-content-center paddingTop20 paddingBottom20 font16 borderBottom ">
            <Link className="position-relative" to={'/about'}>{intl.formatMessage({ id: 'about.us', defaultMessage: '' })}</Link>
            <Link className="position-relative" to={'/workplace'}>{intl.formatMessage({ id: 'workplace', defaultMessage: '' })}</Link>
            <Link className="position-relative" to={'/help'}>{intl.formatMessage({ id: 'help.center', defaultMessage: '' })}</Link>
            <Link className="position-relative active" to={'/contact'}>{intl.formatMessage({ id: 'contact.us', defaultMessage: '' })}</Link>
            <Link className="position-relative" to={'/branch'}>{intl.formatMessage({ id: 'branch.location', defaultMessage: '' })}</Link>
          </div>
          <div className="paddingTop50">
            <div className="container">
              <div className="first paddingBottom25">
                <div className="row">
                  <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12" />
                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12">
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="init text-center">
                          <div className="title paddingBottom15">
                            <h3 className="margin0 padding0 font18 fontMedium">{intl.formatMessage({ id: 'main.office', defaultMessage: '' })}</h3>
                          </div>
                          <div className="desciption">
                            <p className="margin0 font16">
                              {intl.formatMessage({ id: 'main.office.address', defaultMessage: '' })}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="init text-center">
                          <div className="title paddingBottom15">
                            <h3 className="margin0 padding0 font18 fontMedium">{intl.formatMessage({id: 'contact.us', defaultMessage: ''})}</h3>
                          </div>
                          <div className="desciption">
                            <p className="margin0 font16">
                            {intl.formatMessage({id: 'phone', defaultMessage: ''})}: <a href="tel: +976 77012424">+(976)-77012424</a><br />
                            {intl.formatMessage({id: 'email', defaultMessage: ''})}: <a href="mailto: info@mypost.mn">info@mypost.mn</a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="init text-center">
                          <div className="title paddingBottom15">
                            <h3 className="margin0 padding0 font18 fontMedium">{intl.formatMessage({id: 'social.title', defaultMessage: ''})}</h3>
                          </div>
                          <div className="desciption">
                            <p className="margin0 font16">
                            {intl.formatMessage({id: 'facebook', defaultMessage: ''})}: <a href="https://www.facebook.com/mypost2424">Инфинити шуудан</a><br />
                            {intl.formatMessage({id: 'chatbot', defaultMessage: ''})}т: <a href="http://m.me/105263068324421">Инфинити чат бот</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12" />
                </div>
              </div>
              <div className="last paddingTop40">
                <div className="title">
                  <h4 className="margin0 padding0 font22 text-center fontMedium">{intl.formatMessage({id: 'request', defaultMessage: ''})}</h4>
                </div>
                <div className="desciption">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12" />
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <div className="send marginTop25">
                        <form>
                          <div className="mb-3">
                            <input type="text" className="form-control fontLight" placeholder={intl.formatMessage({id: 'contact.name', defaultMessage: ''})} />
                          </div>
                          <div className="mb-3">
                            <input type="text" className="form-control fontLight" placeholder={intl.formatMessage({id: 'contact.phone', defaultMessage: ''})} />
                          </div>
                          <div className="mb-3">
                            <input type="email" className="form-control fontLight" placeholder={intl.formatMessage({id: 'contact.email', defaultMessage: ''})} />
                          </div>
                          <div className="mb-3">
                            <textarea className="form-control fontLight" placeholder={intl.formatMessage({id: 'write.here', defaultMessage: ''})}
                              rows={3} />
                          </div>
                          <button type="submit"
                            className="d-flex all-hover btn primaryBackgroundColor textLight fontMedium paddingTop10 paddingBottom10 paddingLeft40 paddingRight40">
                            {intl.formatMessage({id: 'send.us', defaultMessage: ''})}
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12" />
                  </div>
                </div>
              </div>
            </div>
            <div className="embed marginTop70">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1336.9084748121911!2d106.9101364326824!3d47.920581374996786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDfCsDU1JzEzLjgiTiAxMDbCsDU0JzM2LjUiRQ!5e0!3m2!1sen!2smn!4v1632237097736!5m2!1sen!2smn"
                width="100%" height="455" style={{ border: 0 }} loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Contact